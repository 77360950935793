import { memo } from 'react';
import loadable from '@loadable/component';

import AppContainer from './components/Containers/AppContainer';
import ConnectErrorBoundary from './components/ConnectErrorBoundary';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);

const Snackbar = loadable(() => import('./components/Snackbar'));
const LoadingOverlay = loadable(() => import('./components/LoadingOverlay'));

const App = () => (
    <>
        <ConnectErrorBoundary>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Snackbar />
                <LoadingOverlay />
                <AppContainer />
            </LocalizationProvider>
        </ConnectErrorBoundary>
    </>
);

export default memo(App);
