import store from '../redux/store';

/**
 * Show a notification using the Snackbar Component
 * @param {object} props List of snackbar notifications properties
 * @returns {void}
 */
export const showSnackBarNotification = ({ type, message, snackbar = true }) => {
    store.dispatch({
        type: 'UPDATE_SNACK',
        payload: {
            type,
            message,
            snackbar,
        },
    });
};

/**
 * Error notification helper to use in catch() block
 * @param {string|object} errorMessage Error message or object
 * @returns {void}
 */
export const showSnackBarErrorNotification = (errorMessage) => {
    if (!errorMessage) return;

    showSnackBarNotification({
        type: 'error',
        message: errorMessage?.message || errorMessage,
    });
};

/**
 * Success notification helper
 * @param {string} message Notification message
 * @returns {void}
 */
export const showSnackBarSuccessNotification = (message) => {
    if (!message) return;

    showSnackBarNotification({
        message,
        type: 'success',
    });
};

/**
 * Warning notification helper
 * @param {string} message Notification message
 * @returns {void}
 */
export const showSnackBarWarningNotification = (message) => {
    if (!message) return;

    showSnackBarNotification({
        message,
        type: 'warning',
    });
};

/**
 * Info notification helper
 * @param {string} message Notification message
 * @returns {void}
 */
export const showSnackBarInfoNotification = (message) => {
    if (!message) return;

    showSnackBarNotification({
        message,
        type: 'info',
    });
};

/**
 * Session idle time notification helper
 */
export const showSnackBarSessionIdleTimeNotification = () => {
    showSnackBarNotification({
        type: 'sessionIdleTimeCheck',
        snackbar: true,
        message: 'Your session will expire in a few minutes due to inactivity.',
    });
};

/**
 * Hide snackbar notification
 */
export const hideSnackBarNotification = () => {
    showSnackBarNotification({
        type: 'success',
        message: '',
        snackbar: false,
    });
};
