import axios from 'axios';
import { logError } from './utils/envUtils';
import { getCurrentSelectedOrganizationId } from './utils/loginUtils';

/**
 *
 * @param config
 * @returns {boolean}
 */
const isHandlerEnabled = (config = {}) => {
    return !(Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled);
};

/**
 * Global error handler.
 *
 * @param error
 * @returns {Promise<never>}
 */
const errorHandlerResponse = (error) => {
    if (isHandlerEnabled(error.config)) {
        logError(error);
    }

    return Promise.reject({ ...error });
};

/**
 * Global success handler.
 *
 * @param response
 * @returns {*}
 */
const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
    }

    return response;
};

/**
 *
 * Authorized axios setup - used for requests which required authenticated user
 *
 */
const authorizedAxiosInstance = axios.create({
    baseURL: '',
});

authorizedAxiosInstance.interceptors.request.use(
    (config) => ({ ...config, headers: { 'Current-Organization-Id': getCurrentSelectedOrganizationId() } }),
    (error) => errorHandlerResponse(error),
);

authorizedAxiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandlerResponse(error),
);

authorizedAxiosInstance.defaults.withCredentials = true;

authorizedAxiosInstance.interceptors.request.use(function (config) {
    return config;
});

export { authorizedAxiosInstance };
