export const headerstyling = {
    appbar: {
        padding: '0 10px',
        background: '#fff !important',
        color: '#000',
        boxShadow: 'none',
        borderBottom: '1px solid #d7dcea',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // paddingRight: 24,
        background: '#fff',
        color: '#000',
    },
    headerfirstdiv: {
        display: 'flex',
    },
    headertypo: {
        display: 'flex',
        '& a': { color: '#000' },
        fontSize: '0.875rem',
        fontWeight: '600',
        cursor: 'pointer',
        paddingRight: 5,
    },
    img: {
        width: 180,
    },
    headerprofilediv: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '1px solid #d7dcea',
        marginLeft: '6px',

        '& img': {
            cursor: 'pointer',
        },
        '& .MuiSvgIcon-root': {
            cursor: 'pointer',
            color: '#c4c4c4',
        },
    },
    profileimgdiv: {
        cursor: 'pointer',
        display: 'flex',
        borderRadius: '50%',
        border: '1px solid #3a70d4',
        padding: '8px 11px',
        background: '#E0E0E0',
        marginLeft: 15,
    },
    helpimgdiv: {
        cursor: 'pointer',
        display: 'flex',
        borderRadius: '50%',
        border: '1px solid #3a70d4',
        padding: '7px 8px',
        background: '#E0E0E0',
        marginRight: 10,
    },
    supbtndiv: {
        display: 'flex',
        background: '#3a70d4',
        padding: 10,
        borderRadius: 5,
    },
    supdiv: {
        display: 'flex',
        marginRight: 30,
        color: '#fff',
        background: '#3a70d4',
        borderRadius: 5,
        '& .MuiTypography-root': {
            color: '#fff',
            fontSize: '0.875rem',
            fontWeight: '700',
            textTransform: 'capitalize',
            borderRadius: 5,
        },
        '&:hover': {
            '& .MuiTypography-root': { color: '#3a70d4' },
            background: 'transparent',
            border: '1px solid #3a70d4',

            color: '#3a70d4!important',
        },
    },
    centersection: {
        display: 'flex',
        paddingLeft: '3rem',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            paddingLeft: '1.8rem',
            paddingRight: '1.8rem',
        },
        '& .MuiTypography-root': {
            fontSize: '0.875rem',
            fontWeight: '700',
            padding: '0 10px',
        },
        '& .MuiSvgIcon-root': {
            color: '#3a70d4 !important',
        },
    },
    collapseclass: {
        position: 'absolute',
        right: 5,
        background: '#fff',
        top: '4rem',
        zIndex: 999,
        // border: '1px solid whitesmoke',
        borderRadius: 10,
        boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        minWidth: 184,
        '& .MuiListItem-button': {
            fontSize: '0.75rem',
            borderBottom: '1px solid #e5e5e5',
        },
        '& .MuiListItem-button:last-child': {
            borderBottom: '0',
        },
    },
    menulistitem: {
        cursor: 'pointer',
        color: '#3A70D4',
        background: '#FFF',
        outline: 'none',
        height: 45,
        width: 175,
        padding: 7,

        '&:hover ': {
            '& .MuiTypography-root': {
                color: '#fff',
            },
            backgroundColor: '#3A70D4',
            color: '#fff',
        },
        '& .MuiSvgIcon-root': {
            // color: '#3A70D4',
        },
    },
    menuIcon: {
        height: 16,
        width: 25,
        color: '#3A70D4',
    },
    menulabletext: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.875rem',
        paddingLeft: 14,
        color: '#000000',
    },
    menulistitem1: {
        cursor: 'pointer',
        color: '#3A70D4',
        background: '#FFF',
        outline: 'none',

        '&:hover ': {
            '& .MuiTypography-root': {
                color: '#fff',
            },
            backgroundColor: '#6198FF',
            color: '#fff',
        },
    },
};
