import { useRef, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const GlobalDataRefContext = createContext({});

const GlobalDataRefProvider = ({ children }) => {
    const globalDataRef = useRef({});

    const setData = (key, value) => (globalDataRef.current[key] = value);
    const getData = (key) => globalDataRef.current[key];
    const hasData = (key) => globalDataRef.current[key] !== undefined;
    const removeData = (key) => {
        delete globalDataRef.current[key];
    };

    const providerProps = {
        ref: globalDataRef,
        setData,
        getData,
        hasData,
        removeData,
    };

    return <GlobalDataRefContext.Provider value={providerProps}>{children}</GlobalDataRefContext.Provider>;
};

/**
 * Subscribe to the watch unsaved data context.
 * Note: this will not cause re-rendering since we're using ref
 * @returns {Object}
 */
export const useGlobalDataRefContext = () => useContext(GlobalDataRefContext);

/**
 * Get the hasData function
 * @returns {Function}
 */
export const useHasGlobalRef = () => {
    const { hasData } = useGlobalDataRefContext();
    return hasData;
};

/**
 * Get the ref object
 * @returns {React.MutableRefObject}
 */
export const useGetGlobalRef = () => {
    const { ref } = useGlobalDataRefContext();
    return ref;
};

/**
 * Get an item from ref object
 * @returns {Function}
 */
export const useGetGlobalRefItem = () => {
    const { getData } = useGlobalDataRefContext();
    return getData;
};

/**
 * Get an item from ref object
 * @returns {Function}
 */
export const useSetGlobalRefItem = () => {
    const { setData } = useGlobalDataRefContext();
    return setData;
};

/**
 * Get an item from ref object
 * @returns {Function}
 */
export const useRemoveGlobalRefItem = () => {
    const { removeData } = useGlobalDataRefContext();
    return removeData;
};

GlobalDataRefProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default GlobalDataRefProvider;
