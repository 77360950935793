import lodashObject from 'lodash/object';
import { batch, useDispatch, useSelector } from 'react-redux';

import store from '../redux/store';
import * as envUtils from '../utils/envUtils';
import { getPropFromObject, createObjectFromChainableProps } from '../utils/objectUtils';

/**
 * Store states actions
 */
export const storeDefaultStates = {
    _storeStates: '_storeStates',
};

const storeStatesActionType = storeDefaultStates._storeStates,
    _storeReducerNamespace = 'storeStates';

/**
 * Get store state helper.
 *
 * @param {Object} states Redux store namespace states.
 * @param {*} prop State to get from given store namespace states.
 *
 * @returns {*} State
 */
const getStoreStateHelper = (states, prop) => getPropFromObject(states, prop);

/**
 * Set store state helper.
 *
 * @param {Object} args
 * @param {String} args.type Action type
 * @param {*} args.value Store state value
 *
 * @param {Function} args.dispatch Action dispatcher
 */
const setStoreStateHelper = ({ type, value, dispatch }) => {
    dispatch({
        type,
        payload: value,
    });
};

/**
 * Redux store state action dispatcher
 *
 * Note: Update is ignored if state is unchanged.
 *
 * @param {String} actionType Store action type. Action type can
 * be chained incase of object values.
 * @param {String} storeReducerNamespace Store reducer namespace.
 *
 * @see getPropFromObject()
 *
 * @returns {Function<*, Function>}
 */
export const useStoreState = (actionType = storeStatesActionType, storeReducerNamespace = _storeReducerNamespace) => {
    const dispatch = useDispatch(),
        state = useSelector((storeState) => getStoreStateHelper(storeState?.[storeReducerNamespace], actionType));

    return (defaultValue = undefined) => {
        const setStoreStateFunc = (value) => {
            const traverseActions = actionType.split('.');

            // Ignore chainable store state update if no traversing strategy
            // is used: 'state.user.data.prop'.
            if (traverseActions.length < 2) {
                setStoreStateHelper({ value, dispatch, type: actionType });
                return;
            }

            // Store state action is the first chainable prop
            const action = traverseActions[0];

            const storeStates = store.getStoreState()?.[storeReducerNamespace]?.[action] || {};

            const newObjectState = lodashObject.merge(storeStates, createObjectFromChainableProps(actionType, value));

            setStoreStateHelper({ dispatch, value: newObjectState, type: action });
        };

        const stateValue = state === undefined ? defaultValue : state;
        return [stateValue, setStoreStateFunc];
    };
};

/**
 * Get the redux store states values and subscribe to changes
 *
 * @param {String} storeReducerNamespace Store reducer namespace
 *
 * @returns {Object} store states
 */
export const useStoreValues = (storeReducerNamespace = _storeReducerNamespace) => {
    return useSelector((storeState) => storeState?.[storeReducerNamespace] || {});
};

/**
 * Get a redux store state value and subscribe to changes
 *
 * @param {String} actionType Store action type. Action type can
 * be chained incase of object values.
 * @param {String} storeReducerNamespace Store reducer namespace
 *
 * @see getPropFromObject()
 *
 * @returns {Function<*>}
 */
export const useStoreValue = (actionType = storeStatesActionType, storeReducerNamespace = 'storeStates') => {
    const value = useSelector((reduxStore) => getStoreStateHelper(reduxStore?.[storeReducerNamespace], actionType));

    return (fallbackValue = undefined) => {
        return value === undefined ? fallbackValue : value;
    };
};

/**
 * Set redux store state value.
 *
 * Note: Update is ignored if state is unchanged.
 *
 * @param {String} actionType Store action type
 *
 * @returns {Function<void>} Function to update the store state value
 */
export const setStoreState = (actionType = storeStatesActionType) => {
    const dispatch = store.dispatch;

    return (value) => {
        setStoreStateHelper({
            value,
            dispatch,
            type: actionType,
        });
    };
};

/**
 * Get redux store state value.
 *
 * @param {String} actionType Store action type. Action type can
 * be chained incase of object values.
 * @param {String} storeReducerNamespace Store reducer namespace
 *
 * @see getPropFromObject()
 *
 * @returns {Function<void>} Function to get the store state value
 */
export const getStoreState = (actionType = storeStatesActionType, storeReducerNamespace = 'storeStates') => {
    const states = store.getState()?.[storeReducerNamespace],
        value = getStoreStateHelper(states, actionType);

    return (fallbackValue = undefined) => {
        return value === undefined ? fallbackValue : value;
    };
};

/**
 * Get redux store state value
 * @param {String} storeReducerNamespace Store reducer namespace
 * @returns {Object} Store states on success. Otherwise empty object.
 */
export const getStoreStates = (storeReducerNamespace = _storeReducerNamespace) => {
    return store.getState()?.[storeReducerNamespace] || {};
};

/**
 * Set redux store state values.
 * @param {Object} values Store states
 * @returns {Void} Function to update the store state values
 */
export const setStoreStates = (values) => {
    batch(() => {
        try {
            for (const state in values) {
                setStoreState(state)(values[state]);
            }
        } catch (e) {
            envUtils.logError(e);
        }
    });
};
