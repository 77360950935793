/* eslint default-param-last: 0 */

import reduxReducerHelper from '../../utils/reduxReducerHelperUtil';
import { INTEGRATIONS_DATA_ACTIONS } from '../../hooks/useIntegrationsHook';

const INIT_STATE = {
    [INTEGRATIONS_DATA_ACTIONS.USER_INTEGRATIONS]: [],
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_INTEGRATIONS_DATA]: [],
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_RECENTLY_UPDATED_INTEGRATION_ID]: '',
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_SELECTED_INTEGRATION_FOR_MODAL_ACTION]: {},
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_CURRENT_INTEGRATIONS_PAGE]: 1,
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_SHOULD_RELOAD_INTEGRATIONS_DATA_GRID]: false,
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_TOTAL_INTEGRATIONS_COUNT]: 0,
    [INTEGRATIONS_DATA_ACTIONS.UPDATE_IS_INTEGRATIONS_DATA_READY]: false,
};

export default reduxReducerHelper(INTEGRATIONS_DATA_ACTIONS, INIT_STATE);
