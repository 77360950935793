import { DASHBOARD_TAB_INTEGRATIONS } from '../../utils/constants';

/* eslint default-param-last: 0 */
const INIT_STATE = {
    tabIndex: DASHBOARD_TAB_INTEGRATIONS,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_DASHBOARD_ACTIVE_TAB':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
