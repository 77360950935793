import { useStoreValue, setStoreState, getStoreState, setStoreStates } from './useStoreStateHook';

export const storeNamespace = 'crosswalks';

/**
 * Default State
 */
export const crosswalksStates = {
    crosswalks: [],
    selectedCrosswalk: {},
    selectedCrosswalkValues: [],
    selectedCrosswalkValuesErrors: [],
    recentlyUpdatedCrosswalkId: '',
    isReady: false,

    // Data table states
    currentPage: 1,
    currentPageCrosswalks: [],
    shouldReload: false,
};

/**
 * Reset crosswalks states
 * @param {Object} states
 */
export const resetCrosswalksStates = (states = {}) => {
    setStoreStates({
        crosswalks: [],
        selectedCrosswalk: {},
        recentlyUpdatedCrosswalkId: '',

        currentPage: 1,
        currentPageCrosswalks: [],
        shouldReload: false,
        ...states,
    });
};

/****** Get Store States ******/

/**
 * Get crosswalk by id
 * @param {String} crosswalkId
 * @returns {Object}
 */
export const getCrosswalkById = (crosswalkId) =>
    getStoreState('crosswalks')([]).find((crosswalk) => crosswalk._id === crosswalkId) || {};

/**
 * Get selected crosswalk values
 * @returns {Array<Object>}
 */
export const getSelectedCrosswalkValues = () => getStoreState('selectedCrosswalkValues')([]);

/**
 * Get selected crosswalk values errors
 * @returns {Array<Object>}
 * @example [{ inputError: 'Name is required', outputError: 'Output is required' }]
 * @example [{ inputError: 'Name already exists', outputError: '' }]
 */
export const getSelectedCrosswalkValuesErrors = () => getStoreState('selectedCrosswalkValuesErrors')([]);

/**
 * Get crosswalks
 * @returns {Array<Object>}
 */
export const getCrosswalks = () => getStoreState('crosswalks')([]);

/****** Set Store States ******/

/**
 * Set crosswalks
 * @param {Array<Object>} crosswalks
 */
export const setCrosswalks = (crosswalks) => setStoreState('crosswalks')(crosswalks);

/**
 * Set current page number
 * @param {Integer} currentPage
 */
export const setCurrentPage = (currentPage) => setStoreState('currentPage')(currentPage);

/**
 * Set Recently Updated Crosswalk Id
 * @param {String} crosswalkId
 */
export const setRecentlyUpdatedCrosswalkId = (crosswalkId) => setStoreState('recentlyUpdatedCrosswalkId')(crosswalkId);

/**
 * Set current page crosswalks
 * @param {Array<Object>} crosswalks
 */
export const setCurrentPageCrosswalks = (crosswalks) => setStoreState('currentPageCrosswalks')(crosswalks);

/**
 * Set selected crosswalk
 * @param {Object} crosswalk
 */
export const setSelectedCrosswalk = (crosswalk) => setStoreState('selectedCrosswalk')(crosswalk);

/**
 * Update crosswalks optimistically
 * @param {Object} crosswalk
 * @param {String} action - 'create' or 'update' or 'delete' or 'updateValues'
 */
export const updateCrosswalksOptimistically = (crosswalk, action) => {
    const crosswalks = getStoreState('crosswalks')([]);
    // Copies array to avoid mutating the original array in the store
    const currentPageCrosswalks = getStoreState('currentPageCrosswalks')([]).slice();
    const crosswalkIndex = crosswalks.findIndex((c) => c._id === crosswalk._id);
    const currentPageCrosswalkIndex = currentPageCrosswalks.findIndex((c) => c._id === crosswalk._id);

    switch (action) {
        case 'create':
            setCrosswalks([...crosswalks, crosswalk]);
            if (currentPageCrosswalks.length >= 10) {
                currentPageCrosswalks.pop();
            }

            setCurrentPageCrosswalks([crosswalk, ...currentPageCrosswalks]);
            setRecentlyUpdatedCrosswalkId(crosswalk._id);
            break;
        case 'update':
            if (crosswalkIndex > -1) {
                crosswalks[crosswalkIndex] = crosswalk;
                setCrosswalks(crosswalks);
            } else {
                setCrosswalks([...crosswalks, crosswalk]);
            }

            if (currentPageCrosswalkIndex > -1) {
                currentPageCrosswalks[currentPageCrosswalkIndex] = crosswalk;
                setCurrentPageCrosswalks(currentPageCrosswalks);
                setRecentlyUpdatedCrosswalkId(crosswalk._id);
            }
            break;
        case 'delete':
            if (crosswalkIndex > -1) {
                crosswalks.splice(crosswalkIndex, 1);
                setCrosswalks(crosswalks);
            }

            if (currentPageCrosswalkIndex > -1) {
                currentPageCrosswalks.splice(currentPageCrosswalkIndex, 1);
                setCurrentPageCrosswalks(currentPageCrosswalks);
            }
            break;
        case 'updateValues':
            setRecentlyUpdatedCrosswalkId(crosswalk._id);
            break;
        case 'copy':
            setCrosswalks([...crosswalks, crosswalk]);
            if (currentPageCrosswalks.length >= 10) {
                currentPageCrosswalks.pop();
            }
            currentPageCrosswalks.unshift(crosswalk);
            setCurrentPageCrosswalks(currentPageCrosswalks);
            setRecentlyUpdatedCrosswalkId(crosswalk._id);
            break;
        default:
            break;
    }

    setTimeout(() => {
        setRecentlyUpdatedCrosswalkId('');
    }, 4000);
};

/**
 * Set should reload
 * @param {Boolean} shouldReload
 */
export const setShouldReload = (shouldReload) => setStoreState('shouldReload')(shouldReload);

/**
 * Set is ready
 * @param {Boolean} isReady
 */
export const setIsReady = (isReady) => setStoreState('isReady')(isReady);

/**
 * Set selected crosswalk values
 * @param {Array<Object>} values
 */
export const setSelectedCrosswalkValues = (values) => setStoreState('selectedCrosswalkValues')(values);

/**
 * Set selected crosswalk values errors
 * @param {Array<Object>} errors
 */
export const setSelectedCrosswalkValuesErrors = (errors) => setStoreState('selectedCrosswalkValuesErrors')(errors);

/**
 * Clear error of selected crosswalk value
 * @param {Integer} index
 * @param {String} errorType - 'inputError' or 'outputError' or 'both'
 */
export const clearSelectedCrosswalkValueError = (index, errorType) => {
    const selectedCrosswalkValuesErrors = getStoreState('selectedCrosswalkValuesErrors')([]);

    if (selectedCrosswalkValuesErrors[index]) {
        if (errorType === 'both') {
            selectedCrosswalkValuesErrors[index] = {};
        } else {
            selectedCrosswalkValuesErrors[index][errorType] = null;
        }

        setSelectedCrosswalkValuesErrors(selectedCrosswalkValuesErrors);
    }
};

/******  Use Store States ******/

/**
 * Get current page number and subscribe to changes
 * @returns {Integer}
 */
export const useCurrentPage = () => useStoreValue('currentPage', storeNamespace)(1);

/**
 * Get Recently Updated Crosswalk Id and subscribe to changes
 * @returns {String}
 */
export const useRecentlyUpdatedCrosswalkId = () => useStoreValue('recentlyUpdatedCrosswalkId', storeNamespace)('');

/**
 * Get current page crosswalks and subscribe to changes
 * @returns {Array<Object>}
 */
export const useCurrentPageCrosswalks = () => useStoreValue('currentPageCrosswalks', storeNamespace)([]);

/**
 * Get selected crosswalk and subscribe to changes
 * @returns {Object}
 */
export const useSelectedCrosswalk = () => useStoreValue('selectedCrosswalk', storeNamespace)({});

/**
 * Get should reload and subscribe to changes
 * @returns {Boolean}
 */
export const useShouldReload = () => useStoreValue('shouldReload', storeNamespace)(false);

/**
 * Get is ready and subscribe to changes
 * @returns {Boolean}
 */
export const useIsReady = () => useStoreValue('isReady', storeNamespace)(false);

/**
 * Get selected crosswalk values and subscribe to changes
 * @returns {Array<Object>}
 */
export const useSelectedCrosswalkValues = () => useStoreValue('selectedCrosswalkValues', storeNamespace)([]);

/**
 * Get selected crosswalk values errors and subscribe to changes
 * @returns {Array<Object>}
 */
export const useSelectedCrosswalkValuesErrors = () =>
    useStoreValue('selectedCrosswalkValuesErrors', storeNamespace)([]);

/**
 * Get crosswalks and subscribe to changes
 * @returns {Array<Object>}
 */
export const useCrosswalks = () => useStoreValue('crosswalks', storeNamespace)([]);
