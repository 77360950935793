export const bannerstyle = {
    // stripeBanner.js
    bannerContainer: {
        width: '100%',
        backgroundColor: '#FFFCF1',
        padding: 5.3,
        border: '1px solid #F2994A',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    bannerFirstdiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiLinearProgress-colorPrimary': {
            border: '0.2px solid #E0E0E0',
            width: '65%',
            borderRadius: 99,
            backgroundColor: '#F2F2F2',
            height: 11,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#85C446',
            borderRadius: '99px',
        },
        '& .MuiButton-root': {
            color: '#fff',
            background: '#3A70D4',
            borderRadius: '5px',
            padding: '7px 36px',
            '& .MuiTypography-root': {
                color: '#fff',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                borderRadius: 5,
            },
        },
        '& .MuiGrid-grid-sm-2:last-child': {
            justifyContent: 'space-around',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-20px',
            '& img': {
                cursor: 'pointer',
            },
        },
    },
    bannercenterContainer: {
        display: 'flex',
        alignItems: 'center',
        '& h5': {
            margin: 10,
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1rem',
            color: '#000',
        },
        '& h6': {
            margin: 10,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '0.813rem',
            color: '#000',
        },
        '& .MuiTypography-root:last-child': {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '0.813rem',
            color: '#000',
        },
    },
    bannercenterContainer2: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root:last-child': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.75rem',
            color: '#000',
        },
    },
    bannerdetailtxt: {
        marginLeft: 10,
        paddingRight: 10,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
        color: '#3A71D5',
    },
    bannerheaderprofilediv: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSvgIcon-root': {
            cursor: 'pointer',
            color: '#c4c4c4',
        },
    },
    bannerprofileimgdiv: {
        border: '1px solid #E0E0E0',
        display: 'flex',
        padding: '8px 10px',
        background: '#fff',
        borderRadius: '50%',
    },
};
