export const vendorInstanceStyles = {
    vendorHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    vendorHeaderIconButton: {
        padding: 0,
    },
    vendorAccordion: {
        background: '#484747 !important',
        border: 'solid 1px',
        borderColor: '#fff',
        width: '100%',
    },
    vendorDetailGrid: {
        paddingTop: 25,
        justifyContent: 'center',
        display: 'flex',
        width: '90%',
    },
    vendorDropDownText: {
        color: '#ffffff !important',
        borderColor: '#ffffff',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '23px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
    },
    vendorInstanceIcon: {
        margin: 10,
        padding: 0,
    },
    vendorInstanceDropDownText: {
        color: '#ffffff !important',
        fontFamily: 'Roboto',
        borderColor: '#ffffff',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px !important',
        lineHeight: '15px',
    },
    registerVendorInstanceText: {
        color: '#ffffff !important',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px !important',
        lineHeight: '15px',
    },
};
