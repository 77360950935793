import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useStoreValue, setStoreState, getStoreState, setStoreStates } from './useStoreStateHook';

import {
    getSelectedMapping,
    filterRootDataTypes,
    filterMappingTemplates,
    getTreeViewFieldIdFromPath,
    createMapOfNewInput,
    isFieldPrimitive,
} from '../utils/rosettaUtils';

import { getAllFunctionTypes } from '../utils/rosettaDataRequestUtils';
import { ObjectId } from '../utils/helpers';

export const storeNamespace = 'rosettaMappings';

/**
 * Default states
 */
export const mappingsStates = {
    dataTypes: [],
    functionTypes: [],
    mappingsList: [],
    selectedInput: {},
    lastSelectedInput: {},
    selectedOutput: {},
    lastSelectedOutput: {},
    selectedMapping: {},
    mappingTemplates: [],
    recentlyUpdatedMappingId: '',
    recentlyUpdatedCustomFieldId: '',
    isSelectedMappingDataReady: false,
    isSelectedMappingFirstInputSelected: false,

    /**
     * Data table states
     */
    currentPageMappings: [],
    isMappingsReady: false,
    currentMappingsPage: 1,
    shouldReloadMappings: false,

    /**
     * Mapped output fields
     */
    fieldMappings: [],

    /**
     * Helper's list
     */
    selectedMappingInputPaths: new Map(),
    selectedMappingOutputPaths: new Map(),
    selectedMappingInputFields: new Map(),
    selectedMappingOutputFields: new Map(),

    /**
     * Input tree folder deepest level
     */
    highestNestedLevel: 1,

    /**
     * Mapping Condition states
     */
    // 0: Inactive, 1: Data table, 2: Edit
    mappingConditionState: 0,
    // Index of selected mapping condition
    selectedMappingConditionIndex: -1,
    mappingCopy: {},
    recentlyUpdatedMappingConditionId: '',
    hasSubmitted: false,
};

/**
 * Reset selected mapping states
 * @param {Object} stats State to set
 */
export const resetSelectedMappingStates = (states = {}) => {
    setStoreStates({
        selectedInput: {},
        fieldMappings: [],
        selectedMapping: {},
        lastSelectedInput: {},
        selectedOutput: {},
        lastSelectedOutput: {},
        recentlyUpdatedMappingId: '',
        recentlyUpdatedCustomFieldId: '',
        selectedMappingOutputPaths: new Map(),
        selectedMappingInputFields: new Map(),
        selectedMappingInputPaths: new Map(),
        selectedMappingOutputFields: new Map(),
        isSelectedMappingFirstInputSelected: false,
        ...states,
    });
};

/**
 * Get if mappings are ready and subscribe to changes
 * @returns {Boolean}
 */
export const useIsMappingsReady = () => useStoreValue('isMappingsReady', storeNamespace)(true);

/**
 * Set if mappings are ready
 * @param {Boolean} isReady
 */
export const setIsMappingsReady = (isReady) => setStoreState('isMappingsReady')(isReady);

/**
 * Get current page of myMappings data table and subscribe to changes
 * @returns {Integer}
 */
export const useCurrentPage = () => useStoreValue('currentMappingsPage', storeNamespace)(1);

/**
 * Sets myMapping data table current page
 * @param {Integer} currPage
 */
export const setCurrentPage = (currPage) => setStoreState('currentMappingsPage')(currPage);

/**
 * Set rosetta mappings data
 * @param {Array<Object>} mappings
 */
export const setMappingsData = (mappings) => setStoreState('mappingsList')(mappings);

/**
 * Update the mappings list data optimistically
 * @param {String} actionType. Accepted values: 'create' | 'update' | 'delete'
 * @param {Object} selectedMappingData
 */
export const updateMappingsDataOptimistically = (actionType, selectedMappingData) => {
    let newMappingsList = [],
        newCurrentPageMappings = [];
    const mappings = getStoreState('mappingsList', storeNamespace)([]),
        currentPageMappings = getStoreState('currentPageMappings', storeNamespace)([]),
        selectedMappingId = selectedMappingData?._id,
        isDeleteAction = actionType === 'delete';

    if (actionType === 'create') {
        newMappingsList = [selectedMappingData].concat(mappings);
        if (currentPageMappings.length > 9) {
            newCurrentPageMappings = currentPageMappings.slice(0, 9);
        }
        newCurrentPageMappings.unshift(selectedMappingData);
    } else if (isDeleteAction) {
        newMappingsList = mappings?.filter((mapping) => selectedMappingId !== mapping?._id);
    } else if (actionType === 'update') {
        newMappingsList = mappings?.map((mapping) => {
            if (selectedMappingId !== mapping?._id) {
                return mapping;
            }
            return selectedMappingData;
        });
        newCurrentPageMappings = currentPageMappings.map((mapping) =>
            selectedMappingId === mapping?._id ? selectedMappingData : mapping,
        );
    } else if (actionType === 'copy') {
        newCurrentPageMappings = [selectedMappingData, ...mappings];
        if (newCurrentPageMappings.length > 10) {
            newCurrentPageMappings.pop();
        }
    } else {
        return;
    }

    // Update the mappings list and mark the modified mapping
    setStoreStates({
        mappingsList: newMappingsList,
        recentlyUpdatedMappingId: isDeleteAction ? '' : selectedMappingId,
        currentPageMappings: newCurrentPageMappings,
    });

    // Clear the highlight integration row after 4 seconds
    if (!isDeleteAction) {
        setTimeout(() => {
            setStoreState('recentlyUpdatedMappingId')('');
        }, 4000);
    }
};

/**
 * Get the recently updated mapping ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedMappingId = () => useStoreValue('recentlyUpdatedMappingId', storeNamespace)('');

/**
 * Get the mapping data types and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useMappingDataTypes = () => useStoreValue('dataTypes', storeNamespace)([]);

/**
 * Set the mapping data types.
 * @param {Array<Object>}
 */
export const setMappingDataTypes = (dataTypes) => setStoreState('dataTypes')(dataTypes);

/**
 * Set the selected mapping data.
 * @param {Object}
 */
export const setSelectedMappingData = (data) => setStoreState('selectedMapping')(data);

/**
 * Set the selected mapping data ready state.
 * @param {Boolean}
 */
export const setIsSelectedMappingDataReady = (isReady) => setStoreState('isSelectedMappingDataReady')(isReady);

/**
 * Set the selected mapping first input selected state.
 * @param {Boolean}
 */
export const setIsSelectedMappingFirstInputSelected = (isSelected) =>
    setStoreState('isSelectedMappingFirstInputSelected')(isSelected);

/**
 * Get the selected mapping first input selected state.
 * @return {Boolean}
 */
export const getIsSelectedMappingFirstInputSelected = () =>
    getStoreState('isSelectedMappingFirstInputSelected', storeNamespace)();

/**
 * Optimistically update the selected mapping data.
 * @param {Object} args
 * @param {String} args.prop Property to update
 * @param {String} args.action Action type. 'create' | 'update' | 'delete'.
 * @param {*} args.data Property data. Array and Object are merged automatically.
 */
export const updateSelectedMappingDataOptimistically = ({ prop: fieldProp, action, data = null }) => {
    // Gracefully resolve props for known shorthand properties.
    let prop;
    const _fieldProp = fieldProp?.toLowerCase();
    if (_fieldProp === 'input') {
        prop = 'inputs';
    } else if (_fieldProp === 'output') {
        prop = 'outputs';
    } else if (_fieldProp === 'custom') {
        prop = 'customFields';
    }

    const selectedMapping = _.cloneDeep(getSelectedMapping()),
        previousPropData = selectedMapping[prop],
        isPropArray = typeof previousPropData?.forEach === 'function',
        isPropObject = typeof previousPropData === 'object',
        isCreateAction = action === 'create',
        isDeleteAction = action === 'delete',
        isUpdateAction = action === 'update',
        isDataObject = typeof data === 'object';

    if (previousPropData === undefined) {
        if (!isDeleteAction) {
            selectedMapping[prop] = data;
        }
    }
    // arrays
    else if (isPropArray) {
        if (!isDataObject) return;
        if (isCreateAction) {
            selectedMapping[prop].push(data);

            if (prop === 'inputs') {
                // Add all input fields to the selectedMappingInputFields
                const { inputFieldsList, inputPaths } = createMapOfNewInput(data);
                setStoreState(
                    'selectedMappingInputFields',
                    storeNamespace,
                )(new Map([...getSelectedMappingInputFields(), ...inputFieldsList]));
                setStoreState(
                    'selectedMappingInputPaths',
                    storeNamespace,
                )(new Map([...getSelectedMappingInputPaths(), ...inputPaths]));
            }
        } else if (isUpdateAction) {
            const filterSelectedMapping = selectedMapping[prop]?.map((item) => {
                if (item?._id !== data?._id) {
                    return item;
                }
                return data;
            });

            if (prop === 'inputs') {
                const { inputFieldsList, inputPaths } = createMapOfNewInput(data);
                setStoreState(
                    'selectedMappingInputFields',
                    storeNamespace,
                )(new Map([...getSelectedMappingInputFields(), ...inputFieldsList]));
                setStoreState(
                    'selectedMappingInputPaths',
                    storeNamespace,
                )(new Map([...getSelectedMappingInputPaths(), ...inputPaths]));
            }

            selectedMapping[prop] = filterSelectedMapping;
        } else if (isDeleteAction) {
            const filterSelectedMapping = selectedMapping[prop]?.filter((item) => item?._id !== data?._id);

            selectedMapping[prop] = filterSelectedMapping;
        }
    }
    // objects
    else if (isPropObject) {
        if (!isDataObject) return;

        selectedMapping[prop] = {
            ...selectedMapping[prop],
            ...data,
        };
    }
    // scalar
    else {
        selectedMapping[prop] = data;
    }
    setSelectedMappingData(selectedMapping);

    if (prop === 'customFields' && !isDeleteAction) {
        setStoreState('recentlyUpdatedCustomFieldId')(data?._id);
    }

    if (!isDeleteAction) {
        setTimeout(() => {
            setStoreState('recentlyUpdatedCustomFieldId')('');
        }, 4000);
    }
};

/**
 *
 * @param {Object} arg
 * @param {String} arg.action Action type. 'create' | 'update' | 'delete'.
 * @param {Object} arg.conditionId Condition ID
 */
export const updateSelectedMappingConditions = (data) => {
    const action = data?.action,
        selectedMapping = _.cloneDeep(getSelectedMapping()),
        previousPropData = selectedMapping?.conditions,
        isCreateAction = action === 'create',
        isDeleteAction = action === 'delete',
        isUpdateAction = action === 'update';

    if (previousPropData === undefined) {
        selectedMapping.conditions = [];
    }

    if (isCreateAction) {
        selectedMapping.conditions.push({
            conditionId: data.conditionId,
            conditionName: data.name,
            expressionList: [],
        });
        setStoreState('recentlyUpdatedMappingConditionId')(data.conditionId);
    }

    if (isUpdateAction) {
        const index = selectedMapping.conditions.findIndex((condition) => condition.conditionId === data.conditionId);

        if (index === -1) {
            return;
        } else {
            selectedMapping.conditions[index].conditionName = data.name;
            setStoreState('recentlyUpdatedMappingConditionId')(data.conditionId);
        }
    }

    if (isDeleteAction) {
        const index = selectedMapping.conditions.findIndex((condition) => condition.conditionId === data.conditionId);

        if (index === -1) {
            return;
        } else {
            selectedMapping.conditions.splice(index, 1);
        }
    }

    setSelectedMappingData(selectedMapping);

    if (!isDeleteAction) {
        setTimeout(() => {
            setStoreState('recentlyUpdatedMappingConditionId')('');
        }, 4000);
    }
};

/**
 * @typedef {'create-expression' | 'create-group-expression' | 'update-expression' | 'delete-expression' | 'delete-group-expression' } ExpressionAction
 */

/**
 * Updates the selected mapping condition expressions
 * @param {Object} arg
 * @param {ExpressionAction} arg.action Action type.
 * @param {Object} arg.conditionId Condition ID
 * @param {Object} arg.expressionId Expression ID
 * @param {Object} arg.changes Changes object
 * @param {Number[]} arg.expressionIndexes Expression path
 */
export const updateSelectedMappingConditionExpressions = ({ action, conditionId, changes, expressionIndexes = [] }) => {
    const selectedMapping = _.cloneDeep(getMappingCopy()),
        selectedCondition = selectedMapping.conditions.find((condition) => condition.conditionId === conditionId);

    if (!selectedCondition) {
        return;
    }

    let expressionList = selectedCondition.expressionList,
        expressionListForUpdates = selectedCondition.expressionList;

    if (expressionIndexes.length) {
        for (let i = 0; i < expressionIndexes.length - 1; i++) {
            const index = expressionIndexes[i];
            expressionList = expressionList[index].expressionList;

            if (i < expressionIndexes.length - 2) {
                expressionListForUpdates = expressionListForUpdates[index].expressionList;
            }
        }
    }

    switch (action) {
        case 'create-expression':
            expressionList.push({
                _id: ObjectId(),
                expressionType: 'expression',
                expressionOperator: null,
                leftOperandValue: '',
                leftOperandSourceInputId: null,
                leftOperandSourceInputPath: [],
                comparisonOperator: '',
                rightOperandValue: '',
                rightOperandSourceInputId: null,
                rightOperandSourceInputPath: [],
            });
            break;
        case 'create-group-expression':
            expressionList.push({
                _id: ObjectId(),
                expressionType: 'grouping',
                expressionOperator: null,
                leftOperandValue: null,
                leftOperandSourceInputId: null,
                leftOperandSourceInputPath: [],
                comparisonOperator: null,
                rightOperandValue: null,
                rightOperandSourceInputId: null,
                rightOperandSourceInputPath: [],
                expressionList: [
                    {
                        _id: ObjectId(),
                        expressionType: 'expression',
                        expressionOperator: null,
                        leftOperandValue: '',
                        leftOperandSourceInputId: null,
                        leftOperandSourceInputPath: [],
                        comparisonOperator: '',
                        rightOperandValue: '',
                        rightOperandSourceInputId: null,
                        rightOperandSourceInputPath: [],
                    },
                ],
            });
            break;
        case 'update-expression': {
            expressionListForUpdates[expressionIndexes.at(-1)] = {
                ...expressionListForUpdates[expressionIndexes.at(-1)],
                ...changes,
            };
            break;
        }
        case 'delete-expression':
            expressionListForUpdates.splice(expressionIndexes.at(-1), 1);
            break;
        case 'delete-group-expression':
            expressionListForUpdates.splice(expressionIndexes.at(-1), 1);
            break;
        default:
            break;
    }

    setHasSubmittedMappingCondition(false);
    setMappingCopy(selectedMapping);
};

/**
 * Save condition changes to selected mapping
 * @param {String} conditionId
 */
export const saveSelectedMappingConditionChanges = (conditionId) => {
    const selectedMapping = _.cloneDeep(getMappingCopy());
    setRecentlyUpdatedMappingConditionId(conditionId);
    setSelectedMappingData(selectedMapping);

    setTimeout(() => {
        setRecentlyUpdatedMappingConditionId('');
    }, 4000);
};

/**
 * Get the mappings templates and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useMappingTemplates = () =>
    useSelector((state) => filterMappingTemplates(state?.[storeNamespace]?.mappingsList));

/**
 * Get the root data types and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useResourceTypes = () => useSelector((state) => filterRootDataTypes(state?.[storeNamespace]?.dataTypes));

/**
 * Get the selected mapping data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedMapping = () => useStoreValue('selectedMapping', storeNamespace)([]);

/**
 * Check whether the selected mapping data is ready and subscribe to changes.
 * @returns {Object}
 */
export const useIsSelectedMappingDataReady = () => useStoreValue('isSelectedMappingDataReady', storeNamespace)({});

/**
 * Get the selected mapping input types and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useSelectedMappingInputTypes = () =>
    useSelector((state) => state?.[storeNamespace]?.selectedMapping?.inputs || []);

/**
 * Get the selected mapping output types and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useSelectedMappingOutputTypes = () =>
    useSelector((state) => state?.[storeNamespace]?.selectedMapping?.outputs || []);

/**
 * Get the selected mapping custom fields and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useSelectedMappingCustomFields = () =>
    useSelector((state) => state?.[storeNamespace]?.selectedMapping?.customFields || []);

/**
 * Set the selected input on mapping screen.
 * @param {Object} data
 */
export const setSelectedInput = (data) => setStoreState('selectedInput')(data);

/**
 * Set the selected output on mapping screen and logically set last selected output
 * @param {Object} data
 */
export const setSelectedOutput = (data) => {
    const output = getStoreState('selectedOutput', storeNamespace)({});
    if (data.type !== 'customField') {
        setStoreState('lastSelectedOutput')(output._id ? output : data);
    }
    setStoreState('selectedOutput', storeNamespace)(data);
};

/**
 * Use the selected input/output mappings on mapping screen
 * @param {Array<object>} defaultMappings
 * @return {Array<object>}
 */
export const useSelectedOutputFieldMappings = (defaultMappings = []) =>
    useStoreValue('fieldMappings', storeNamespace)(defaultMappings);

/**
 * Get the selected output on mapping screen
 * @param {Array<object>} data pre-loaded mapping fields
 * @return {Array<object>}
 */
export const getSelectedOutputFieldMappings = (data = []) => getStoreState('fieldMappings', storeNamespace)(data);

/**
 * Get custom field from id
 * @param {String} id
 * @returns {Object}
 */
export const getCustomFieldById = (id) => {
    const mapping = getStoreState('selectedMapping', storeNamespace)({});

    if (mapping?._id && mapping.customFields) {
        return mapping.customFields.find((field) => field._id === id) || {};
    } else {
        return {};
    }
};

/**
 * Returns if the two output paths are equal
 * @param {Array<Object>} outputPath
 * @param {Array<Object>} mappingOutputPath
 * @returns {Boolean}
 */
const checkMappingOutputPathEquality = (outputPath, mappingOutputPath) => {
    if (outputPath.length !== mappingOutputPath.length) {
        return false;
    }

    for (let i = 0; i < outputPath.length; i++) {
        const currentOutputPath = outputPath[i],
            currentMappingOutputPath = mappingOutputPath[i];

        if (
            currentOutputPath.dataTypeFieldId !== currentMappingOutputPath.dataTypeFieldId ||
            currentOutputPath.occurrenceIndex !== currentMappingOutputPath.occurrenceIndex
        ) {
            return false;
        }
    }

    return true;
};

/**
 * Get field mappings for a output field/path
 * @param {Array<Object>} outputPath path of the output field
 * @param {Object} field field object
 * @return {Object} field mapping object
 */
export const getOutputFieldMappingByPath = (outputPath, field) => {
    if (!outputPath?.length) {
        return {};
    }

    const fieldMappings = getSelectedOutputFieldMappings();

    return fieldMappings?.find((mapping) => {
        const mappingOutputPath = mapping.outputPath || [];

        if (mappingOutputPath.length !== outputPath.length) {
            return false;
        } else {
            // Check if the output path field ids are equal
            for (let i = 0; i < outputPath.length; i++) {
                if (outputPath[i].dataTypeFieldId !== mappingOutputPath[i].dataTypeFieldId) {
                    return false;
                }
            }

            const fieldIsPrimitive = isFieldPrimitive(field);

            if (fieldIsPrimitive) {
                return mapping.outputPath.every(
                    ({ occurrenceIndex }, i) => outputPath[i].occurrenceIndex === occurrenceIndex,
                );
            } else {
                return true;
            }
        }
    });
};

/**
 * Set the selected output on mapping screen
 * @param {Array<object>} data mapping fields
 */
export const setSelectedOutputFieldMappings = (data) => setStoreState('fieldMappings', storeNamespace)(data);

/**
 * Update the selected output on mapping screen
 * @param {Array<object>} data mapping fields
 */
export const updateSelectedOutputFieldMappings = (data) => {
    const fieldMappings = getSelectedOutputFieldMappings(),
        // checked if data is an object or an array, can replace in function call
        // _data = typeof data?.forEach === 'function' ? data : [data],
        fieldMappingExists = fieldMappings?.find((mapping) => {
            return checkMappingOutputPathEquality(data.outputPath, mapping.outputPath);
        });

    if (fieldMappingExists) {
        fieldMappings.splice(fieldMappings.indexOf(fieldMappingExists), 1, data);
    } else {
        fieldMappings.push(data);
    }

    setStoreState('fieldMappings', storeNamespace)([...fieldMappings]);
};

/**
 * Remove selected output mapping item
 * @param {Array<Object>} fieldMapping
 * @return {Array<object>} Output mapping fields
 */
export const removeSelectedOutputFieldMappingItem = (fieldMapping) => {
    const fieldMappingTreeViewFieldId = getTreeViewFieldIdFromPath(
        getSelectedOutputDataObject()?._id,
        fieldMapping.outputPath || [],
        false,
    );

    const outputMappings = getSelectedOutputFieldMappings()?.filter((mappingObj) => {
        const treeViewFieldId = getTreeViewFieldIdFromPath(
            getSelectedOutputDataObject()?._id,
            mappingObj.outputPath || [],
            false,
        );

        return fieldMappingTreeViewFieldId !== treeViewFieldId;
    });

    setStoreState('fieldMappings', storeNamespace)([...outputMappings]);
};

/**
 * Get the drag-drop field and subscribe to changes.
 * @returns {Object}
 */
export const useDragDropField = () => useStoreValue('dragDropField', storeNamespace)({});

/**
 * Get the mappings list
 * @returns {Array}
 */
export const getMappingsList = () => getStoreState('mappingsList', storeNamespace)([]);

/**
 * Get the selected input data
 * @returns {Object}
 */
export const getSelectedInputDataObject = () => getStoreState('selectedInput', storeNamespace)({});

/**
 * Get the selected output data
 * @returns {Object}
 */
export const getSelectedOutputDataObject = () => getStoreState('selectedOutput', storeNamespace)({});

/**
 * Get the selected input and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedInput = () => useStoreValue('selectedInput', storeNamespace)({});

/**
 * Get the selected output and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedOutput = () => useStoreValue('selectedOutput', storeNamespace)({});

/**
 * Get the selected input fields list.
 * @returns {*}
 */
export const getSelectedMappingInputFields = () =>
    getStoreState('selectedMappingInputFields', storeNamespace)(new Map());

/**
 * Get the selected mapping input paths.
 * @returns {Array<object>}
 */
export const getSelectedMappingInputPaths = () => getStoreState('selectedMappingInputPaths', storeNamespace)(new Map());

/**
 * Get the selected mapping condition index
 * @returns {Number}
 */
export const getSelectedMappingConditionIndex = () =>
    getStoreState('selectedMappingConditionIndex', storeNamespace)(-1);

/**
 * Get mapping copy
 * @returns {Object}
 */
export const getMappingCopy = () => getStoreState('mappingCopy', storeNamespace)({});

/**
 * Get the selected mapping input paths and subscribe to changes.
 * @returns {*}
 */
export const useSelectedMappingInputPaths = () => useStoreValue('selectedMappingInputPaths', storeNamespace)(new Map());

/**
 * Get the selected mapping output paths and subscribe to changes.
 * @returns {*}
 */
export const useSelectedMappingOutputPaths = () =>
    useStoreValue('selectedMappingOutputPaths', storeNamespace)(new Map());

/**
 * Get selected mapping condition state and subscribe to changes
 * @returns {Number}
 */
export const useMappingConditionState = () => useStoreValue('mappingConditionState', storeNamespace)(0);

/**
 * Get recently updated mapping condition id and subscribe to changes
 * @returns {String}
 */
export const useRecentlyUpdatedMappingConditionId = () =>
    useStoreValue('recentlyUpdatedMappingConditionId', storeNamespace)('');

/**
 * Get has submitted mapping condition and subscribe to changes
 * @returns {Boolean}
 */
export const useHasSubmittedMappingCondition = () => useStoreValue('hasSubmitted', storeNamespace)(false);

/**
 * Get mapping copy and subscribe to changes
 * @returns {Object}
 */
export const useMappingCopy = () => useStoreValue('mappingCopy', storeNamespace)({});

/**
 * Get the selected mapping output paths.
 * @returns {*}
 */
export const getSelectedMappingOutputPaths = () =>
    getStoreState('selectedMappingOutputPaths', storeNamespace)(new Map());

/**
 * Get selected mapping input path.
 * @param {String} key
 * @returns {*}
 */
export const getSelectedMappingInputPath = (key) => getSelectedMappingInputPaths()?.get(key) || [];

/**
 * Get selected mapping output path.
 * @param {String} key
 * @returns {*}
 */
export const getSelectedMappingOutputPath = (key) => getSelectedMappingOutputPaths()?.get(key) || [];

/**
 * Get the selected input fields list.
 * @param {String} key
 * @returns {*}
 */
export const getSelectedMappingInputFieldsItem = (key) => getSelectedMappingInputFields()?.get(key) || {};

/**
 * Append items to the selected mapping input paths.
 * @param {String} key
 * @param {*} value
 */
export const appendToSelectedMappingInputPaths = (key, value) => {
    setStoreState('selectedMappingInputPaths', storeNamespace)(getSelectedMappingInputPaths()?.set(key, value));
};

/**
 * Append items to the selected mapping output paths.
 * @param {String} key
 * @param {*} value
 */
export const appendToSelectedMappingOutputPaths = (key, value) => {
    setStoreState('selectedMappingOutputPaths', storeNamespace)(getSelectedMappingOutputPaths()?.set(key, value));
};

/**
 * Append items to the selected input fields list.
 * @param {String} key
 * @param {*} value
 */
export const appendToSelectedMappingInputFields = (key, value) => {
    setStoreState('selectedMappingInputFields', storeNamespace)(getSelectedMappingInputFields()?.set(key, value));
};

/**
 * Get the selected mapping output fields list.
 * @returns {*}
 */
export const getSelectedMappingOutputFields = () =>
    getStoreState('selectedMappingOutputFields', storeNamespace)(new Map());

/**
 * Get the selected output fields list item.
 * @param {String} key
 * @returns {*}
 */
export const getSelectedMappingOutputFieldsItem = (key) => getSelectedMappingOutputFields()?.get(key) || {};

/**
 * Append items to the selected output fields list.
 * @param {String} key
 * @param {*} value
 */
export const appendToSelectedMappingOutputFields = (key, value) => {
    setStoreState('selectedMappingOutputFields', storeNamespace)(getSelectedMappingInputFields()?.set(key, value));
};

/**
 * Clear the selected input/output fields list cache.
 */
export const clearSelectedInputOutputFields = () => {
    setStoreStates({
        selectedMappingInputFields: new Map(),
        selectedMappingOutputFields: new Map(),
    });
};

/**
 * Get dataType object with id
 * @param {String} id
 * @returns {Object}
 */
export const getDataType = (id) => getStoreState('dataTypes', storeNamespace)([]).find((type) => type._id === id);

/**
 * Get and set the data types.
 * @returns {Array<Object>}
 */
export const useFunctionTypes = () => {
    /**
     * Initialize rosetta state values
     */
    useEffect(() => {
        async function fetchFunctionTypes() {
            getAllFunctionTypes({
                successCallback: (res) => {
                    setCustomFieldFunctionTypes(res.data.results);
                },
            });
        }
        fetchFunctionTypes();
    }, []);
};

/**
 * Get the mappings data and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useMappings = () => useStoreValue('mappingsList', storeNamespace)([]);

/**
 * Set custom field function types
 * @param {Array<Object>} functionTypes
 */
export const setCustomFieldFunctionTypes = (functionTypes) => setStoreState('functionTypes')(functionTypes);

/**
 * Get custom field function types and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useCustomFieldFunctionTypes = () => useStoreValue('functionTypes', storeNamespace)([]);

/**
 * Set selected output to last selected if it exists else set selected output to default
 */
export const setOutputToLastSelected = () => {
    const lastOutput = getStoreState('lastSelectedOutput', storeNamespace)({});
    const fieldMappings = getStoreState('fieldMappings', storeNamespace)([]);
    // Keep current mappings, but set output back to previous selected output
    setStoreState('selectedOutput', storeNamespace)({ ...lastOutput, fieldMappings });
};

/**
 * Get recently updated custom fields id and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedCustomFieldId = () => useStoreValue('recentlyUpdatedCustomFieldId', storeNamespace)('');

/**
 * Get should reload state and subscribe to changes
 * @returns {Boolean}
 */
export const useShouldReload = () => useStoreValue('shouldReloadMappings', storeNamespace)(false);

/**
 * Sets should reload
 * @param {Boolean} shouldReload
 */
export const setShouldReload = (shouldReload) => setStoreState('shouldReloadMappings', storeNamespace)(shouldReload);

/**
 * Get current page mappings and subscribe to changes
 * @returns {Array<Object>}
 */
export const useMappingsForPage = () => useStoreValue('currentPageMappings', storeNamespace)([]);

/**
 * Sets current page mappings
 * @param {Array<Object>} mappings
 */
export const setMappingsForPage = (mappings) => setStoreState('currentPageMappings', storeNamespace)(mappings);

/**
 * Sets mapping condition state
 * @param {Number} state
 */
export const setMappingConditionState = (state) => setStoreState('mappingConditionState', storeNamespace)(state);

/**
 * Sets selected mapping condition
 * @param {Object} condition
 */
export const setSelectedMappingConditionIndex = (index) =>
    setStoreState('selectedMappingConditionIndex', storeNamespace)(index);

/**
 * Sets recently updated mapping condition id
 * @param {String} id
 */
export const setRecentlyUpdatedMappingConditionId = (id) =>
    setStoreState('recentlyUpdatedMappingConditionId', storeNamespace)(id);

/**
 * Sets hasSubmitted state for mapping condition
 * @param {Boolean} hasSubmitted
 */
export const setHasSubmittedMappingCondition = (hasSubmitted) =>
    setStoreState('hasSubmitted', storeNamespace)(hasSubmitted);

/**
 * Sets mapping copy
 * @param {Object} mapping
 */
export const setMappingCopy = (mapping) => {
    const mappingCopy = mapping ? _.cloneDeep(mapping) : _.cloneDeep(getSelectedMapping()),
        selectedMappingConditionIndex = getSelectedMappingConditionIndex(),
        selectedMappingConditionExpressionList =
            mappingCopy?.conditions?.[selectedMappingConditionIndex]?.expressionList;

    if (selectedMappingConditionExpressionList && !selectedMappingConditionExpressionList.length) {
        mappingCopy.conditions[selectedMappingConditionIndex].expressionList = [
            {
                _id: ObjectId(),
                expressionType: 'expression',
                expressionOperator: null,
                leftOperandValue: '',
                leftOperandSourceInputId: null,
                leftOperandSourceInputPath: [],
                comparisonOperator: '',
                rightOperandValue: '',
                rightOperandSourceInputId: null,
                rightOperandSourceInputPath: [],
            },
        ];
    }

    setStoreState('mappingCopy', storeNamespace)(mappingCopy);
};

export default useMappings;
