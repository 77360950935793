import { useRef, useEffect, useState } from 'react';
import { isInputFieldsReady } from '../utils/inputFieldUtils';

/**
 * Input field mutable ref object.
 * @param {React.MutableRefObject} inputRef Mutable ref object.
 * @returns {React.MutableRefObject} Mutable ref object.
 */
export const useInputFieldRef = (inputRef = null) => {
    const inputFieldRef = useRef();

    /**
     * Set input ref. We don't want to change the input field value using
     * the change event to prevent unnecessary re-rendering.
     */
    useEffect(() => {
        if (inputRef) {
            inputRef.current = inputFieldRef.current;
        }
    }, [inputFieldRef, inputRef]);

    return inputFieldRef;
};

/**
 * Ensure input field default value is rendered correctly
 * during the initial load state.
 * @param {string|number} defaultValue Input field default value.
 * @param {React.MutableRefObject} inputRef Mutable ref object.
 */
export const useInputFieldDefaultValue = (defaultValue, inputRef) => {
    useEffect(() => {
        if (defaultValue !== undefined && inputRef.current) {
            inputRef.current.value = defaultValue;
        }
        /* eslint-disable */
    }, [defaultValue]);
};

/**
 * Auth field state helper hook.
 *
 * Note: the `submitButtonRef` should be passed to the auth workflow
 * submit button incase for need to bind `enter key` event.
 *
 * @returns {object} props
 * @returns {boolean} props.isValid
 * @returns {object} props.authFieldProps
 * @returns {React.MutableRefObject} props.submitButtonRef
 * @returns {function} props.getInputFieldValue
 */
export const useAuthFieldState = () => {
    const authFieldRef = useRef(),
        submitButtonRef = useRef(),
        [isValid, setIsValid] = useState(false);

    /**
     * Run the verification if enter key is pressed.
     */
    const handleKeyDown = (e) => {
        if (isValid && submitButtonRef.current && e.keyCode === 13) {
            submitButtonRef.current?.click();
        }
    };

    /**
     * Get input field value.
     * @returns {string|number}
     */
    const getInputFieldValue = () => (isValid ? authFieldRef.current.value : '');

    /**
     * Input props for use in auth field.
     */
    const authFieldProps = {
        inputRef: authFieldRef,
        realtimeValidationOnType: true,
        inputProps: {
            onKeyDown: handleKeyDown,
        },
        validationErrorCallback: () => {
            isValid && setIsValid(false);
        },
        validationSuccessCallback: () => {
            !isValid && setIsValid(true);
        },
    };

    return {
        isValid,
        setIsValid,
        authFieldRef,
        authFieldProps,
        submitButtonRef,
        getInputFieldValue,
    };
};

/**
 * Run a callback function based on the controlled action value.
 * @param {React.MutableRefObject} inputRef Input ref object
 * @param {object} actionCallback
 * @param {bool} actionCallback.action If true, the callback function is fired.
 * @param {function} actionCallback.callback
 */
export const useInputActionCallback = (inputRef, actionCallback = null) => {
    useEffect(() => {
        if (actionCallback && actionCallback?.action) {
            actionCallback.callback && actionCallback.callback(inputRef);
        }
    }, [actionCallback]);
};

/**
 * Monitor the input field validation state.
 * @see isInputFieldsReady()
 * @param {object} stateData Input field state data
 * @returns {boolean} True if input fields are valid. False otherwise.
 */
export const useInputDataValidationState = (stateData) => {
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        isInputFieldsReady({ dataRefObj: stateData })()
            .then((res) => {
                setIsValid(res);
            })
            .catch(() => {
                setIsValid(false);
            });

        return () => {
            setIsValid(false);
        };
    }, [stateData]);

    return isValid;
};
