import { makeStyles } from '@mui/styles';

import { appstlying } from './appstyling';
import dashboardstyle from './dashboardstyle';
import { headerstyling } from './headerstyling';
import { integrationworkflowstyle } from './integrationworkflowstyle';
import { loginstyle } from './loginstyle';
import { registernewapplicationstyle } from './registernewapplicationstyle';
import { utilsstyling } from './utilsstyling';
import { bannerstyle } from './bannerstyle';
import { uploadfilestyle } from './uploadfilestyle';
import { vendorInstanceStyles } from './vendorInstanceStyles';

const useStyles = () =>
    makeStyles(
        (theme) => ({
            //<-- dashboard -->
            dashboardroot: dashboardstyle.dashboardroot,
            topbar: dashboardstyle.topbar,
            workflowcontainerroot: dashboardstyle.workflowcontainerroot,
            createbtn: dashboardstyle.createbtn,
            loaderdiv: dashboardstyle.loaderdiv,
            activetab: dashboardstyle.activetab,
            deactivetab: dashboardstyle.deactivetab,
            nodatacomporoot: dashboardstyle.nodatacomporoot,
            headtxt1: dashboardstyle.headtxt1,
            compContainer: dashboardstyle.compContainer,
            dilaogbox: dashboardstyle.dialogbox,
            typodiv: dashboardstyle.typodiv,
            typo2: dashboardstyle.typo2,
            yesbtn: dashboardstyle.yesbtn,
            laterbtn: dashboardstyle.laterbtn,
            breadcrumbdiv: dashboardstyle.breadcrumbdiv,
            inttitle: dashboardstyle.inttitle,
            inttitle1: dashboardstyle.inttitle1,
            desc: dashboardstyle.desc,
            integrationcontainerroot: dashboardstyle.integrationcontainerroot,
            dropdowndrawer: dashboardstyle.dropdowndrawer,
            hoveractive: dashboardstyle.hoveractive,
            hoverinactive: dashboardstyle.hoverinactive,
            dialogbox: dashboardstyle.dialogbox,

            //<-- Integration Workflow -->
            integrationworkflowtopbar: integrationworkflowstyle.integrationworkflowtopbar,
            seconddiv: integrationworkflowstyle.seconddiv,
            bottomDiv: integrationworkflowstyle.bottomDiv,
            leftDiv: integrationworkflowstyle.leftDiv,
            bottomdrawer: integrationworkflowstyle.bottomdrawer,
            innersectionroot: integrationworkflowstyle.innersectionroot,
            maindiv: integrationworkflowstyle.maindiv,
            container: integrationworkflowstyle.container,
            firstdiv: integrationworkflowstyle.firstdiv,
            innersectionseconddiv: integrationworkflowstyle.innersectionseconddiv,
            seconddivcontainer: integrationworkflowstyle.seconddivcontainer,
            seconddivcontainer1: integrationworkflowstyle.seconddivcontainer1,
            boxdiv: integrationworkflowstyle.boxdiv,
            thirddiv: integrationworkflowstyle.thirddiv,
            thirddivbox: integrationworkflowstyle.thirddivbox,
            innersectiondialogbox: integrationworkflowstyle.innersectiondialogbox,
            popupheaderdiv: integrationworkflowstyle.popupheaderdiv,
            addnewstep: integrationworkflowstyle.addnewstep,
            innersectionseconddilaogbox: integrationworkflowstyle.innersectionseconddilaogbox,
            datedialogbox: integrationworkflowstyle.datedialogbox,
            divborder: integrationworkflowstyle.divborder,
            headingtextdiv: integrationworkflowstyle.headingtextdiv,
            griddiv: integrationworkflowstyle.griddiv,
            checkboxdiv: integrationworkflowstyle.checkboxdiv,
            contentsectionmaindiv: integrationworkflowstyle.contentsectionmaindiv,
            mainfirstdiv: integrationworkflowstyle.mainfirstdiv,
            drawer: integrationworkflowstyle.drawer,
            drawerPaper: integrationworkflowstyle.drawerPaper,
            newdrawer: integrationworkflowstyle.newdrawer,
            newdrawerPaper: integrationworkflowstyle.newdrawerPaper,
            newleftdrawer: integrationworkflowstyle.newleftdrawer,
            RightdrawerbtnDiv: integrationworkflowstyle.RightdrawerbtnDiv,
            Rightdrawheader: integrationworkflowstyle.Rightdrawheader,
            rightDrawerDiv: integrationworkflowstyle.rightDrawerDiv,
            rightDrawerDiv2: integrationworkflowstyle.rightDrawerDiv2,
            rightdrawetext: integrationworkflowstyle.rightdrawetext,
            Drawercheckboxdiv: integrationworkflowstyle.Drawercheckboxdiv,
            drawerbottmSection: integrationworkflowstyle.drawerbottmSection,
            rightDrawerSelect: integrationworkflowstyle.rightDrawerSelect,
            stepDrawtxt: integrationworkflowstyle.stepDrawtxt,
            stepContainer: integrationworkflowstyle.stepContainer,
            bottombtnDiv: integrationworkflowstyle.bottombtnDiv,
            stepmainContainer: integrationworkflowstyle.stepmainContainer,
            rightdrawerContainer: integrationworkflowstyle.rightdrawerContainer,
            drawerdatepicker: integrationworkflowstyle.drawerdatepicker,
            rightdrawerstep: integrationworkflowstyle.rightdrawerstep,
            rightstepdrawerstep: integrationworkflowstyle.rightstepdrawerstep,
            rightDrawerParameter: integrationworkflowstyle.rightDrawerParameter,
            rightDrawerParamValue: integrationworkflowstyle.rightDrawerParamValue,
            executeManualTrigger: integrationworkflowstyle.executeManualTrigger,
            zoomControlDrawer: integrationworkflowstyle.zoomControlDrawer,
            zoomContainer: integrationworkflowstyle.zoomContainer,
            zoomTriggerContainer: integrationworkflowstyle.zoomTriggerContainer,
            rightdrawBottomBar: integrationworkflowstyle.rightdrawBottomBar,
            stepsList: integrationworkflowstyle.stepsList,
            content: {
                flexGrow: 1,
                display: 'flex',
                height: '80vh',
                alignItems: 'center',
                '&::-webkit-scrollbar': {
                    width: 0,
                },
                position: 'absolute',
                padding: 0,
                marginTop: 25,
                width: '100%',
                justifyContent: 'space-between',
                // transition: theme.transitions.create('margin', {
                //     easing: theme.transitions.easing.sharp,
                //     duration: theme.transitions.duration.leavingScreen,
                // }),
                marginLeft: 0,
            },
            contentShift: {
                // transition: theme.transitions.create('margin', {
                //     easing: theme.transitions.easing.easeOut,
                //     duration: theme.transitions.duration.enteringScreen,
                // }),
                marginLeft: 140,
            },
            small: {
                // width: theme.spacing(5),
                // height: theme.spacing(5),
                // backgroundSize: 'contain !important',
                // objectFit: 'contain !important'
            },
            avatarimage: integrationworkflowstyle.avatarimage,
            imgmaindiv: integrationworkflowstyle.imgmaindiv,
            imgdiv: integrationworkflowstyle.imgdiv,
            boxStyling: integrationworkflowstyle.boxStyling,
            listdiv: integrationworkflowstyle.listdiv,
            stepStyling: integrationworkflowstyle.stepStyling,
            colorCircle: integrationworkflowstyle.colorCircle,
            contentsectiondialogbox: integrationworkflowstyle.contentsectiondialogbox,
            list: integrationworkflowstyle.list,
            fullList: integrationworkflowstyle.fullList,
            bottomdrawerbottomDiv: integrationworkflowstyle.bottomdrawerbottomDiv,
            bottomheader: integrationworkflowstyle.bottomheader,
            innerdiv: integrationworkflowstyle.innerdiv,
            bottomdrawerdilaogbox: integrationworkflowstyle.bottomdrawerdilaogbox,
            addstepdiv: integrationworkflowstyle.addstepdiv,
            dragIcon: integrationworkflowstyle.dragIcon,
            stepContainerDiv: integrationworkflowstyle.stepContainerDiv,
            stepMainDiv: integrationworkflowstyle.stepMainDiv,
            triggerStepMainDiv: integrationworkflowstyle.triggerStepMainDiv,
            triggerStepTitle: integrationworkflowstyle.triggerStepTitle,
            triggerIcon: integrationworkflowstyle.triggerIcon,
            triggerDescriptionMainDiv: integrationworkflowstyle.triggerDescriptionMainDiv,
            triggerDescriptionMainText: integrationworkflowstyle.triggerDescriptionMainText,
            triggerStepLine: integrationworkflowstyle.triggerStepLine,
            triggerPlusButton: integrationworkflowstyle.triggerPlusButton,
            triggerPlusIcon: integrationworkflowstyle.triggerPlusIcon,
            stepDropSpace: integrationworkflowstyle.stepDropSpace,
            stepLine: integrationworkflowstyle.stepLine,
            stepPlaceholder: integrationworkflowstyle.stepPlaceholder,
            stepPlusButton: integrationworkflowstyle.stepPlusButton,

            //<-- Login -->
            paper: loginstyle.paper,
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
            createtxt: loginstyle.createtxt,
            signupbtnContainer: loginstyle.signupbtnContainer,
            signupBtn: loginstyle.signupBtn,
            signupdivider: loginstyle.signupdivider,
            signupdividerContainer: loginstyle.signupdividerContainer,
            footerlink: loginstyle.footerlink,
            subPass: loginstyle.subPass,
            bottomtext: loginstyle.bottomtext,
            signupmainContainer: loginstyle.signupmainContainer,
            signuppassworddiv: loginstyle.signuppassworddiv,
            loginsubmit: loginstyle.loginsubmit,
            headertext: loginstyle.headertext,
            logintypotext: loginstyle.logintypotext,
            logintextfield: loginstyle.logintextfield,
            loginremembertext: loginstyle.loginremembertext,
            logindivider: loginstyle.logindivider,
            bottomdiv: loginstyle.bottomdiv,
            cancel: loginstyle.cancel,
            send: loginstyle.send,
            forgotheadertext: loginstyle.forgotheadertext,
            typotext1: loginstyle.typotext1,
            buttondiv: loginstyle.buttondiv,

            //<-- Register New Application -->
            rootSelect1: registernewapplicationstyle.rootSelect1,
            multiemail: registernewapplicationstyle.multiemail,
            registerapplicationsectiontypotext: registernewapplicationstyle.registerapplicationsectiontypotext,
            authtext: registernewapplicationstyle.authtext,
            registerapplicationsectioncreatetxt: registernewapplicationstyle.registerapplicationsectioncreatetxt,
            registerapplicationsectionBtn: registernewapplicationstyle.registerapplicationsectionBtn,
            registernewapplicationsectionmainContainer:
                registernewapplicationstyle.registernewapplicationsectionmainContainer,
            registernewvendorsectionmainContainer: registernewapplicationstyle.registernewvendorsectionmainContainer,
            registernevendoritemheader: registernewapplicationstyle.registernevendoritemheader,
            registernevendortypeformControl: registernewapplicationstyle.registernevendortypeformControl,
            disabledEditable: registernewapplicationstyle.disabledEditable,
            textFieldEditable: registernewapplicationstyle.textFieldEditable,
            seconddialog: registernewapplicationstyle.seconddialog,

            /**
             * TODO: sort the appropriate location to store these
             *
             * Recovered some styling that was under obsolete components but some of the styles were
             * used in other components.
             */
            // used in RegisterVendorSection
            templatecontentbtnContainer: registernewapplicationstyle.templatecontentbtnContainer,
            textfield: registernewapplicationstyle.textfield,
            dividerContainer: registernewapplicationstyle.dividerContainer,
            // used in Subscriptions
            divider: registernewapplicationstyle.divider,

            //<--headers-->
            appbar: headerstyling.appbar,
            toolbar: headerstyling.toolbar,
            headerfirstdiv: headerstyling.headerfirstdiv,
            headertypo: headerstyling.headertypo,
            img: headerstyling.img,
            headerprofilediv: headerstyling.headerprofilediv,
            profileimgdiv: headerstyling.profileimgdiv,
            helpimgdiv: headerstyling.helpimgdiv,
            supbtndiv: headerstyling.supbtndiv,
            supdiv: headerstyling.supdiv,
            centersection: headerstyling.centersection,
            collapseclass: headerstyling.collapseclass,
            menulistitem: headerstyling.menulistitem,
            menuIcon: headerstyling.menuIcon,
            menulabletext: headerstyling.menulabletext,
            menulistitem1: headerstyling.menulistitem1,

            //<-- Utils-->
            dialogbox1: utilsstyling.dialogbox1,
            createintegrationpopupdialogbox: utilsstyling.createintegrationpopupdialogbox,
            createIntegrationTextInput: utilsstyling.createIntegrationTextInput,
            createIntegrationMultilineTextInput: utilsstyling.createIntegrationMultilineTextInput,
            logoutbuttons: utilsstyling.logoutbuttons,
            nobtn: utilsstyling.nobtn,
            popupmodaldilaogbox: utilsstyling.popupmodaldilaogbox,
            typo1: utilsstyling.typo1,
            maintypo: utilsstyling.maintypo,
            iconnecttxt: utilsstyling.iconnecttxt,
            popupmodaltypo2: utilsstyling.popupmodaltypo2,
            typo3: utilsstyling.typo3,
            popupmodalsubmit: utilsstyling.popupmodalsubmit,

            //<-- App -->
            Approot: appstlying.Approot,
            appcontainer: appstlying.appcontainer,

            // <--Stripe Banner-->
            bannerContainer: bannerstyle.bannerContainer,
            bannerFirstdiv: bannerstyle.bannerFirstdiv,
            bannercenterContainer: bannerstyle.bannercenterContainer,
            bannercenterContainer2: bannerstyle.bannercenterContainer2,
            bannerdetailtxt: bannerstyle.bannerdetailtxt,
            bannerheaderprofilediv: bannerstyle.bannerheaderprofilediv,
            bannerprofileimgdiv: bannerstyle.bannerprofileimgdiv,

            //<--File Upload-->
            uploaddileselecttxt: uploadfilestyle.uploaddileselecttxt,
            uploadlink: uploadfilestyle.uploadlink,
            uploadheadtxt: uploadfilestyle.uploadheadtxt,
            uploadcontentbtnContainer: uploadfilestyle.uploadcontentbtnContainer,
            uploadfilesectionBtn: uploadfilestyle.uploadfilesectionBtn,
            uploadpreviewtxt: uploadfilestyle.uploadpreviewtxt,
            uploadsectionmainContainer: uploadfilestyle.uploadsectionmainContainer,
            uploadpreviewboxcontainer: uploadfilestyle.uploadpreviewboxcontainer,
            uploadprogresscontainer: uploadfilestyle.uploadprogresscontainer,
            uploadbrowssectionBtn: uploadfilestyle.uploadbrowssectionBtn,
            uploadprogressdiv: uploadfilestyle.uploadprogressdiv,
            uploadfileSelect: uploadfilestyle.uploadfileSelect,

            //<--Vendor settings-->
            vendorHeaderTitle: vendorInstanceStyles.vendorHeaderTitle,
            vendorHeaderIconButton: vendorInstanceStyles.vendorHeaderIconButton,
            vendorAccordion: vendorInstanceStyles.vendorAccordion,
            vendorDetailGrid: vendorInstanceStyles.vendorDetailGrid,
            vendorDropDownText: vendorInstanceStyles.vendorDropDownText,
            vendorInstanceIcon: vendorInstanceStyles.vendorInstanceIcon,
            vendorInstanceDropDownText: vendorInstanceStyles.vendorInstanceDropDownText,
            registerVendorInstanceText: vendorInstanceStyles.registerVendorInstanceText,
        }),
        { index: 1 },
    );
export default useStyles;
