import reduxReducerHelper, { mapDefaultValuesToActions } from '../../utils/reduxReducerHelperUtil';

import { transformComponentStates, integrationBuilderDefaultStates } from '../../hooks/useIntegrationBuilderHook';

const integrationBuilderActions = mapDefaultValuesToActions(integrationBuilderDefaultStates);

const transformComponentActions = mapDefaultValuesToActions(transformComponentStates);

export const transformComponentReducer = reduxReducerHelper(transformComponentActions, transformComponentStates);

export default reduxReducerHelper(integrationBuilderActions, integrationBuilderDefaultStates);
