import { useStoreValue, setStoreState, setStoreStates } from './useStoreStateHook';

export const storeNamespace = 'myConnections';

export const myConnectionsStates = {
    connections: [],
    selectedConnection: {},

    // Table States
    currentPageNumber: 1,
    currentPageConnections: [],
    shouldReload: false,

    // Form States
    awsVpnState: '',
    isLoading: false,
    isSubmitting: false,
};

/**
 * Reset MyConnections states
 * @param {Object} states
 */
export const resetMyConnectionsStates = (states = {}) => {
    setStoreStates({
        connections: [],
        selectedConnection: {},

        // Table States
        currentPageNumber: 1,
        currentPageConnections: [],
        shouldReload: false,

        // Form States
        awsVpnState: '',
        isLoading: false,
        isSubmitting: false,
        ...states,
    });
};

/****** Set Store States ******/

/**
 * @param connection
 * @returns {void}
 */
export const setSelectedConnection = (connection) => setStoreState('selectedConnection')(connection);

/**
 * Set current page number
 * @param {Number} currentPage
 */
export const setCurrentPageNumber = (currentPage) => setStoreState('currentPageNumber')(currentPage);

/**
 * Set current page connections
 * @param {Array<Object>} connections
 */
export const setCurrentPageConnections = (connections) => setStoreState('currentPageConnections')(connections);

/**
 * Set should reload
 * @param {Boolean} shouldReload
 */
export const setShouldReload = (shouldReload) => setStoreState('shouldReload')(shouldReload);

/**
 * @param {string} awsVpnState
 */
export const setAwsVpnState = (awsVpnState) => setStoreState('awsVpnState')(awsVpnState);

/**
 * @param {boolean} isLoading
 */
export const setFormIsLoading = (isLoading) => setStoreState('isLoading')(isLoading);

/**
 * @param {boolean} isSubmitting
 */
export const setFormIsSubmitting = (isSubmitting) => setStoreState('isSubmitting')(isSubmitting);

/****** Use Store States ******/

/**
 * @returns {Object}
 */
export const useSelectedConnection = () => useStoreValue('selectedConnection', storeNamespace)({});

/**
 * Get should reload and subscribe to changes
 * @returns {Boolean}
 */
export const useShouldReload = () => useStoreValue('shouldReload', storeNamespace)(false);

/**
 * Current page for connection data grid
 * @returns {Number}
 */
export const useCurrentPageNumber = () => useStoreValue('currentPageNumber', storeNamespace)(1);

/**
 * Gets filtered list of connections for display
 * @returns {Object[]}
 */
export const useCurrentPageConnections = () => useStoreValue('currentPageConnections', storeNamespace)([]);

/**
 * @returns {string}
 */
export const useAwsVpnState = () => useStoreValue('awsVpnState', storeNamespace)('');

/**
 * @returns {boolean}
 */
export const useFormIsLoading = () => useStoreValue('isLoading', storeNamespace)(false);

/**
 * @returns {boolean}
 */
export const useFormIsSubmitting = () => useStoreValue('isSubmitting', storeNamespace)(false);
