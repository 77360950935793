import TimeCircleIcon from '../assets/icons/TimeCircleIcon';
export const registernewapplicationstyle = {
    rootSelect1: {
        '& .select__input': { color: '#fff!important' },
        '& .select__control': {
            backgroundColor: '#484747!important',
        },
        '& .select__menu': {
            marginTop: 0,
            backgroundColor: '#484747!important',
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
        },
        '& .select__indicator-separator': {
            display: 'none!important',
        },
        '& svg': {
            color: '#fff!important',
        },
        '& .select__dropdown-indicator:last-child': {
            paddingRight: 14,
        },
        '& .select__dropdown-indicator': {
            padding: '13px 7px!important',
        },
        '& .css-1gtu0rj-indicatorContainer': {
            padding: '13px 14px!important',
        },
        '& .css-1pahdxg-control': {
            backgroundColor: '#484747!important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .select__placeholder': {
            color: '#8B9197!important',
        },
        '& .select__menu-list': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#484747',
            color: '#fff',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '& div:hover': {
                backgroundColor: '#3a70d4',
                cursor: 'pointer',
            },
            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .select__option': {
                backgroundColor: 'transparent',
            },
        },
        '& .css-2b097c-container': {
            fontSize: '0.813rem',
            fontWeight: 400,
            backgroundColor: '#4f4f4f',
            '& .select__control': {
                borderColor: '#828282!important',
                background: '#4f4f4f',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .css-1uccc91-singleValue': {
                color: '#fff',
            },
        },
    },
    multiemail: {
        '& .react-tag-input--input::placeholder': {
            color: '#8B9197',
            fontSize: '0.813rem',
        },
        '& .react-tagsinput:first-child': {
            '&  .react-tagsinput-tag:first-child': {
                '& a': {
                    display: 'none',
                },
            },
        },
        '& .react-tagsinput': {
            backgroundColor: '#484747',
            width: '100%',
            outline: 'none',
            border: 'none',
            overflow: 'hidden',
            padding: '5px 5px',
            borderRadius: 4,
            color: '#fff',
            marginTop: 5,
        },

        '& .react-tagsinput--focused': {
            border: 'none',
        },

        '& .react-tagsinput-tag': {
            border: 'none',
            backgroundColor: '#484747',
            borderRadius: '2px',
            color: '#fff',
            display: 'inline-block',
            fontSize: '13px',
            fontWeight: 400,
            marginTop: '5px',
            marginRight: '5px',
            padding: '5px',
        },

        '& .react-tagsinput-remove': {
            cursor: 'pointer',
            color: '#4f4f4f',
            '&:before': {
                content: `url(${(<TimeCircleIcon />)})`,
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                top: 1,
                left: 3,
                width: 10,
                height: 10,
            },
        },
        '& .react-tagsinput-input': {
            background: '#484747',
            border: 0,
            color: '#fff',
            fontSize: '0.813rem',
            fontWeight: 400,
            marginBottom: 6,
            marginTop: 1,
            outline: 'none',
            padding: 5,
            display: 'inline-flex',
            maxWidth: '100%',
            '&:-webkit-autofill': {
                transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                transitionDelay: 'background-color 50000s, color 50000s',
            },
            '&::placeholder': {
                color: '#8B9197',
                fontSize: '0.813rem',
                fontWeight: 'normal',
                fontStyle: 'normal',
            },
        },
    },
    registerapplicationsectiontypotext: {
        fontSize: '0.813rem',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#fff',
    },
    authtext: {
        fontSize: '0.75rem',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#fff',
        textTransform: 'uppercase',
    },
    registerapplicationsectioncreatetxt: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '0.75rem',
        paddingBottom: 5,
        paddingTop: '2rem',
        color: '#fff',
    },
    registerapplicationsectionBtn: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '0.875rem',
        textTransform: 'none',
        color: '#fff',
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#bdbdbd',
            color: '#fff',
        },
    },

    registernewapplicationsectionmainContainer: {
        overflow: 'auto',
        marginTop: '1rem',
        padding: '0 10px',
        '&::-webkit-scrollbar': {
            width: 1,
        },
        '& hr': {
            margin: '1.5rem 0',
            height: '1px',
            background: '#828282',
        },
    },
    seconddialog: {
        '& .MuiDialog-paper': {
            padding: '70px 0',
            alignItems: 'center',
            '& .MuiTypography-root': {
                fontSize: '1.125rem',
                fontWeight: 600,
                paddingBottom: '2.5rem',
            },
            '& .MuiLinearProgress-colorPrimary': {
                width: '70%',
                borderRadius: 5,
                backgroundColor: '#c4c4c4',
                height: 8,
            },
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: '#85C446' },
        },
    },
    registernewvendorsectionmainContainer: {
        overflow: 'auto',
        marginTop: '1rem',
        padding: '0 10px',
        '&::-webkit-scrollbar': {
            width: 1,
        },
        '& hr': {
            margin: '1.5rem 0',
            height: '1px',
            background: '#828282',
        },
    },
    textFieldEditable: {
        width: 300,
        color: '#fff',
        fontSize: 30,
        opacity: 1,
        borderBottom: 0,
        '&:before': {
            borderBottom: 0,
        },
    },
    disabledEditable: {
        width: '100%',
        border: 0,
        '&:before': {
            borderBottom: 0,
        },
        '& .MuiInputBase-root': {
            color: '#fff',
            fontSize: 16,
        },
        '& .MuiInputBase-input': {
            fontSize: 24,
        },
        '& p': {
            color: 'red',
        },
    },
    registernevendoritemheader: {
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between !important',
            cursor: 'default',
        },
        '& .MuiButtonBase-root': {
            // paddingLeft: 16,
            // paddingRight: 16,
            // display: 'inherit',
        },
    },
    registernevendortypeformControl: {
        margin: 2,
        minWidth: 120,
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
        },
    },
    templatecontentbtnContainer: {
        display: 'flex',

        '& .MuiButtonBase-root': {
            margin: '1rem 0',

            backgroundColor: '#3A70D4',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '0.875rem',
            minWidth: 116,
            minHeight: 35,
            textTransform: 'capitalize',
        },
    },
    textfield: {
        marginTop: 5,
        marginBottom: 5,

        '& .MuiInputAdornment-positionStart': {
            marginRight: 0,
        },
        '& .MuiTypography-root': { color: '#fff' },
        '& :hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3A70D4',
            },
        },
        '& .MuiInputBase-root': {
            color: '#fff',
            borderRadius: 5,
        },
        '& .MuiInputBase-input': {
            color: '#fff!important',
            '&:-webkit-autofill': {
                transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                transitionDelay: 'background-color 50000s, color 50000s',
            },
        },
        '& .MuiInputBase-input:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3A70D4',
            },
        },
        '& .MuiInputBase-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3A70D4',
            },
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: '#fff',
        },
        '& .MuiOutlinedInput-input': {
            padding: '15px 14px',
        },
        '& .MuiIconButton-root': {
            color: '#fff',
            position: 'absolute',
            right: 10,
            '& .MuiSvgIcon-root': {
                fontSize: '1.063rem',
            },
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        paddingRight: 0,
        position: 'relative',
    },
    dividerContainer: {
        paddingBottom: 15,
    },
    divider: {
        background:
            'linear-gradient(90deg, rgba(168, 213, 255, 0.06) 0%, rgba(56, 128, 193, 0.3) 52.14%, rgba(168, 213, 255, 0.06) 100%)',
    },
    mainSection: {
        height: '100vh',
        display: 'flex ',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subtxt: {
        fontSize: '1rem',
        color: '#fff',
        fontStyle: 'normal',
        fontWeight: 'normal',
        '& span:last-child': {
            textDecoration: 'underline',
            marginLeft: 4,
            cursor: 'pointer',
            fontWeight: 600,
            textDecorationThickness: '1px',
        },
        '& div:last-child': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
};
