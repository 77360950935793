import loadable from '@loadable/component';
import useStyles from '../../../styling/jss';
import GlobalDataRefProvider from '../../../hooks/useGlobalDataRefHook';
import WatchUnsavedDataProvider from '../../../hooks/useWatchUnsavedDataHook';

const Routes = loadable(() => import('../../../routes'));
// import Routes from '../../../routes';

const AppRoutesContainer = () => {
    const classes = useStyles()();

    return (
        <div className={classes.appcontainer}>
            <GlobalDataRefProvider>
                <WatchUnsavedDataProvider>
                    <Routes />
                </WatchUnsavedDataProvider>
            </GlobalDataRefProvider>
        </div>
    );
};

export default AppRoutesContainer;
