import store from '../redux/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIntegrations, getAllIntegrations } from '../utils/integrationDataRequestUtils';
import { getStoreState } from './useStoreStateHook';
import { getAuthUserId } from './useUserHook';

const storeNamespace = 'integrationsData';

/**
 * ACTIONS
 */
export const INTEGRATIONS_DATA_ACTIONS = {
    USER_INTEGRATIONS: 'userIntegrations',
    UPDATE_INTEGRATIONS_DATA: 'integrationsDataList',
    UPDATE_RECENTLY_UPDATED_INTEGRATION_ID: 'recentlyUpdatedIntegrationId',
    UPDATE_SELECTED_INTEGRATION_FOR_MODAL_ACTION: 'selectedIntegrationForModalAction',
    UPDATE_CURRENT_INTEGRATIONS_PAGE: 'currentIntegrationsPage',
    UPDATE_SHOULD_RELOAD_INTEGRATIONS_DATA_GRID: 'shouldReloadIntegrations',
    UPDATE_TOTAL_INTEGRATIONS_COUNT: 'totalIntegrationsCount',
    UPDATE_IS_INTEGRATIONS_DATA_READY: 'isIntegrationsDataReady',
};

const integrationsDataActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_INTEGRATIONS_DATA;

const selectedIntegrationForModalActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_SELECTED_INTEGRATION_FOR_MODAL_ACTION;

const recentlyUpdatedIntegrationIdActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_RECENTLY_UPDATED_INTEGRATION_ID;

const currentIntegrationsPageActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_CURRENT_INTEGRATIONS_PAGE;

const totalIntegrationsCountActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_TOTAL_INTEGRATIONS_COUNT;

const isIntegrationsDataReadyActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_IS_INTEGRATIONS_DATA_READY;

const shouldReloadIntegrationsActionType = INTEGRATIONS_DATA_ACTIONS.UPDATE_SHOULD_RELOAD_INTEGRATIONS_DATA_GRID;

/**
 * Get the integrations data states and subscribe to changes
 * @returns {object} An object containing  the integrations table states
 */
export const useIntegrationsData = () =>
    useSelector((state) => state?.integrationsData?.[integrationsDataActionType] || []);

/**
 * Get the selected integration data state and subscribe to changes
 * @returns {object} Selected integration data
 */
export const useSelectedIntegrationData = () =>
    useSelector((state) => state?.integrationsData?.[selectedIntegrationForModalActionType] || {});

/**
 * Get the selected integration data state and subscribe to changes
 * @returns {object} Selected integration data
 */
export const useRecentlyUpdatedIntegrationId = () =>
    useSelector((state) => state?.integrationsData?.[recentlyUpdatedIntegrationIdActionType] || {});

/**
 * Get current integrations data grid page and subscribe to changes
 * @returns {number}
 */
export const useCurrentIntegrationsPage = () =>
    useSelector((state) => state?.integrationsData?.[currentIntegrationsPageActionType] || 1);

/**
 * Get should reload data grid page and subscribe to changes
 * @returns {boolean}
 */
export const useShouldReloadIntegrationsPage = () =>
    useSelector((state) => state?.integrationsData?.[shouldReloadIntegrationsActionType] || false);

/**
 * Get should total integrations count and subscribe to changes
 * @returns {number}
 */
export const useTotalIntegrations = () =>
    useSelector((state) => state?.integrationsData?.[totalIntegrationsCountActionType] || 0);

/**
 * Get integrations data ready state and subscribe to changes
 * @returns {boolean}
 */
export const useIsIntegrationsDataReady = () =>
    useSelector((state) => state?.integrationsData?.[isIntegrationsDataReadyActionType] || false);

/**
 * Update the integrations data
 *
 * @param {array} payload List of integrations data
 * @returns {void}
 */
export const setIntegrationsData = (payload) => {
    store.dispatch({
        payload,
        type: integrationsDataActionType,
    });
};

/**
 * Update the selected integration data
 *
 * @param {object} payload Selected integration data
 * @returns {void}
 */
export const setSelectedIntegrationData = (payload) => {
    store.dispatch({
        payload,
        type: selectedIntegrationForModalActionType,
    });
};

/**
 * Get the selected integration data
 * @returns {object}
 */
export const getSelectedIntegrationData = () =>
    store.getState().integrationsData?.[selectedIntegrationForModalActionType];

/**
 * Set the recently updated integration ID
 *
 * @param {string|array} payload Recently updated integration ID(s)
 * @returns {void}
 */
export const setRecentlyUpdatedIntegrationId = (payload) => {
    store.dispatch({
        payload,
        type: recentlyUpdatedIntegrationIdActionType,
    });
};

/**
 * Set current page for integrations data grid
 * @param {number} pageNo
 */
export const setCurrentIntegrationsPage = (pageNo) => {
    store.dispatch({
        payload: pageNo,
        type: currentIntegrationsPageActionType,
    });
};

/**
 * Set should reload page for integrations data grid
 * @param {boolean} shouldReload
 */
export const setShouldReloadIntegrationsPage = (shouldReload) => {
    store.dispatch({
        payload: shouldReload,
        type: shouldReloadIntegrationsActionType,
    });
};

/**
 * Set total integrations count
 * @param {number} count
 */
export const setTotalIntegrations = (count) => {
    store.dispatch({
        payload: count,
        type: totalIntegrationsCountActionType,
    });
};

/**
 * Set total integrations count
 * @param {boolean} isReady
 */
export const setIsIntegrationsDataReady = (isReady) => {
    store.dispatch({
        payload: isReady,
        type: isIntegrationsDataReadyActionType,
    });
};

/**
 * Optimistically update the integration data after any changes
 * instead of refetching the data from the server.
 *
 * Note: Minimum requirement for data to be updated is the integration ID
 * along with any other existing property(ies) that exists in the
 * integration data.
 *
 * @param {string} actionType Type of action to perform. Possible values: 'create', 'update', 'delete', 'copy'
 * @param {object} changedIntegrationData Changed integration data.
 */
export const updateIntegrationsOptimistically = (actionType, changedIntegrationData = {}) => {
    let integrationsList = [];
    const integrationsDataStates = store?.getState()?.integrationsData,
        integrations = integrationsDataStates?.[integrationsDataActionType],
        isDeleteActionType = actionType === 'delete',
        selectedIntegration = integrationsDataStates?.[selectedIntegrationForModalActionType],
        selectedIntegrationId = selectedIntegration?._id,
        changedIntegrationDataId = changedIntegrationData?._id;

    if (actionType === 'create') {
        integrationsList = integrations.concat(changedIntegrationData);
    } else if (actionType === 'update') {
        integrationsList = integrations?.map((integration) => {
            if (changedIntegrationDataId !== integration?._id) {
                return integration;
            }
            return changedIntegrationData;
        });
    } else if (isDeleteActionType) {
        integrationsList = integrations?.filter((integration) => selectedIntegrationId !== integration?._id);
        setShouldReloadIntegrationsPage(true);
    } else if (actionType === 'copy') {
        // Append the copied integration after the selected integration
        for (const integration of integrations) {
            const mergeCopiedIntegration =
                selectedIntegrationId === integration?._id ? [integration, changedIntegrationData] : [integration];

            integrationsList = integrationsList.concat(mergeCopiedIntegration);
        }
    }

    // This should never happen, but lets's bail data just in case
    // {integrationsList} is invalid
    if (!integrationsList) return;

    setIntegrationsData(integrationsList);

    // This is use to highlight the updated integration row in table
    const recentlyUpdatedIntegrationId = isDeleteActionType ? selectedIntegrationId : changedIntegrationDataId;

    setRecentlyUpdatedIntegrationId(recentlyUpdatedIntegrationId);

    // Clear the highlight integration row after 4 seconds
    setTimeout(() => {
        setRecentlyUpdatedIntegrationId('');
    }, 4000);
};

/**
 * Get all integration by user and subscribe to changes
 * @return {Array<object>}
 */
export const useUserIntegrations = () => {
    const userIntegrations = useSelector(
        (state) => state?.integrationsData?.[INTEGRATIONS_DATA_ACTIONS.USER_INTEGRATIONS] || [],
    );

    useEffect(() => {
        getAllIntegrations({
            successCallback: (res) => {
                store.dispatch({
                    payload: res?.data?.results || [],
                    type: INTEGRATIONS_DATA_ACTIONS.USER_INTEGRATIONS,
                });
            },
        });
    }, []);

    return userIntegrations;
};

/**
 * Get all integration by user
 * @return {Array<object>}
 */
export const useUserIntegrationsValue = () =>
    useSelector((state) => state?.integrationsData?.[INTEGRATIONS_DATA_ACTIONS.USER_INTEGRATIONS] || []);

/**
 * Get integration from all user's integrations by ID
 * @param {string} integrationId
 * @return {object} Integration data
 */
export const filterAllUserIntegrationsById = (integrationId) =>
    getStoreState(
        INTEGRATIONS_DATA_ACTIONS.USER_INTEGRATIONS,
        storeNamespace,
    )([])?.find((integration) => integration?._id === integrationId) || {};

/**
 * Get integrations
 */
export const getIntegrationsList = () => {
    const userId = getAuthUserId();
    if (userId) {
        getIntegrations({ userId, pageNo: 1 })
            .then((data) => {
                setIntegrationsData(data?.results || []);
                setTotalIntegrations(data?.totalNumberOfResults || 0);
                setIsIntegrationsDataReady(true);
            })
            .catch((err) => {
                setIntegrationsData([]);
                setTotalIntegrations(0);
                setIsIntegrationsDataReady(err);
            });
    }
};
