import { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import InputLabel from '../InputLabel';
import { inputValueType } from '../../../utils/typeUtils';
import { useInputFieldRef, useInputActionCallback } from '../../../hooks/useInputFieldHook';
import SelectFieldSearchMenu, { searchMenuProps } from '../../SelectFieldSearchMenu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 16;

/**
 * Select field component
 * Note: Props of the SelectFieldSearchMenu are also available directly.
 * Note: If `isSearchEnabled` is disabled, use the `inputProps` to pass props directly to the SelectField component.
 * @param {Object} props
 * @returns {React.ComponentElement}
 */
const SelectField = (props) => {
    const {
        value,
        items,
        inputRef,
        minLength,
        maxLength,
        onChange,
        renderValue,
        handleChange,
        defaultValue,
        searchPlaceholder,
        sx = { mt: 1, '& .MuiSelect-select': { padding: '10px 10px', fontSize: 14 } },
        name = '',
        type = 'text',
        error = false,
        label = 'Label',
        variant = 'outlined',
        disabled = false,
        required = true,
        gridProps = {},
        menuProps = {},
        labelProps = {},
        fullWidth = true,
        trimValue = true,
        showError = true,
        inputProps = {},
        helperText = '',
        placeholder = '',
        autoComplete = '',
        menuWidth = 410,
        menuHeight = ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        displayEmpty = true,
        renderWithGrid = true,
        isSearchEnabled = true,
        requiredLabel = ' *',
        actionCallback = null,
        isSubmittingForm = false,
        inputDataList = [],
        skipSelectPlaceholder = true,
        inputLabelComponent = 'label',

        // Whether to validate the input field when loaded
        validateOnLoad = false,

        // Whether to validate the input field as you type,
        // useful for auth fields such as: Verification code.
        realtimeValidationOnType = false,

        // Control whether to add the validation props to
        // the input field, e.g: minLength, maxLength, etc.
        validationProps = true,

        validate = {
            regex: null,
            errorLabel: '', // Default to: {label}
            emptyLabel: '', // Default to: `{label} is required`
            invalidLabel: '', // Default to: `{label} is invalid`

            // `${label} minimum ${pluralizeCharacterText(minLength)} is ${minLength}.`
            minLengthErrorLabel: '',

            // `${label} maximum ${pluralizeCharacterText(maxLength)} is ${maxLength}.`
            maxLengthErrorLabel: '',

            /**
             * A custom validator function.
             *
             * @param {string|number} value Input field value.
             * @param {HTMLFormElement} inputRef HTML form input element.
             *
             * @return {bool|object} True if field is valid.
             * Otherwise false or an object with the following properties:
             * - error: (bool) True if field is invalid.
             * - helperText: (React.ReactNode) Error helper text
             */
            customValidator: null,
        },

        /**
         * Validation error callback function. If defined, it fires if
         * validation fails.
         * @param {object} args
         * @param {number|string} args.value Input field value.
         * @param {HTMLFormElement} args.inputRef HTML form input element.
         * @returns {void}
         */
        validationErrorCallback = null,

        /**
         * Validation success callback function. If defined, it fires if
         * validation was successful.
         * @param {object} args
         * @param {number|string} args.value Input field value.
         * @param {HTMLFormElement} args.inputRef HTML form input element.
         * @returns {void}
         */
        // validationSuccessCallback = null,

        ...otherProps
    } = props;

    const inputFieldRef = useInputFieldRef(inputRef),
        hasShownErrorForFirstTime = useRef(false),
        autoFilledFallbackChecked = useRef(false),
        optionsRef = useRef({}),
        [hasError, setHasError] = useState(error),
        [selectedValue, setSelectedValue] = useState(value),
        [fieldHelperText, setFieldHelperText] = useState(helperText),
        [openSelectMenu, setOpenSelectMenu] = useState(false),
        regex = validate.regex,
        hasRegex = regex && typeof regex === 'object',
        errorLabel = validate.errorLabel || (typeof label === 'string' ? label : placeholder || 'Field'),
        isEmailField = type === 'email',
        invalidLabel = validate.invalidLabel || `${errorLabel} is invalid`,
        emptyErrorLabel = validate.emptyLabel || `${errorLabel} is required`,
        customValidator = validate.customValidator,
        minLengthErrorLabel = validate.minLengthErrorLabel,
        maxLengthErrorLabel = validate.maxLengthErrorLabel,
        fieldItems = items || inputDataList;

    // Run input field action callback if specified
    useInputActionCallback(inputFieldRef, actionCallback);

    // Ensure the radio field is checked correctly
    useEffect(() => {
        if (defaultValue !== undefined) {
            setSelectedValue(defaultValue);
        } else if (value !== undefined) {
            setSelectedValue(value);
        }
    }, [defaultValue, value]);

    /**
     * Make error a dependency to re-render component if
     * it's changed and painting is completed.
     */
    useEffect(() => {
        setHasError(error);
    }, [error]);

    /**
     * Make helperText a dependency to re-render component if
     * it's changed and painting is completed.
     */
    useEffect(() => {
        setFieldHelperText(helperText);
    }, [helperText]);

    /**
     * Handle input field error visibility
     */
    useEffect(() => {
        (validateOnLoad || isSubmittingForm) && validateInputField();
        /* eslint-disable */
    }, [isSubmittingForm, validateOnLoad]);

    /**
     * Get input field value
     * @returns {number|string} Input field value.
     */
    const getInputFieldValue = () => {
        if (inputRef?.current === null) return '';

        const normalizeValue = inputFieldRef.current?.value || '',
            fieldValue = trimValue ? normalizeValue?.toString()?.trim() : normalizeValue || '';

        return isEmailField ? fieldValue?.toLowerCase() : fieldValue;
    };

    /**
     * Pluralize the `character` text
     * @param {number} len Input field value length.
     * @return {string} Pluralized `characters` text if value length is greater
     * then 1. Otherwise 'character' is returned.
     */
    const pluralizeCharacterText = (len) => (len > 1 ? 'characters' : 'character');

    /**
     * Handle input field validation
     * @param {boolean} showError Use to control whether to error.
     * This is used to implement fallback for auto-filled input fields.
     */
    const validateInputField = (showError = true) => {
        const fieldValue = getInputFieldValue(),
            fieldValueLen = fieldValue?.length;

        // Required field check
        if (required && fieldValueLen < 1) {
            showError && displayFieldError(emptyErrorLabel);
            return;
        }

        // Minimum field value length check
        if (!isInputMinLengthOkay(fieldValueLen, showError)) {
            return;
        }

        // Maximum field value length check
        if (!isInputMaxLengthOkay(fieldValueLen, showError)) {
            return;
        }

        // Regex pattern check
        if (hasRegex && !regex?.test(fieldValue)) {
            displayFieldError(invalidLabel);
            return;
        }

        // Custom validator check
        if (!isCustomValidationCheckOkay(fieldValue)) return;

        // Clear field error
        showError && clearFieldError();

        // Fires validation success callback if defined
        // validationSuccessCallback &&
        //     validationSuccessCallback({
        //         value: fieldValue,
        //         inputRef: inputFieldRef,
        //     });

        return true;
    };

    /**
     * Check whether the field value minlength is okay.
     * @param {number} fieldValueLen Input field value length
     * @param {boolean} showError Whether to display error.
     * @returns {boolean} True if field value minlength is okay.
     * False otherwise.
     */
    const isInputMinLengthOkay = (fieldValueLen, showError) => {
        if (showError && minLength && fieldValueLen < minLength) {
            const minLengthError =
                minLengthErrorLabel || `${errorLabel} minimum ${pluralizeCharacterText(minLength)} is ${minLength}.`;

            displayFieldError(minLengthError);
            return false;
        }
        return true;
    };

    /**
     * Check whether the field value max-length is okay.
     * @param {number} fieldValueLen Input field value length
     * @param {boolean} showError Whether to display error.
     * @returns {boolean} True if field value max-length is okay.
     * False otherwise.
     */
    const isInputMaxLengthOkay = (fieldValueLen, showError) => {
        if (showError && maxLength && fieldValueLen > maxLength) {
            const maxLengthError =
                maxLengthErrorLabel || `${errorLabel} maximum ${pluralizeCharacterText(maxLength)} is ${maxLength}.`;

            displayFieldError(maxLengthError);
            return;
        }
        return true;
    };

    /**
     * Validate the input field value using the custom validator if defined.
     * @param {number|string} fieldValue Input field value.
     * @returns {boolean} True if validation is successful, false otherwise.
     */
    const isCustomValidationCheckOkay = (fieldValue) => {
        if (customValidator) {
            const validateInput = customValidator(fieldValue, inputFieldRef.current);

            if (true !== validateInput && (!validateInput || validateInput?.error)) {
                displayFieldError(validateInput?.helperText || invalidLabel);
                return false;
            }
        }

        return true;
    };

    /**
     * Display input field error
     * @param {String} errorText Field error helper text
     */
    const displayFieldError = (errorText) => {
        setHasError(true);
        setFieldHelperText(errorText);
        setErrorDisplayState();

        // Fires validation error callback if defined
        validationErrorCallback &&
            validationErrorCallback({
                value: getInputFieldValue(),
                inputRef: inputFieldRef,
            });
    };

    /**
     * Set the error display state
     */
    const setErrorDisplayState = () => {
        if (!hasShownErrorForFirstTime.current) {
            hasShownErrorForFirstTime.current = true;
        }
    };

    /**
     * Clear input field error
     */
    const clearFieldError = () => {
        setHasError(false);
        setFieldHelperText('');
    };

    /**
     * If field error is shown for the first time, then validate field
     * when value is changed.
     */
    const handleValueChange = async (e) => {
        const fieldValue = e.target.value;
        setSelectedValue(fieldValue);
        inputFieldRef.current.value = fieldValue;

        if (hasShownErrorForFirstTime.current || realtimeValidationOnType) {
            validateInputField();
        } else {
            // fallback for auto-filled input fields
            if (!autoFilledFallbackChecked.current && true === validateInputField(false)) {
                // Should run just once
                autoFilledFallbackChecked.current = true;
                setErrorDisplayState();
            }
        }

        // Todo: rename handleChange to onChange
        const handleChangeFunc = onChange ? onChange : handleChange;
        handleChangeFunc && (await handleChangeFunc(e));
    };

    /**
     * Close select menu
     */
    const handleCloseMenu = () => {
        setOpenSelectMenu(false);
    };

    const fieldMenuProps = {
            autoFocus: true,
            PaperProps: {
                style: {
                    maxHeight: menuHeight,
                    width: menuWidth,
                },
            },
            onKeyDown: (e) => {
                const targetElem = e.target,
                    isEnterKey = e.key?.toLowerCase() === 'enter';

                let menuElem = targetElem?.firstElementChild;
                if (!menuElem && targetElem?.classList?.contains('menuSearchInput')) {
                    menuElem = targetElem?.closest('.MuiList-root');
                }

                if (isEnterKey && menuElem && menuElem?.classList?.contains('MuiList-root')) {
                    const menuChildren = menuElem?.children;

                    const handleAlreadySelectedItem = () => {
                        for (const item of menuChildren) {
                            if (item?.classList?.contains('Mui-selected')) {
                                item?.click();
                                break;
                            }
                        }
                    };

                    if (menuChildren) {
                        const countChildren = menuChildren?.length;

                        if (1 === countChildren) {
                            menuChildren[0]?.click();
                        } else if (2 === countChildren) {
                            const secondMenuItem = menuChildren?.[1];
                            if (secondMenuItem) {
                                if (isSearchEnabled) {
                                    secondMenuItem?.click();
                                } else if (skipSelectPlaceholder && secondMenuItem?.getAttribute('data-value') === '') {
                                    secondMenuItem?.click();
                                } else {
                                    handleAlreadySelectedItem();
                                }
                            } else {
                                handleAlreadySelectedItem();
                            }
                        } else {
                            handleAlreadySelectedItem();
                        }
                    }
                }
            },
            ...menuProps,
            sx: {
                ...(menuProps.sx || {}),
                '& .MuiMenuItem-root': {
                    fontSize: '14px !important',
                },
            },
        },
        renderValueStyle = {
            color: '#33333345',
            fontSize: '14px',
        };

    const renderOptions =
        fieldItems &&
        fieldItems.map((inputData, index) => {
            // Skip first value if empty, and skipSelectPlaceholder is true, and field is required
            if (index === 0 && inputData.value === '' && skipSelectPlaceholder && required) return null;

            const itemValue = otherProps?.getItemValue
                    ? otherProps.getItemValue(inputData, index)
                    : inputData?.value || '',
                itemLabel = otherProps?.getItemLabel
                    ? otherProps.getItemLabel(inputData, index)
                    : inputData?.label || '',
                itemId = otherProps?.getItemId ? otherProps.getItemId(inputData, index) : itemValue;

            // This is use to quickly retrieve the selected input label
            optionsRef.current[itemValue] = itemLabel;

            return (
                <MenuItem
                    key={itemId}
                    value={itemValue}
                    selected={itemValue === selectedValue}
                    onClick={handleCloseMenu}
                >
                    {itemLabel}
                </MenuItem>
            );
        });

    const renderFieldValue = (selected) => {
        if (!selected?.toString()?.length) {
            return <span style={renderValueStyle}>{placeholder}</span>;
        }
        const itemLabel = optionsRef.current[selected];
        return renderValue ? renderValue({ label: itemLabel, value: selected }) : itemLabel;
    };

    const inputStyle = {
            height: '40px',
        },
        fieldProps = {
            sx: {
                backgroundColor: '#fff !important',
                '& .MuiInputBase-input': {
                    fontSize: '14px !important',
                    fontWeight: '400 !important',
                    backgroundColor: 'transparent !important',
                },
                '& .MuiInputBase-root': {
                    height: '40px',
                },
                ...(sx || {}),
            },
            name,
            value: selectedValue || '',
            error: hasError,
            variant: variant,
            inputRef: inputFieldRef,
            required: required,
            disabled: disabled,
            fullWidth: fullWidth,
            onChange: handleValueChange,
            minLength: !validationProps ? undefined : minLength,
            maxLength: !validationProps ? undefined : maxLength,
            placeholder: placeholder,
            displayEmpty: displayEmpty,
            autoComplete: autoComplete,
            // defaultValue:defaultValue,
            input: <OutlinedInput autoFocus={false} style={inputStyle} fullWidth label="" />,
            MenuProps: fieldMenuProps,
            renderValue: renderFieldValue,
            open: openSelectMenu,
            onClose: () => {
                setOpenSelectMenu(false);
            },
            SelectDisplayProps: {
                onKeyDown: (e) => {
                    const eventKey = e.key?.toLowerCase(),
                        canOpenSelectMenuWithKey = eventKey !== 'tab' && eventKey !== 'escape';

                    if (canOpenSelectMenuWithKey) {
                        !openSelectMenu && setOpenSelectMenu(true);
                    }
                },
                onClick: () => {
                    !openSelectMenu && setOpenSelectMenu(true);
                },
            },
            ...inputProps,
        };

    if (fieldProps?.disabled) {
        fieldProps.open = false;
    }

    const renderSelectField = (
        <>
            {label !== null && (
                <InputLabel
                    sx={labelProps}
                    label={label}
                    required={required}
                    requiredLabel={requiredLabel}
                    inputLabelComponent={inputLabelComponent}
                />
            )}

            {isSearchEnabled && fieldItems?.length > 5 ? (
                <SelectFieldSearchMenu
                    items={fieldItems}
                    required={required}
                    placeholder={searchPlaceholder}
                    selectComponentProps={fieldProps}
                    {...otherProps}
                />
            ) : (
                <Select {...fieldProps}>{renderOptions}</Select>
            )}

            {showError && <FormHelperText error={hasError}>{fieldHelperText}</FormHelperText>}
        </>
    );

    return renderWithGrid ? (
        <Grid item xs={12} md={6} {...gridProps}>
            {renderSelectField}
        </Grid>
    ) : (
        renderSelectField
    );
};

export const selectFieldProps = {
    sx: PropTypes.object,
    type: PropTypes.string,
    name: PropTypes.string,
    regex: PropTypes.object,
    error: PropTypes.bool,
    label: PropTypes.node,
    value: inputValueType,
    items: PropTypes.any,
    menuProps: PropTypes.object,
    menuWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    menuHeight: PropTypes.number,
    variant: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    validate: PropTypes.object,
    onChange: PropTypes.func,
    inputRef: PropTypes.object,
    fullWidth: PropTypes.bool,
    trimValue: PropTypes.bool,
    gridProps: PropTypes.object,
    inputProps: PropTypes.object,
    helperText: PropTypes.node,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    renderValue: PropTypes.func,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    defaultValue: inputValueType,
    displayEmpty: PropTypes.bool,
    autoComplete: PropTypes.string,
    handleChange: PropTypes.func,
    requiredLabel: PropTypes.node,
    actionCallback: PropTypes.object,
    reRenderCounter: PropTypes.number,
    validationProps: PropTypes.bool,
    validateOnLoad: PropTypes.bool,
    isSearchEnabled: PropTypes.bool,
    isSubmittingForm: PropTypes.bool,
    renderWithGrid: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    inputLabelComponent: PropTypes.node,
    realtimeValidationOnType: PropTypes.bool,
    validationErrorCallback: PropTypes.func,
    validationSuccessCallback: PropTypes.func,
    skipSelectPlaceholder: PropTypes.bool,
    inputDataList: PropTypes.any,
    labelProps: PropTypes.object,

    // Search menu props
    ...searchMenuProps,
};

SelectField.propTypes = selectFieldProps;

export default memo(SelectField);
