import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

const MappingIcon = (props) => {
    const fill = props.fill || 'white';

    return (
        <SvgIcon {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 10.5C14.3125 10.5 13.3125 11.3438 13.0625 12.4375C12.3125 12.3438 11.25 12.0625 10.4375 11.2812C9.90625 10.7812 9.5625 10.125 9.375 9.34375C10.3125 9 11 8.09375 11 7C11 5.625 9.875 4.5 8.5 4.5C7.09375 4.5 6 5.625 6 7C6 8.125 6.71875 9.09375 7.75 9.40625V15.625C6.71875 15.9375 6 16.9062 6 18C6 19.4062 7.09375 20.5 8.5 20.5C9.875 20.5 11 19.4062 11 18C11 16.9062 10.25 15.9375 9.25 15.625V12.2188C9.28125 12.25 9.34375 12.3125 9.40625 12.375C10.625 13.5312 12.2188 13.875 13.1875 13.9688C13.5625 14.875 14.4375 15.5312 15.5 15.5312C16.875 15.5312 18 14.4062 18 13.0312C18 11.625 16.875 10.5 15.5 10.5ZM8.5 6C9.03125 6 9.5 6.46875 9.5 7C9.5 7.5625 9.03125 8 8.5 8C7.9375 8 7.5 7.5625 7.5 7C7.5 6.46875 7.9375 6 8.5 6ZM8.5 19C7.9375 19 7.5 18.5625 7.5 18C7.5 17.4688 7.9375 17 8.5 17C9.03125 17 9.5 17.4688 9.5 18C9.5 18.5625 9.03125 19 8.5 19ZM15.5 14C14.9375 14 14.5 13.5625 14.5 13C14.5 12.4688 14.9375 12 15.5 12C16.0312 12 16.5 12.4688 16.5 13C16.5 13.5625 16.0312 14 15.5 14Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

MappingIcon.propTypes = {
    fill: PropTypes.string,
};

export default MappingIcon;
