import { processResponse, sendPostRequest } from './dataRequestUtils';
import { logError } from './envUtils';
import { getCurrentSelectedOrganizationId } from './loginUtils';

const ignoreErrorCodes = [404];
let requestStartTime = null,
    initialRequestData = {},
    requestStart = null;

const getAppUsageRequestUrl = (usageType) =>
    `organization/${getCurrentSelectedOrganizationId()}/type/${usageType}/usage`;

const getDataSize = (data) => {
    if (typeof TextEncoder !== 'undefined') {
        return new TextEncoder().encode(JSON.stringify(data || {})).length;
    }
    return Buffer.byteLength(JSON.stringify(data || {}));
};

const getRequestTime = () => performance?.now() || Date.now();

/**
 * Setup app usage request for a given type
 * @param {{ type: string, requestData: object }}
 * @return {object} Request data
 */
export const setupAppUsageRequestData = ({ type, requestData }) => {
    const _type = type === 'appRequest' ? 'app request' : type;
    requestStartTime = new Date().toISOString();
    requestStart = getRequestTime();
    initialRequestData = {
        type,
        executedAt: requestStartTime,
        organizationId: getCurrentSelectedOrganizationId(),
        requestSize: getDataSize(requestData),
        details: {
            type: `Added ${_type} usage`,
        },
    };

    return requestData;
};

const getAppUsageSuccessData = (res) => ({
    ...initialRequestData,
    responseCode: res?.status || 200,
    elapsedTime: getRequestTime() - requestStart,
    responseSize: getDataSize(res?.data || {}),
});

const getAppUsageErrorData = (res) => ({
    ...initialRequestData,
    responseCode: res?.response?.status || 500,
    elapsedTime: getRequestTime() - requestStart,
    responseSize: getDataSize(res?.response?.data || {}),
});

const getAppUsageRequestData = ({ res, success }) => {
    try {
        success ? getAppUsageSuccessData(res) : getAppUsageErrorData(res);
    } catch (e) {
        logError('addAppRequestUsage: ', e);
    }
};

const processAppUsageResponseArgs = {
    errorMessage: '',
    ignoreErrorCodes,
};

/**
 * Get app request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const addAppRequestUsage = (args) =>
    sendPostRequest({
        url: getAppUsageRequestUrl('appRequest'),
        data: getAppUsageRequestData(args),
        ...processResponse(processAppUsageResponseArgs),
    });

/**
 * Get mapping request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const addMappingUsage = (args) =>
    sendPostRequest({
        url: getAppUsageRequestUrl('mapping'),
        data: getAppUsageRequestData(args),
        ...processResponse(processAppUsageResponseArgs),
    });

/**
 * Get integration request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const addIntegrationUsage = (args) =>
    sendPostRequest({
        url: getAppUsageRequestUrl('integration'),
        data: getAppUsageRequestData(args),
        ...processResponse(processAppUsageResponseArgs),
    });

/**
 * Get Webhook request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const addWebhookUsage = (args) =>
    sendPostRequest({
        url: getAppUsageRequestUrl('webhook'),
        data: getAppUsageRequestData(args),
        ...processResponse(processAppUsageResponseArgs),
    });
