import PropTypes from 'prop-types';

import { Wrapper, ErrorImage, HeaderText, SubText, Button, BannerFooter, BannerFooterGradient } from './styles';

import icon404 from '../../assets/images/404Icon.svg';
import iconOops from '../../assets/images/oopsIcon.svg';
import bannerFooter from '../../assets/images/bannerFooter.svg';
import bannerFooterGradient from '../../assets/images/bannerFooterGradient.svg';

const ErrorPage = ({ errorCode }) => {
    const headerText = errorCode === 404 ? "Looks like you're lost" : 'Something went wrong',
        subText =
            errorCode === 404 ? "Sorry, we can't find the page you're looking for" : "That wasn't supposed to happen";

    const returnHome = () => {
        window.onbeforeunload = undefined;
        window.location.replace('/');
    };

    return (
        <Wrapper>
            <ErrorImage src={errorCode === 404 ? icon404 : iconOops} />
            <HeaderText>{headerText}</HeaderText>
            <SubText>{subText}</SubText>
            <Button onClick={returnHome}>Back to Home</Button>
            <BannerFooter src={bannerFooter} />
            <BannerFooterGradient src={bannerFooterGradient} />
        </Wrapper>
    );
};

ErrorPage.propTypes = {
    errorCode: PropTypes.number,
};

export default ErrorPage;
