import _ from 'lodash';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import useStyles from '../styling/jss';

import useSelectedOrganization from './useOrganizationHook';

import { useStoreValue, getStoreStates, setStoreState, setStoreStates, useStoreValues } from './useStoreStateHook';

import { showSnackBarErrorNotification, showSnackBarSuccessNotification } from '../utils/snackBarNotificationUtil';

import {
    getVendorInstancesById,
    getMergedAvailableInstanceField,
    getOrganizationVendorInstancesForDisplay,
} from '../utils/organizationUtils';

import {
    getAllVendorsList,
    insertVendorInstanceData,
    updateVendorInstancesData,
    getVendorInstanceDataByVendorId,
    markVendorInstanceAsDeleted,
} from '../utils/vendors';

/**
 * Vendor Registration States
 */
export const vendorRegistrationStates = {
    vendorsList: [], // vendorMaster
    currentVendorId: '',
    vendorInstances: [], // myVendors
    vendorCategories: [], // categories
    accordionExpanded: 0,
    hasSelectedVendor: false,
    selectedDeleteIndex: null,
    selectedVendorData: {},
    vendorInstanceStatus: 'Creating...',
    organizationVendorInstances: [],
    isCreatingVendorInstance: false,
    isCreateVendorFormSubmitted: false,
    isVendorInstanceFormSubmitted: false,
    openDeleteVendorInstanceModal: false,
    vendorInstanceProgressCounter: 0,
    isSelectedVendorInstanceReady: false,
    openVendorInstanceProgressModal: false,
    selectedVendorInterfaceTypesData: [],
    organizationVendorInstancesFormData: {},
    organizationVendorInstancesOriginal: [],
};

/**
 * Get an vendor registration state value and subscribe to changes
 * @param {String} state Vendor registration state name
 * @param {*} fallbackValue State fallback value
 * @returns {Function<*>}
 */
export const useVendorRegistrationValue = (state, fallbackValue = undefined) =>
    useStoreValue(state, 'vendorRegistration')(fallbackValue);

/**
 * Get the vendor registration state values and subscribe to changes
 * @returns {Object} Vendor registration state values
 */
export const useVendorRegistrationValues = () => useStoreValues('vendorRegistration');

/**
 * Get the vendor registration state values
 * @returns {Object} State value
 */
export const getVendorRegistrationStates = () => getStoreStates('vendorRegistration');

/**
 * Set an vendor registration state value
 * @param {String} state Vendor registration state name
 * @param {*} value State value
 */
export const setVendorRegistrationState = (state, value) => setStoreState(state)(value);

/**
 * Set vendor registration state values
 * @param {Object} values New state values
 *
 * Note: values are automatically mapped to their respective action type.
 */
export const setVendorRegistrationStates = (values) => setStoreStates(values);

/**
 * Reset vendor list and categories states
 */
const resetVendorsListState = () => {
    setVendorRegistrationStates({
        vendorsList: [],
        vendorCategories: [],
    });
};

/**
 * Setup the vendor list and categories
 * @param {Object} args
 * @param {Function} args.finallyCallback
 * @return {Function}
 */
export const setupVendorsListAndCategories =
    ({ finallyCallback = null } = {}) =>
    () => {
        getAllVendorsList()
            .then((data) => {
                if (data && data.length > 0) {
                    // Get all the vendors's category
                    const categories = [];
                    for (const o of data) {
                        if (!categories.includes(o.category)) {
                            categories.push(o.category);
                        }
                    }

                    const states = {
                        vendorsList: data,
                        vendorCategories: categories,
                    };

                    setVendorRegistrationStates(states);
                    return states;
                } else {
                    resetVendorsListState();
                }
                return {};
            })
            .catch((e) => {
                resetVendorsListState();
                showSnackBarErrorNotification(e);
            })
            .finally(() => {
                finallyCallback && finallyCallback();
            });
    };

/**
 * Vendor Registration Hook
 * @returns {Object} Vendor registration state
 */
const useVendorRegistration = () => {
    const { vendorId, vendorInstanceId } = useParams(),
        navigate = useNavigate(),
        classes = useStyles()(),
        [selectedOrganization] = useSelectedOrganization(),
        userOrganizationId = selectedOrganization?.organizationId,
        [isDataLoaded, setIsDataLoaded] = useState(false),
        [isVendorsListLoaded, setIsVendorsListLoaded] = useState(false),
        organizationVendorInstancesFormDataRef = useRef([]);

    /**
     * Get the vendor instances using a memoized method
     */
    const fetchVendorInstances = useCallback(async () => {
        // Wait until the {userOrganizationId} state is ready
        if (!userOrganizationId) return;

        getVendorInstancesById(userOrganizationId)
            .then((data) => {
                setVendorRegistrationStates({
                    vendorInstances: data && data.length > 0 ? data : [],
                });
            })
            .catch(() => {
                setVendorRegistrationStates({
                    vendorInstances: [],
                });
            })
            .finally(() => {
                setIsDataLoaded(true);
            });
    }, [userOrganizationId]);

    /**
     * Get all vendors list using a memoized method
     */
    const fetchAllVendorsList = useCallback(
        setupVendorsListAndCategories({
            finallyCallback: () => {
                setIsVendorsListLoaded(true);
            },
        }),
        [],
    );

    /**
     * Fetch the vendor instance and all vendors list once the user organization
     * is set.
     */
    useEffect(() => {
        fetchVendorInstances();
        fetchAllVendorsList();
    }, [userOrganizationId]);

    /**
     * Update vendor states
     */
    useEffect(() => {
        vendorId &&
            setVendorRegistrationStates({
                currentVendorId: vendorId,
                hasSelectedVendor: true,
                isCreatingVendorInstance: vendorId && !vendorInstanceId,
            });
    }, [vendorId, vendorInstanceId]);

    /**
     * Get a vendor from the vendor list
     * @param {String} vendorIdToFind
     * @returns {Promise<object>}
     */
    const getVendorData = async (vendorIdToFind) => {
        const { vendorsList } = getVendorRegistrationStates(),
            vendorData = vendorsList?.find((vendor) => vendor._id === vendorIdToFind);

        return vendorData ? _.cloneDeep(vendorData) : {};
    };

    /**
     * Get a vendor from the vendor list
     * @param {Object} vendorData
     * @see getVendorData()
     * @returns {Array}
     */
    const getVendorInterfacesData = (vendorData) => {
        const vendorInterfaceData = vendorData?.interfaces;
        return vendorInterfaceData ? _.cloneDeep(vendorInterfaceData) : [];
    };

    /**
     * Get default vendor instance fields from selected vendor data
     * @param {Object} selectedVendorData
     * @returns {Object}
     */
    const getDefaultVendorInstanceFields = (selectedVendorData) => {
        const copyOfData = _.cloneDeep(selectedVendorData);
        const availableInstanceFields = getMergedAvailableInstanceField(
            copyOfData.interfaces[0].interfaceType,
            copyOfData.interfaces[0].authenticationMethods[0]?.authenticationMethod || [],
            copyOfData,
        );

        const vendorInstanceFields = {
            vendorId: copyOfData._id,
            interfaceType: copyOfData.interfaces[0].interfaceType,
            authenticationMethod: copyOfData.interfaces[0].authenticationMethods[0]?.authenticationMethod || [],
            availableInstanceFields: availableInstanceFields,
            isNewInstanceObject: true,
        };

        availableInstanceFields.forEach((field) => {
            vendorInstanceFields[field.name] = field.type === 'select' ? field.listOfOptions[0].value : null;
        });

        return vendorInstanceFields ? _.cloneDeep(vendorInstanceFields) : {};
    };

    /**
     * Get the vendor's authentication methods
     * @param {String} vendorIdToFind
     * @param {String} interfaceType
     * @returns {Array<object>}
     */
    const getAuthenticationMethodsData = (vendorIdToFind, interfaceType) => {
        const { vendorsList } = getVendorRegistrationStates(),
            vendorData = vendorsList?.find((vendor) => vendor._id === vendorIdToFind);

        if (!vendorData) return [];

        const authenticationMethodsData = vendorData?.interfaces?.find(
            (vendor) => vendor.interfaceType === interfaceType,
        );

        if (authenticationMethodsData) {
            return authenticationMethodsData ? _.cloneDeep(authenticationMethodsData.authenticationMethods) : [];
        }
        return [];
    };

    /**
     * Vendor instanced
     * @param {Array<object>} vendorInstances
     */
    const setVendorInstancesOriginal = (vendorInstances) => {
        setVendorRegistrationStates({
            organizationVendorInstancesOriginal: _.cloneDeep(vendorInstances),
        });
    };

    /**
     * Load vendor instance to modify
     * @param {String} vendorIdToLoad
     * @returns {void}
     */
    const loadVendorInstancesToModify = (vendorIdToLoad) => {
        if (!vendorIdToLoad) return;

        const { vendorsList, selectedVendorData, isCreatingVendorInstance } = getVendorRegistrationStates();

        const vendorListToSet = [getDefaultVendorInstanceFields(selectedVendorData)],
            initialVendorListSize = vendorListToSet.length;

        let panelToExpandIndex = isCreatingVendorInstance ? 0 : 1;

        getVendorInstanceDataByVendorId({
            orgId: userOrganizationId,
            vendorId: vendorIdToLoad,
        })
            .then((data) => {
                if (data?.length) {
                    const instanceData = getOrganizationVendorInstancesForDisplay(
                        userOrganizationId,
                        vendorsList,
                        data,
                    );

                    vendorListToSet.push(...instanceData);

                    data.forEach((instance, index) => {
                        if (vendorInstanceId === instance._id) {
                            panelToExpandIndex = index + initialVendorListSize;
                        }
                    });
                }
            })
            .catch((e) => {
                showSnackBarErrorNotification(e);
            })
            .finally(() => {
                setVendorRegistrationStates({
                    accordionExpanded: isCreatingVendorInstance ? 'panel1' : 'panel' + (panelToExpandIndex + 1),
                    organizationVendorInstances: vendorListToSet,
                    isSelectedVendorInstanceReady: true,
                    // organizationVendorInstancesOriginal: [...vendorListToSet],
                });
            });
    };

    /**
     * Check whether the vendor instance form is valid
     * @return {Boolean}
     */
    const hasValidInstancesForm = () => {
        let numberOfInstances = 0,
            numberOfValidInstances = 0;

        const { currentVendorId, organizationVendorInstances, selectedVendorData, isCreatingVendorInstance } =
            getVendorRegistrationStates();

        if (!currentVendorId) {
            return false;
        }

        numberOfInstances = organizationVendorInstances.length;

        for (let i = 0; i < numberOfInstances; i++) {
            const availableInstanceFieldList = getMergedAvailableInstanceField(
                organizationVendorInstances[i]['interfaceType'],
                organizationVendorInstances[i]['authenticationMethod'],
                selectedVendorData,
            );

            const instance = _.cloneDeep(organizationVendorInstances[i]);
            const isCreateInstanceObject = instance.isNewInstanceObject,
                hasValidBaseFields =
                    instance.vendorId &&
                    instance.name &&
                    instance.authenticationMethod &&
                    instance.interfaceType &&
                    (isCreateInstanceObject || instance._id); // _id is only on loaded instances

            if (!isCreateInstanceObject || isCreatingVendorInstance) {
                if (hasValidBaseFields) {
                    let numberOfRequiredInstanceFields = 0,
                        numberOfValidRequiredInstanceFields = 0;

                    for (const field of availableInstanceFieldList) {
                        if (field.required) {
                            if (instance[field.name]) {
                                numberOfValidRequiredInstanceFields++;
                            }
                            numberOfRequiredInstanceFields++;
                        }
                    }

                    if (numberOfRequiredInstanceFields === numberOfValidRequiredInstanceFields) {
                        numberOfValidInstances++;
                    } else {
                        setVendorRegistrationState('accordionExpanded', `panel${i + 1}`);
                        break;
                    }
                } else {
                    setVendorRegistrationState('accordionExpanded', `panel${i + 1}`);
                    break;
                }
            } else {
                // ignore the create instance object if we're not in create mode
                numberOfValidInstances++;
            }
        }

        return numberOfInstances > 0 && numberOfInstances === numberOfValidInstances;
    };

    /**
     * Set the successful vendor instance creation state
     * @param {Boolean} hasSavedCreatedVendorInstances
     * @param {Boolean} hasSavedModifiedVendorInstances
     * @returns {void}
     */
    const finishSavingVendorInstances = (
        hasSavedCreatedVendorInstances = false,
        hasSavedModifiedVendorInstances = false,
        progressCounterInterval = null,
        progressCounter = 0,
    ) => {
        if (hasSavedCreatedVendorInstances && hasSavedModifiedVendorInstances) {
            if (progressCounter >= 100) {
                setVendorRegistrationStates({
                    ...vendorRegistrationStates,
                    // vendorInstanceStatus: 'Success!',
                    isVendorInstanceFormSubmitted: false,
                });

                progressCounterInterval && clearInterval(progressCounterInterval);
                progressCounterInterval = null;

                showSnackBarSuccessNotification('Vendor instances have been updated successfully.');
                navigate('/dashboard');
            }
        }
    };

    /**
     * Update vendor instance
     */
    const updateVendorInstances = () => {
        setVendorRegistrationStates({
            vendorInstanceStatus: 'Updating...',
            isVendorInstanceFormSubmitted: true,
        });

        if (!hasValidInstancesForm()) {
            showSnackBarErrorNotification('Please verify that you have entered all of the required fields.');
            return;
        }

        const { selectedVendorData, isCreatingVendorInstance } = getVendorRegistrationStates();

        const instancesToUpdate = [],
            instancesToCreate = [],
            organizationVendorInstances = organizationVendorInstancesFormDataRef.current || [];

        let progressCounterInterval,
            progressCounter = 10,
            numberOfInstancesToCreate = 0,
            numberOfInstancesToUpdate = 0,
            progressCounterCallback = null;

        setVendorRegistrationStates({
            // vendorInstanceProgressCounter: progressCounter,
            openVendorInstanceProgressModal: true,
        });

        // Progress counter
        progressCounterInterval = setInterval(() => {
            progressCounter += 10;
            setVendorRegistrationState('vendorInstanceProgressCounter', progressCounter);

            if (progressCounter >= 100) {
                clearInterval(progressCounterInterval);
                progressCounterInterval = null;

                let callbackTimeout = setTimeout(() => {
                    clearTimeout(callbackTimeout);
                    callbackTimeout = null;
                    progressCounterCallback && progressCounterCallback();
                }, 10);
            }
        }, 100);

        organizationVendorInstances.forEach((instance) => {
            if (instance.isNewInstanceObject) {
                if (isCreatingVendorInstance) {
                    const instanceToCreate = {
                        vendorId: organizationVendorInstances[0].vendorId,
                        instance: {
                            interfaceType: organizationVendorInstances[0].interfaceType,
                            authenticationMethod: organizationVendorInstances[0].authenticationMethod,
                            name: organizationVendorInstances[0].name,
                        },
                    };

                    const organizationVendorInstanceFields = getMergedAvailableInstanceField(
                        organizationVendorInstances[0].interfaceType,
                        organizationVendorInstances[0].authenticationMethod,
                        selectedVendorData,
                    );

                    organizationVendorInstanceFields.forEach((field) => {
                        instanceToCreate.instance[field.name] = organizationVendorInstances[0][field.name];
                    });

                    instancesToCreate.push(instanceToCreate);
                }
            } else {
                const instanceToUpdate = {
                    vendorId: instance.vendorId,
                    interfaceType: instance.interfaceType,
                    authenticationMethod: instance.authenticationMethod,
                    name: instance.name,
                    _id: instance._id,
                };

                const organizationVendorInstanceFields = getMergedAvailableInstanceField(
                    organizationVendorInstances[0].interfaceType,
                    organizationVendorInstances[0].authenticationMethod,
                    selectedVendorData,
                );

                organizationVendorInstanceFields.forEach((field) => {
                    instanceToUpdate[field.name] = instance[field.name];
                });

                instancesToUpdate.push(instanceToUpdate);
            }
        });

        numberOfInstancesToCreate = instancesToCreate.length;
        numberOfInstancesToUpdate = instancesToUpdate.length;

        if (numberOfInstancesToCreate) {
            insertVendorInstanceData(userOrganizationId, instancesToCreate[0])
                .then(() => {
                    progressCounterCallback = () =>
                        finishSavingVendorInstances(true, true, progressCounterInterval, progressCounter);
                })
                .catch(() => {
                    showSnackBarErrorNotification('An error occurred attempting to create a new vendor instance.');
                });
        }

        if (numberOfInstancesToUpdate) {
            updateVendorInstancesData(userOrganizationId, selectedVendorData._id, {
                instanceList: instancesToUpdate,
            })
                .then(() => {
                    progressCounterCallback = () =>
                        finishSavingVendorInstances(true, true, progressCounterInterval, progressCounter);
                })
                .catch(() => {
                    showSnackBarErrorNotification(
                        'An error occurred attempting to update the specified vendor instances',
                    );
                });
        }

        if (!numberOfInstancesToCreate && !numberOfInstancesToUpdate) {
            showSnackBarErrorNotification('An error occurred detecting which instances to modify.');
        }
    };

    /**
     * Delete vendor instance
     * @param {Integer} index Vendor instance index
     */
    const deleteVendorInstance = (index) => {
        setVendorRegistrationStates({
            vendorInstanceStatus: 'Deleting...',
            openDeleteVendorInstanceModal: false,
            isVendorInstanceFormSubmitted: true,
            openVendorInstanceProgressModal: true,
        });

        const { organizationVendorInstances } = getVendorRegistrationStates();

        const payloads = {
            vendorId: organizationVendorInstances[index].vendorId,
            update: {
                _id: organizationVendorInstances[index]._id,
            },
        };

        markVendorInstanceAsDeleted(userOrganizationId, payloads)
            .then((data) => {
                const vendorStates = {
                    isVendorInstanceFormSubmitted: false,
                };

                if (data) {
                    vendorStates.selectedDeleteIndex = null;
                    vendorStates.vendorInstanceStatus = 'Success!';
                }

                setVendorRegistrationStates(vendorStates);

                if (data) {
                    showSnackBarSuccessNotification('Vendor Instance Deleted Successfully');
                    navigate('/dashboard');
                }
            })
            .catch((e) => {
                showSnackBarErrorNotification(e);
            });
    };

    return {
        classes,
        navigate,
        vendorId,
        isDataLoaded,
        vendorInstanceId,
        userOrganizationId,
        isVendorsListLoaded,
        organizationVendorInstancesFormDataRef,

        /**
         * Functions
         */
        getVendorData,
        deleteVendorInstance,
        updateVendorInstances,
        getVendorInterfacesData,
        setVendorInstancesOriginal,
        loadVendorInstancesToModify,
        getAuthenticationMethodsData,
        getDefaultVendorInstanceFields,
    };
};

export default useVendorRegistration;
