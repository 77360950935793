export const comparisonActions = {
    ADD_NEW_COMPARISON: 'addNewComparison',
    ADD_NEW_CONDITION: 'addNewCondition',
    ADD_NEW_INPUT: 'addNewInput',
    ADD_NEW_GROUPING: 'addNewGrouping',
    ADD_NEW_EXPRESSION: 'addNewExpression',
    ADD_NEW_CONDITION_REFERENCE_FIELD: 'addNewConditionReferenceField',
    ADD_NEW_EXPRESSION_REFERENCE_FIELD: 'addNewExpressionReferenceField',
    ADD_MULTIPLE_EXPRESSIONS_TO_CONDITION: 'addMultipleExpressions',

    UPDATE_COMPARISON_NAME: 'updateName',
    UPDATE_COMPARISON_EXTERNAL_ID: 'updateExternalId',
    UPDATE_COMPARISON_CONDITION_EVALUATION_MODE: 'updateConditionEvaluationMode',
    UPDATE_COMPARISON_LEFT_OPERAND_INPUT_ID: 'updateLeftOperandInputId',
    UPDATE_COMPARISON_CONDITION_LIST: 'updateConditionList',
    UPDATE_COMPARISON_INPUT: 'updateInput',
    UPDATE_COMPARISON_CONDITION_LOGICAL_OPERATOR_PREFIX: 'updateConditionLogicalOperatorPrefix',
    UPDATE_COMPARISON_CONDITION_NAME: 'updateConditionName',
    UPDATE_COMPARISON_CONDITION_RIGHT_OPERAND_INPUT_ID: 'updateConditionRightOperandInputId',
    UPDATE_COMPARISON_DISPLAY_UNMATCHED_RESULT_VALUES_FOR_SUBSEQUENT_CONDITIONS:
        'updateDisplayUnmatchedResultValuesForSubsequentConditions',
    UPDATE_COMPARISON_CONDITION_DISPLAY_UNMATCHED_RESULT_VALUES_BASED_ON_MASTER:
        'updateDisplayUnmatchedResultValuesBasedOnMaster',
    UPDATE_COMPARISON_EXPRESSION_NAME: 'updateExpressionName',
    UPDATE_COMPARISON_EXPRESSION_FROM_VALUE: 'updateExpressionFromValue',
    UPDATE_COMPARISON_EXPRESSION_COMPARISON_OPERATOR: 'updateExpressionComparisonOperator',
    UPDATE_COMPARISON_EXPRESSION_TO_VALUE: 'updateExpressionToValue',
    UPDATE_COMPARISON_EXPRESSION_LOGICAL_OPERATOR_PREFIX: 'updateExpressionLogicalOperatorPrefix',
    UPDATE_COMPARISON_EXPRESSION_REFERENCE_FIELD: 'updateExpressionReferenceField',
    UPDATE_COMPARISON_REFERENCE_FIELD: 'updateConditionReferenceField',

    DELETE_INPUT: 'deleteInput',
    DELETE_COMPARISON: 'deleteComparison',
    DELETE_CONDITION: 'deleteCondition',
    DELETE_CONDITION_REFERENCE_FIELD: 'deleteConditionReferenceField',
    DELETE_GROUPING: 'deleteGrouping',
    DELETE_EXPRESSION: 'deleteExpression',
    DELETE_EXPRESSION_REFERENCE_FIELD: 'deleteExpressionReferenceField',
};

export const MAXIMUM_NESTED_GROUPING_LEVEL = 4;
