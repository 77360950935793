/**
 * Capitalizes the given string first character
 * @param {string} str String to capitalize first character from
 * @returns {string} String with first character capitalized. Empty string is returned if provided string is not valid.
 */
const capitalizeFirstLetter = (str) => {
    if (!str?.length) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export default capitalizeFirstLetter;
