import { useEffect } from 'react';

import { useUserEmail } from './useUserHook';
import useSelectedOrganization from './useOrganizationHook';
import { useStoreValue, setStoreState } from './useStoreStateHook';
import { getApplicationAccessByOrganizationSubscription } from '../utils/manageSubscriptionUtils';

const storeNamespace = 'appSubscriptions';

export const appSubscriptionsStoreStates = {
    applicationAccess: {
        hasFormsAccess: undefined,
        hasConnectAccess: undefined,
    },
};

/**
 * Get the app subscriptions state and subscribe to changes
 * @return {{hasConnectAccess: false|true, hasFormsAccess: false|true}}
 */
export const useApplicationAccess = () => useStoreValue('applicationAccess', storeNamespace)();

/**
 * Get app subscriptions from backend and setup store state
 * @return {{hasConnectAccess: false|true, hasFormsAccess: false|true}}
 */
const useAppSubscriptions = () => {
    const userEmail = useUserEmail(),
        [currentSelectedOrganization] = useSelectedOrganization(),
        applicationAccess = useApplicationAccess();

    useEffect(() => {
        async function fetchAppAccess() {
            const organizationId = currentSelectedOrganization?.organizationId;
            if (organizationId) {
                getApplicationAccessByOrganizationSubscription({
                    userEmail,
                    organizationId,
                })
                    .then((appAccess) => {
                        setStoreState('applicationAccess')(appAccess);
                    })
                    .catch();
            }
        }

        if (applicationAccess?.hasFormsAccess === undefined) {
            fetchAppAccess();
        }

        return () => false;
    }, [currentSelectedOrganization, userEmail]);

    return applicationAccess;
};

export default useAppSubscriptions;
