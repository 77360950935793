import { authorizedAxiosInstance } from '../axiosInstances';
import { getCurrentUserId } from './loginUtils';

const BASE_URL = process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST;

/**
 * Get all instances
 */
export const getOrganizationVendorInstances = (params) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .get(`${BASE_URL}/user/${getCurrentUserId()}/organization/${params.orgId}/vendor/instances`)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((res) => {
                reject(res.error);
            });
    });
};

/**
 * Insert vendor data
 */
export const insertVendorInstanceData = (orgId, payloads) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .post(`${BASE_URL}/organization/${orgId}/vendor/${payloads.vendorId}/instance`, payloads)
            .then((resp) => {
                if (resp.status === 200) {
                    resolve(resp.data);
                } else {
                    reject(resp.error);
                }
            })
            .catch((resp) => {
                reject(resp.error);
            });
    });
};

export const markVendorInstanceAsDeleted = (organizationId, payloads) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(
                `${BASE_URL}/organization/${organizationId}/vendor/${payloads.vendorId}/instance/${payloads.update._id}/markDeleted`,
            )
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((res) => {
                reject(res.error);
            });
    });
};

/**
 * Get all vendor list
 */
export const getAllVendorsList = () => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .get(`${BASE_URL}/vendors`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch((response) => {
                reject(response);
            });
    });
};

/**
 * Get vendor instance data by vendorId
 */
export const getVendorInstanceDataByVendorId = (body) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .get(`${BASE_URL}/organization/${body.orgId}/vendor/${body.vendorId}/instances`)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((res) => {
                reject(res.error);
            });
    });
};

/**
 * Get vendor instances data by vendorId
 */
export const updateVendorInstancesData = (organizationId, vendorId, payloads) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(`${BASE_URL}/organization/${organizationId}/vendor/${vendorId}/instances`, payloads)
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Get app name and instance name
 * @param {Object} instanceData
 * @returns {String}
 */
export const getAppInstanceLabel = (instanceData) => {
    return `${instanceData.appName} - ${instanceData.name}`;
};
