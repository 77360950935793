/**
 *
 * @param a
 * @param b
 * @returns {boolean}
 */
export const isSameNumber = (a, b) => {
    const aValue = typeof a === 'number' || typeof a === 'string' ? parseInt(a, 10) : false;
    const bValue = typeof b === 'number' || typeof b === 'string' ? parseInt(b, 10) : false;

    return aValue !== false && bValue !== false && aValue === bValue;
};

export const isNumber = (value) => {
    const temp = typeof value === 'number' || typeof value === 'string' ? parseInt(value, 10) : false;

    return temp !== false && !isNaN(temp);
};

/**
 * Generate random string of any length
 * @param {Object} args
 * @param {Number} args.length
 * @param {String} args.acceptedChars
 * @param {Boolean} args.isLowercase
 * @returns {String} Generated random string
 */
export const generateRandomString = ({
    length = 32,
    acceptedChars = '0123456789abcdefghijklmnopqrstuvwxyz',
    isLowercase = true,
} = {}) => {
    let result = '';
    const chars = isLowercase ? acceptedChars : `${acceptedChars}ABCDEFGHIJKLMNOPQRSTUVWXYZ`;

    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
};

/**
 * Generates ObjectId()
 * @param {Object} args
 * @returns {String}
 */
export const ObjectId = ({ m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h) } = {}) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

/**
 * Open new window tab
 * @param {String} relativePath
 * @return {Object|null} New window handle
 */
export const openNewWindowRelativeTab = (relativePath) => {
    const locationHost = window.location.host;
    if (locationHost?.indexOf('intely') > -1) {
        const windowHandle = window.open(`https://${locationHost}/${relativePath}`, '_blank');
        windowHandle.blur();
        window.focus();
        return windowHandle;
    }
    return null;
};

/**
 * Get current timestamp. Equivalent to Date.now().
 * @return {Number}
 */
export const getCurrentTimestamp = () => Date.now();

/**
 * Generate random numbers between min and max inclusively.
 * @param {Number} min. Default: 0.
 * @param {Number} max. Default: 9.
 * @param {Number} len. Default: 9.
 * @returns {Number} Random numbers
 */
export const getRandomInt = (min = 0, max = 9, len = 9) => {
    const _min = Math.ceil(min),
        _max = Math.floor(max);

    let randomNum = '';
    for (let i = 0; i < len; i++) {
        randomNum += Math.floor(Math.random() * (_max - _min + 1)) + _min;
    }

    return parseInt(randomNum);
};

/**
 * Check if a value is empty.
 * @param {string|number|array} value
 * @returns {boolean}
 */
export const isEmpty = (value) => {
    return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
};
