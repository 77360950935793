import SvgIcon from '@mui/material/SvgIcon';

const StepRequest = (props) => {
    return (
        <SvgIcon width="21" height="16" viewBox="0 0 21 16" fill="#000" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M4.15002 8.5H10.15V10H11.65V8.5H17.65V10H19.15V8.21875C19.15 7.5625 18.5875 7 17.9313 7H11.65V5H12.9C13.4313 5 13.9 4.5625 13.9 4V1C13.9 0.46875 13.4313 0 12.9 0H8.90002C8.33752 0 7.90002 0.46875 7.90002 1V4C7.90002 4.28125 7.99377 4.53125 8.18127 4.71875C8.36877 4.90625 8.61877 5 8.90002 5H10.15V7H3.83752C3.18127 7 2.65002 7.5625 2.65002 8.21875V10H4.15002V8.5ZM9.40002 3.5V1.5H12.4V3.5H9.40002ZM19.9 11H16.9C16.3375 11 15.9 11.4688 15.9 12V15C15.9 15.5625 16.3375 16 16.9 16H19.9C20.4313 16 20.9 15.5625 20.9 15V12C20.9 11.4688 20.4313 11 19.9 11ZM19.4 14.5H17.4V12.5H19.4V14.5ZM12.4 11H9.40002C8.83752 11 8.40002 11.4688 8.40002 12V15C8.40002 15.5625 8.83752 16 9.40002 16H12.4C12.9313 16 13.4 15.5625 13.4 15V12C13.4 11.4688 12.9313 11 12.4 11ZM11.9 14.5H9.90002V12.5H11.9V14.5ZM4.90002 11H1.90002C1.33752 11 0.900024 11.4688 0.900024 12V15C0.900024 15.5625 1.33752 16 1.90002 16H4.90002C5.43127 16 5.90002 15.5625 5.90002 15V12C5.90002 11.4688 5.43127 11 4.90002 11ZM4.40002 14.5H2.40002V12.5H4.40002V14.5Z" />
        </SvgIcon>
    );
};
export default StepRequest;
