import SvgIcon from '@mui/material/SvgIcon';

const TimeCircleIcon = (props) => {
    return (
        <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 0C2.2379 0 0 2.2379 0 5C0 7.7621 2.2379 10 5 10C7.7621 10 10 7.7621 10 5C10 2.2379 7.7621 0 5 0ZM5 9.03226C2.7621 9.03226 0.967742 7.2379 0.967742 5C0.967742 2.78226 2.7621 0.967742 5 0.967742C7.21774 0.967742 9.03226 2.78226 9.03226 5C9.03226 7.2379 7.21774 9.03226 5 9.03226ZM7.03629 3.75C7.1371 3.66935 7.1371 3.50806 7.03629 3.40726L6.59274 2.96371C6.49194 2.8629 6.33065 2.8629 6.25 2.96371L5 4.21371L3.72984 2.96371C3.64919 2.8629 3.4879 2.8629 3.3871 2.96371L2.94355 3.40726C2.84274 3.50806 2.84274 3.66935 2.94355 3.75L4.19355 5L2.94355 6.27016C2.84274 6.35081 2.84274 6.5121 2.94355 6.6129L3.3871 7.05645C3.4879 7.15726 3.64919 7.15726 3.72984 7.05645L5 5.80645L6.25 7.05645C6.33065 7.15726 6.49194 7.15726 6.59274 7.05645L7.03629 6.6129C7.1371 6.5121 7.1371 6.35081 7.03629 6.27016L5.78629 5L7.03629 3.75Z"
                fill="#FF0000"
            />
        </SvgIcon>
    );
};
export default TimeCircleIcon;
