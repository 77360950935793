import { useStoreValue, setStoreStates } from './useStoreStateHook';

const storeNamespace = 'loadingOverlay';

export const loadingOverlayStates = {
    loadingOverlayDetails: {
        open: false,
        text: '',
        timeUntilDisplay: 0,
        cancelDisplay: false,
    },
};

/**
 * Get loadingOverlay state value and subscribe to changes.
 * @returns {Object} loadingOverlay states
 */
export const useLoadingOverlayHook = () => useStoreValue('loadingOverlayDetails', storeNamespace)({});

/**
 * Display loading overlay.
 * @param {String} loadingOverlayText
 * @param {Number} timeUntilDisplay
 * @returns {Function} Function to cancel displaying the overlay if it has not opened yet
 */
export const displayLoadingOverlay = (loadingOverlayText = '', timeUntilDisplay = 0) => {
    if (isNaN(parseInt(timeUntilDisplay)) || timeUntilDisplay < 0) {
        throw RangeError('timeUntilDisplay must be a positive integer');
    }

    setStoreStates({
        loadingOverlayDetails: {
            open: true,
            text: loadingOverlayText,
            timeUntilDisplay,
            cancelDisplay: false,
        },
    });

    return () => {
        setStoreStates({
            loadingOverlayDetails: {
                open: true,
                text: loadingOverlayText,
                timeUntilDisplay,
                cancelDisplay: true,
            },
        });
    };
};

/**
 * Hide loading overlay.
 */
export const hideLoadingOverlay = () =>
    setStoreStates({
        loadingOverlayDetails: {
            open: false,
            text: '',
            timeUntilDisplay: 0,
            cancelDisplay: false,
        },
    });
