import { useStoreValue, setStoreState } from './useStoreStateHook';

const storeNamespace = 'integrationExecutions';

export const integrationExecutionStates = {
    currentIntegrationId: '',
    currentIntegrationData: null,
    integrationExecutionResults: [],
    currentIntegrationExecutionResultPageNumber: 1,
    totalNumberOfIntegrationExecutions: 0,
    integrationExecutionDataReady: false,
    reloadIntegrationExecutionDataGrid: false,
    integrationExecutionLogModalState: {},
};

/*
    Use Integration Execution store values
 */
export const useCurrentIntegrationId = () => useStoreValue('currentIntegrationId', storeNamespace)('');
export const useCurrentIntegrationData = () => useStoreValue('currentIntegrationData', storeNamespace)(null);
export const useIntegrationExecutionResults = () => useStoreValue('integrationExecutionResults', storeNamespace)([]);
export const useTotalNumberOfIntegrationExecutions = () =>
    useStoreValue('totalNumberOfIntegrationExecutions', storeNamespace)(0);
export const useCurrentIntegrationExecutionResultPageNumber = () =>
    useStoreValue('currentIntegrationExecutionResultPageNumber', storeNamespace)(1);
export const useIsIntegrationExecutionDataReady = () =>
    useStoreValue('integrationExecutionDataReady', storeNamespace)(false);
export const useIntegrationExecutionLogModalState = () =>
    useStoreValue('integrationExecutionLogModalState', storeNamespace)({});

/*
    Set Integration Execution store values
 */
export const setCurrentIntegrationId = (value) => setStoreState('currentIntegrationId')(value);
export const setCurrentIntegrationData = (value) => setStoreState('currentIntegrationData')(value);
export const setIntegrationExecutionResults = (payload) => setStoreState('integrationExecutionResults')(payload);
export const setTotalNumberOfIntegrationExecutions = (count) =>
    setStoreState('totalNumberOfIntegrationExecutions')(count);
export const setCurrentIntegrationExecutionResultPageNumber = (count) =>
    setStoreState('currentIntegrationExecutionResultPageNumber')(count);
export const setIsIntegrationExecutionDataReady = (payload) => setStoreState('integrationExecutionDataReady')(payload);
export const setIntegrationExecutionLogModalState = (payload) =>
    setStoreState('integrationExecutionLogModalState')(payload);
