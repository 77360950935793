import { logError } from './envUtils';

/**
 * Check whether a given URL is valid
 * @param {string} url
 * @returns {boolean}
 */
export const isUrlValid = (url) => {
    try {
        const urlObj = new URL(url);

        if (urlObj.protocol === 'https:') {
            return true;
        }
    } catch (error) {
        logError(error);
    }

    return false;
};
