import SvgIcon from '@mui/material/SvgIcon';

const SetVariableIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="black"
            viewBox="0 0 1000 1000"
        >
            <g>
                <g transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)">
                    <path d="M429.1,3782.6c-130.2-61.3-243.2-181.9-293-310.2c-38.3-105.3-38.3-113-34.5-4126.7l5.7-4023.3l63.2-55.5l61.3-55.5H5000h4768.2l61.3,55.5l63.2,55.5l5.7,4023.3c3.8,4013.7,3.8,4021.3-34.5,4126.7c-49.8,128.3-162.8,248.9-293,310.2l-99.6,46H5000H528.6L429.1,3782.6z M9423.5,3416.9c82.3-42.1,95.8-122.6,95.8-559.2V2469H5000H480.8v388.7c0,212.6,9.6,419.4,21.1,457.7c38.3,139.8-354.3,128.3,4494.3,130.2C8847.1,3445.6,9373.7,3441.8,9423.5,3416.9z M9515.4-1163.6l-5.7-3232.4H5000H490.3l-5.7,3232.4l-3.8,3230.5H5000h4519.2L9515.4-1163.6z" />
                    <path d="M3956.4,879.6c-262.3-68.9-438.5-168.5-649.2-363.8C2822.7,61.9,2547-652.3,2545.1-1456.6c-1.9-377.2,26.8-530.4,141.7-764.1c130.2-270,348.5-444.2,631.9-503.6c134-28.7,145.5-19.2,162.8,114.9c11.5,95.8,9.6,97.7-44,111.1c-319.8,76.6-469.2,411.7-440.4,993.9c36.4,775.5,270,1487.9,612.8,1861.3c137.9,149.4,245.1,226,400.2,281.5c116.8,42.1,118.7,44,141.7,136c17.2,67,17.2,99.6,1.9,118.7C4128.7,923.6,4121,923.6,3956.4,879.6z" />
                    <path d="M6782.8,893c-11.5-15.3-26.8-65.1-32.5-113l-11.5-88.1l93.8-30.6c187.7-63.2,308.3-214.5,371.5-471.1c38.3-153.2,32.6-681.7-9.6-947.9c-153.2-953.6-520.9-1577.9-1014.9-1721.5c-84.2-24.9-101.5-57.4-105.3-187.7c-1.9-86.2,17.2-90,199.2-44c670.2,172.3,1206.4,871.3,1373,1790.5C7698.1-635.1,7702-120,7656,88.7c-65.1,287.2-216.4,536.2-398.3,662.6C7093,864.3,6826.9,942.8,6782.8,893z" />
                    <path d="M5821.5-123.8c-47.9-11.5-113-42.1-145.5-65.1c-91.9-65.1-237.5-233.6-360-413.6c-61.3-90-114.9-162.8-116.8-158.9c-1.9,1.9-30.6,99.6-63.2,214.5c-34.5,116.8-82.3,243.2-109.2,281.5C4917.7-97,4708.9-74,4408.3-196.6c-155.1-61.3-162.8-67-189.6-145.5c-40.2-122.6-19.2-137.9,153.2-101.5c120.6,23,147.4,24.9,195.3,0c90-44,120.6-105.3,214.5-434.7l88.1-310.2l-49.8-86.2c-53.6-91.9-254.7-348.5-302.6-388.7c-24.9-19.2-49.8-19.2-137.9,3.8c-235.5,61.3-413.6-44.1-413.6-245.1c0-160.8,88.1-241.3,262.3-241.3c178.1,0,377.2,164.7,608.9,505.5c63.2,91.9,107.2,141.7,113,124.5c5.7-15.3,19.1-65.1,30.6-113c82.4-335.1,164.7-459.6,329.4-497.9c135.9-32.6,308.3-13.4,490.2,57.5c151.3,57.4,153.2,59.4,174.2,147.5c30.7,114.9,13.4,130.2-113,103.4c-132.1-28.7-220.2-26.8-283.4,5.8c-109.2,57.5-134,107.2-245.1,509.4l-65.1,239.4l122.5,181.9c68.9,99.6,153.2,210.6,191.5,247l67,65.1l122.6-24.9c258.5-47.9,413.6,63.2,400.2,287.2C6162.4-156.4,6005.3-74,5821.5-123.8z" />
                </g>
            </g>
        </SvgIcon>
    );
};
export default SetVariableIcon;
