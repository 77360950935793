import SvgIcon from '@mui/material/SvgIcon';

const StepRequest = (props) => {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.9938 0.90625L13.3688 2.53125C11.9625 1.125 10.025 0.25 7.90003 0.25C3.74378 0.25 0.337526 3.53125 0.150026 7.625C0.118776 7.84375 0.306276 8 0.525026 8H1.40003C1.58753 8 1.74378 7.875 1.77503 7.65625C1.96253 4.4375 4.61878 1.875 7.90003 1.875C9.58753 1.875 11.1188 2.5625 12.2125 3.6875L10.525 5.375C10.275 5.625 10.4625 6 10.775 6H15.275C15.4625 6 15.65 5.84375 15.65 5.625V1.15625C15.65 0.84375 15.2438 0.65625 14.9938 0.90625ZM15.2438 8H14.3688C14.1813 8 14.025 8.15625 13.9938 8.375C13.8063 11.5938 11.15 14.125 7.90003 14.125C6.18128 14.125 4.65003 13.4688 3.55628 12.3438L5.24378 10.6562C5.49378 10.4062 5.30628 10 4.99378 10H0.525026C0.306276 10 0.150026 10.1875 0.150026 10.375V14.875C0.150026 15.1875 0.525026 15.375 0.775026 15.125L2.40003 13.5C3.80628 14.9062 5.74378 15.75 7.90003 15.75C12.025 15.75 15.4313 12.5 15.6188 8.40625C15.65 8.1875 15.4625 8 15.2438 8Z"
                fill="#000"
            />
        </SvgIcon>
    );
};
export default StepRequest;
