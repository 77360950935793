import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import { useState } from 'react';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import styles from './styles';
import PrimaryButton from '../Buttons/PrimaryButton';
import { getIntegrationLogOutputHeadingStyleObject, getLogOutputEntryObjectStepId } from '../../utils/integrationUtils';

dayjs.extend(utc);

/**
 * Toggle Object Data
 * @param {{ data: object, maxStringLength: number, open: boolean }} props
 * @returns {React.Component}
 */
export const ToggleObjectData = ({ data = {}, open = false, maxStringLength = 1024 }) => {
    const [expand, setExpand] = useState(open),
        ArrowIcon = expand ? ArrowDropUpIcon : ArrowDropDownIcon;

    const handleSetExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={styles.toggleObjectWrapper}>
            <PrimaryButton
                onClick={handleSetExpand}
                backgroundColor="#999"
                color="#fff"
                fontSize="13px"
                height="25px"
                padding="5px 10px"
            >
                {expand ? 'Collapse' : 'Expand'} {' Object'}
                <ArrowIcon sx={styles.arrow} />
            </PrimaryButton>

            {expand && (
                <pre style={styles.pre}>
                    {JSON.stringify(
                        data,
                        (_, value) => {
                            if (typeof value === 'string' && value?.length > maxStringLength) {
                                return (
                                    value?.substring(0, maxStringLength) +
                                    `...(${value?.length - maxStringLength} more)`
                                );
                            }

                            return value;
                        },
                        4,
                    )}
                </pre>
            )}
        </div>
    );
};
ToggleObjectData.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.any,
    maxStringLength: PropTypes.number,
};

/**
 * String Output
 * @param {{ data: object, message: any }} props
 * @returns {React.Component}
 */
export const StringOutput = ({ data, message }) => {
    const timestampString = dayjs(data?.timestamp).format(`YYYY-MM-DD HH:mm:ss`),
        headingStyle = getIntegrationLogOutputHeadingStyleObject(data.heading),
        hasMessage = message !== undefined,
        output = hasMessage ? message : headingStyle.output,
        valueStyle = headingStyle.valueStyle,
        stepId = hasMessage ? getLogOutputEntryObjectStepId({ stepId: data?.stepId, message }) : '';

    let rootClassName = hasMessage ? `testDataItem log-entry-step-${stepId}` : 'testDataItem';

    if (message?.match(/^Step -/gi) && message?.indexOf('stepType=') > -1) {
        rootClassName += ` mainTestDataItem-${stepId}`;
    }

    return (
        <div className={rootClassName} style={styles.wrapText}>
            <span style={styles.time}>{timestampString}</span>
            <span style={styles.colon}>:</span>
            <span className="log-entry-item-text" style={valueStyle}>
                {output}
            </span>
        </div>
    );
};
StringOutput.propTypes = {
    data: PropTypes.any,
    message: PropTypes.any,
};
