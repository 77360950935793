import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

const IntegrationBuilderStepDrawerFieldGrid = ({
    label,
    children,
    sm = 12,
    style = undefined,
    labelStyle = {},
    isRequired = true,
    justifyContent = undefined,
    integrationBuilderProps,
    display = true,
}) => {
    const { classes } = integrationBuilderProps;

    return display ? (
        <Grid item sm={sm} classes={{ root: classes.rightDrawerSelect }} style={style} justifyContent={justifyContent}>
            {label && (
                <Typography component="div" style={{ color: '#000', ...labelStyle }}>
                    {label}
                    {isRequired && <span style={{ color: 'red', marginLeft: '3px' }}>*</span>}
                </Typography>
            )}
            {children}
        </Grid>
    ) : null;
};

IntegrationBuilderStepDrawerFieldGrid.propTypes = {
    sm: PropTypes.number,
    style: PropTypes.object,
    isRequired: PropTypes.bool,
    justifyContent: PropTypes.string,
    label: PropTypes.node.isRequired,
    labelStyle: PropTypes.object,
    children: PropTypes.node.isRequired,
    integrationBuilderProps: PropTypes.object.isRequired,
    display: PropTypes.bool,
};

export default IntegrationBuilderStepDrawerFieldGrid;
