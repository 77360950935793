import { getStoreState, useStoreValue } from './useStoreStateHook';
import useSelectedOrganization from './useOrganizationHook';

/**
 * Get a given property from the authenticated user data object.
 * @param {string} prop The name of the property in the user data object to retrieve.
 * @param {any} fallbackValue Value to fallback to if the property is not found. Default value is undefined.
 * @returns {any} The value of the property if found. Otherwise ${fallbackValue} is returned.
 */
export function useUserProp(prop, fallbackValue = undefined) {
    const user = useUser(),
        userProp = user?.[prop];

    if (userProp === undefined) {
        return fallbackValue;
    }
    return userProp;
}

/**
 * Get the authenticated user ID
 * @returns {string|undefined} The authenticated user ID on success. Otherwise undefined.
 */
export function useUserId() {
    return useUserProp('id');
}

/**
 * Get the authenticated user email address
 * @returns {string|undefined} The authenticated user email address on success. Otherwise undefined..
 */
export function useUserEmail() {
    return useUserProp('email');
}

/**
 * Check whether the authenticated user is admin or not.
 * @returns {boolean} True if current logged in user is the admin. Otherwise false.
 */
export function useIsUserAdmin() {
    const [selectedOrganization] = useSelectedOrganization();
    return selectedOrganization.isAdmin ? true : false;
}

/**
 * Get the authenticated user data
 * @returns {object|undefined} The authenticated user data on success.
 * Otherwise undefined.
 */
export const getAuthUserData = () => getStoreState('data', 'userdata')({});

/**
 * Get auth user ID
 * @return {string}
 */
export const getAuthUserId = () => getAuthUserData()?.id || '';

/**
 * Get the authenticated user data
 * @returns {object|undefined} The authenticated user data on success.
 * Otherwise undefined.
 */
const useUser = () => useStoreValue('data', 'userdata')({});
export default useUser;
