import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { createTheme, ThemeProvider as MUIThemeProvider /*StyledEngineProvider*/ } from '@mui/material/styles';

/**
 * Redefine the props for MUI theme provider and discard the 'exact-prop' check.
 *
 * Note: The `exact-prop` is a function in check ThemeProvider.propTypes
 * which is used to check for the exact prop types.
 */
MUIThemeProvider.propTypes = {
    theme: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// setup a cache for our dynamic styles so they will have the nonce attribute attached for CSP
const styledCache = createCache({
    key: 'main-style-cache-key',
    nonce: window.nonce,
    prepend: true,
});

export default function ThemeProvider({ children }) {
    const themeMode = 'light',
        isLight = themeMode === 'light',
        themeDirection = 'ltr';

    const themeOptions = useMemo(
        () => ({
            shape: { borderRadius: 8 },
            direction: themeDirection,
            breakpoints: {
                values: {
                    // We need to redefine the MUI default breakpoints
                    xs: 0,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                    collapseMenu: 1280,
                },
            },
        }),
        [isLight, themeDirection],
    );

    const theme = createTheme(themeOptions);

    return (
        // <StyledEngineProvider injectFirst>
        <CacheProvider value={styledCache}>
            <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
        </CacheProvider>
        // </StyledEngineProvider>
    );
}
