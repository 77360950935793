import { useState, useEffect } from 'react';

import store from '../redux/store';
import useUser from './useUserHook';

const cacheKeyNamespace = 'intely-connect';

/**
 * Get cache key
 * @param {string} key Cached key name
 * @param {string} userEmail User email to use to namespace the cache data.
 *                           Default value: ''
 * @returns {string} Cache key
 */
const getCacheKey = (key, userEmail = '') =>
    userEmail?.length ? `${cacheKeyNamespace}/${userEmail}/${key}` : `${cacheKeyNamespace}/${key}`;

/**
 * Helper to get a local storage data
 * @param {String} cacheKey
 * @param {any} fallbackValue
 * @returns {any}
 */
const getLocalStorageDataHelper = (cacheKey, fallbackValue = null) => {
    try {
        let item = localStorage?.getItem(cacheKey) || {};

        if (typeof item === 'string') {
            item = JSON.parse(item);
        }

        // Cache data is serialized and saved in object
        const cacheData = item?.data;
        return typeof cacheData === 'undefined' ? fallbackValue : cacheData;
    } catch (e) {
        return fallbackValue;
    }
};

/**
 * Helper to set a local storage data
 * @param {String} cacheKey
 * @param {any} data
 */
const setLocalStorageDataHelper = (cacheKey, data) => {
    const cacheData = JSON.stringify({ data });
    localStorage?.setItem(cacheKey, cacheData);
};

/**
 * Remove an item from the local storage cache.
 * @param {String} cacheKey
 */
const removeLocalStorageDataHelper = (cacheKey) => {
    localStorage?.removeItem(cacheKey);
};

/**
 * Local storage Cache Hook
 * @param {string} key The key to use for the cache instance.
 * @param {any} defaultValue Default value to use if cache is not available.
 * Default: null
 */
const useLocalStorage = (key, defaultValue = null) => {
    const userData = useUser(),
        userEmail = userData?.email || '',
        cacheKey = getCacheKey(key, userEmail),
        [value, setValue] = useState(() => getLocalStorageDataHelper(cacheKey, defaultValue));

    useEffect(() => {
        // Remove the local storage cache item if value is undefined
        if (value === undefined) {
            removeLocalStorageDataHelper(cacheKey);
            return;
        }
        setLocalStorageDataHelper(cacheKey, value);
    }, [key, value, cacheKey]);

    return {
        /**
         * State structure to get and set the cache item.
         * This is equivalent to the return value of useState().
         * @value {Array<any, function>}
         */
        state: [value, setValue],

        /**
         * Get a cache item
         *
         * @param {any} fallbackValue Default value to use if cache
         * is not available.
         * @see getLocalStorageDataHelper()
         *
         * @return {null|any} Returns cache data on success.
         * {fallbackValue} is returned on failure.
         */
        get: (fallbackValue = null) => {
            return value === undefined ? fallbackValue : value;
        },

        /**
         * Set a cache item
         * @param {any} data Cache data
         * @param {Boolean} requireAuth Whether user must be logged in before
         * cache is set
         * @see setLocalStorageDataHelper()
         */
        set: (data, requireAuth = false) => {
            if (requireAuth && !userEmail) return;
            setValue(data);
        },

        /**
         * Remove a cache item
         * @see removeLocalStorageDataHelper()
         * @return void
         */
        remove: () => {
            setValue(undefined);
        },
    };
};

/**
 *
 * @param {Object} store Redux store instance
 * @returns {String} User email on success. Otherwise empty string.
 */
const getUserEmail = () => store?.getState()?.userdata?.data?.email || '';

/**
 * Get a local storage cache item
 * @param {String} cacheKey
 * @param {any} defaultValue
 * @see getLocalStorageDataHelper()
 * @returns {any}
 */
export const getLocalStorageCacheItem = (key, defaultValue = null) => {
    const userEmail = getUserEmail(),
        cacheKey = getCacheKey(key, userEmail);

    return getLocalStorageDataHelper(cacheKey, defaultValue);
};

/**
 * Set a local storage cache item
 * @param {String} key Cache key
 * @param {any} data Cache data
 * @param {Boolean} requireAuth Whether user must be logged in before
 * cache is set
 * @see setLocalStorageDataHelper()
 */
export const setLocalStorageCacheItem = (key, data, requireAuth = false) => {
    const userEmail = getUserEmail(),
        cacheKey = getCacheKey(key, userEmail);

    if (requireAuth && !userEmail) return;

    setLocalStorageDataHelper(cacheKey, data);
};

/**
 * Remove a cache item
 * @see removeLocalStorageDataHelper()
 */
export const removeLocalStorageCacheItem = (key) => {
    const userEmail = getUserEmail(),
        cacheKey = getCacheKey(key, userEmail);

    removeLocalStorageDataHelper(cacheKey);
};

/**
 * Destroy the local storage cache
 * @param {Array<string>} keys List of cache keys to preserve
 */
export const destroyLocalStorageCache = (keys = null) => {
    const userEmail = getUserEmail();
    localStorage.removeItem('currentOrganizationId');
    for (const findKey of Object.keys(localStorage)) {
        const extractKeyNamespace = findKey.substring(0, cacheKeyNamespace.length);

        // Check if the cache item is preservable
        const canPreserveCacheItem = keys && keys?.some((value) => findKey.indexOf(value) !== -1);
        if (canPreserveCacheItem) continue;

        if (cacheKeyNamespace === extractKeyNamespace) {
            const _cacheKey = getCacheKey(findKey, userEmail || '');
            localStorage?.removeItem(_cacheKey);
        }
    }
};

export default useLocalStorage;
