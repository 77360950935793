import { useEffect } from 'react';

import { setAllDataTypes } from './useMyDataTypesHook';
import { useStoreValue, setStoreState, getStoreState } from './useStoreStateHook';

export const storeNamespace = 'appSchemas';

/**
 * Default states
 */
export const schemaStoreStates = {
    schemas: [],
};

/**
 * Get the schemas and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useSchemasValue = () => useStoreValue('schemas', storeNamespace)([]);

/**
 * Set the schemas.
 * @param {Array<Object>}
 */
export const setSchemas = (schemas) => setStoreState('schemas')(schemas);

/**
 * Set the schemas.
 * @param {Array<Object>}
 */
export const getSchemas = () => getStoreState('schemas', storeNamespace)();

/**
 * Use data type objects and subscribe to changes
 *
 * @return {Array<object>}
 */
export const useDataTypesObject = () => {
    const schemas = useSchemasValue();

    useEffect(() => {
        const allDataTypes = {};
        schemas.forEach((dataType) => (allDataTypes[dataType._id] = dataType));
        setAllDataTypes(allDataTypes);
    }, [schemas]);
};
