/* eslint default-param-last: 0 */
const INIT_STATE = {
    loading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
