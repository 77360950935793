import { authorizedAxiosInstance } from '../axiosInstances';
import HttpStatusCodes from '../classes/HttpStatusCodes';
import { getGatewayUrl } from './envUtils';
import { showSnackBarErrorNotification } from './snackBarNotificationUtil';

/**
 * Process the request response object
 * @param {Object} response
 * @param {Function|null} callback Success callback
 * @returns {any}
 */
const processRequestResponse = (response, callback = null) => {
    if (response?.status === HttpStatusCodes.SUCCESS) {
        if (callback) {
            callback(response.data);
        }

        return response.data;
    } else {
        throw response.errors;
    }
};

/**
 *
 * @param {String} applicationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getApplications = async () => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/applications`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} applicationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getApplicationById = async (applicationId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/application/${applicationId}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSubscriptionById = async (organizationId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/organization/${organizationId}/subscriptions`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} userEmail
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLegacyFormSubscriptionByUserEmail = async (userEmail) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/platform/checkLegacyFormsPlan/${userEmail}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const removeLegacySubscriptionById = async (userEmail) => {
    return authorizedAxiosInstance
        .patch(getGatewayUrl(`/platform/removeLegacyFormsPlan/${userEmail}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const insertEnterpriseSubscriptionById = async (userEmail) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/platform/insertEnterprisePlan/${userEmail}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const insertSubscriptionById = async (organizationId, data) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/subscriptions`), data)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @param {String} sessionId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStripePaymentDetailById = async (organizationId, sessionId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/organization/${organizationId}/subscriptions/${sessionId}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSubscriptionById = async (organizationId, subscriptionId, data) => {
    return authorizedAxiosInstance
        .patch(getGatewayUrl(`/organization/${organizationId}/subscriptions/${subscriptionId}`), data)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Get application access by subscription.
 * Note: callbacks are only fired on success.
 * @param {{ organizationId: string, userEmail: string, callbacks: {apps: (apps: []) => {}, subscriptions: (subscriptions: []) => {}, legacySubscription: (data: {payload: Array<object>}) => {}} }} data
 * @return {{ hasFormsAccess: true|false, hasConnectAccess: true|false, }}
 */
export const getApplicationAccessByOrganizationSubscription = async ({ organizationId, userEmail, callbacks = {} }) => {
    let applicationResponse,
        subscriptionResponse,
        hasLegacyFormAccess = null;

    /**
     * Get form access with legacy subscription if error occurs
     * @return {{hasConnectAccess: false, hasFormsAccess: true|false}}
     */
    const getFormAccessByLegacySubscription = async () => {
        // Prevent this request from running again after the first successful call
        if (hasLegacyFormAccess !== null) {
            return {
                hasFormsAccess: hasLegacyFormAccess,
                hasConnectAccess: false,
            };
        }

        try {
            const formAccess = await getLegacyFormSubscriptionByUserEmail(userEmail);
            hasLegacyFormAccess = formAccess?.payload ? true : false;

            callbacks && callbacks?.legacySubscription && callbacks.legacySubscription(formAccess);

            return {
                hasFormsAccess: hasLegacyFormAccess,
                hasConnectAccess: false,
            };
        } catch {
            return {
                hasFormsAccess: false,
                hasConnectAccess: false,
            };
        }
    };

    try {
        applicationResponse = await getApplications();
        callbacks && callbacks?.apps && callbacks.apps(applicationResponse);
    } catch (e) {
        showSnackBarErrorNotification(e?.response?.data?.message || e?.message);
    }

    try {
        subscriptionResponse = await getSubscriptionById(organizationId);
        callbacks && callbacks?.subscriptions && callbacks.subscriptions(subscriptionResponse);
    } catch (e) {
        showSnackBarErrorNotification(e?.response?.data?.message || e?.message);
    }

    if (
        !applicationResponse ||
        !applicationResponse?.length ||
        !subscriptionResponse ||
        subscriptionResponse?.length < 1
    ) {
        return getFormAccessByLegacySubscription();
    }

    let connectAccess = false,
        formsAccess = false;

    const formAppId = applicationResponse[4]?._id,
        connectAppId = applicationResponse[3]?._id;

    for (const x in subscriptionResponse) {
        const subscriptionAppId = subscriptionResponse[x]?.applicationId;

        if (subscriptionAppId === formAppId) {
            formsAccess = true;
            continue;
        }
        if (subscriptionAppId === connectAppId) {
            connectAccess = true;
            continue;
        }

        if (formsAccess && connectAccess) break;
    }

    return {
        hasFormsAccess: formsAccess,
        hasConnectAccess: connectAccess,
    };
};
