/* eslint default-param-last: 0 */
const INIT_STATE = {
    snackbar: false,
    type: 'success',
    message: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'UPDATE_SNACK':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
