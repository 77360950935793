import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: #fff;
`;

export const ErrorImage = styled.img`
    margin-top: 15vh;
`;

export const HeaderText = styled.p`
    font-weight: bolder;
    font-size: xx-large;
    letter-spacing: 1.5px;
    padding: 1vh 0 0.5vh 0;
    margin: 0;
`;

export const SubText = styled.p`
    letter-spacing: -0.3px;
    padding-bottom: 1vh;
    margin: 0;
`;

export const Button = styled.button`
    font-weight: bold;
    color: #fff;
    background-color: #3a70d4;
    border: solid;
    border-radius: 6px;
    padding: 8px 20px 8px 20px;
    margin: 1.2vh;
    cursor: pointer;
`;

export const BannerFooter = styled.img`
    width: 100%;
    margin-top: auto;
`;

export const BannerFooterGradient = styled.img`
    width: 100%;
`;
