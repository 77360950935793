import { useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const WatchUnsavedDataContext = createContext({});

const WatchUnsavedDataProvider = ({ children }) => {
    /**
     * Handle unsaved data confirmation
     * @param {Object} event
     * @returns {String}
     */
    const triggerUnsavedDataChangesConfirmationHandler = (event, confirmationText = '') => {
        event.preventDefault();
        return (event.returnValue =
            confirmationText || 'You have unsaved data changes, are you sure you want to leave this page?');
    };

    /**
     * Set the unsaved data confirmation
     * @param {String} confirmationText
     */
    const setUnsavedDataState = (confirmationText = '') => {
        if (!window.onbeforeunload) {
            window.onbeforeunload = async (event) =>
                triggerUnsavedDataChangesConfirmationHandler(event, confirmationText);
        }
    };

    /**
     * Clear the unsaved data confirmation
     */
    const clearUnsavedDataState = () => {
        window.onbeforeunload = undefined;
    };

    /**
     * Check whether there's unsaved data
     * @returns {Bool} True if unsaved data changes is present. Otherwise false.
     */
    const hasUnsavedData = () => typeof window.onbeforeunload === 'function';

    const providerProps = {
        hasUnsavedData,
        setUnsavedDataState,
        clearUnsavedDataState,
    };

    return <WatchUnsavedDataContext.Provider value={providerProps}>{children}</WatchUnsavedDataContext.Provider>;
};

/**
 * Subscribe to the watch unsaved data context.
 * Note: this will not cause re-rendering since we're using ref
 * @returns {Object}
 */
export const useWatchUnsavedDataContext = () => useContext(WatchUnsavedDataContext);

/**
 * Subscribe to the unsaved data state change
 * @returns {Callback<boolean>}
 */
export const useHasUnsavedData = () => {
    const { hasUnsavedData } = useWatchUnsavedDataContext();
    return hasUnsavedData;
};

/**
 * Clear the unsaved data state
 * @returns {Callback<boolean>}
 */
export const useClearUnsavedDataState = () => {
    const { clearUnsavedDataState } = useWatchUnsavedDataContext();
    return clearUnsavedDataState;
};

/**
 * Set the unsaved data state
 * @param {String} componentTreeConfirmationText
 * @returns {Callback}
 */
export const useSetUnsavedDataState = (componentTreeConfirmationText = '') => {
    const { setUnsavedDataState } = useWatchUnsavedDataContext();
    return (confirmationText = '') => {
        setUnsavedDataState(confirmationText || componentTreeConfirmationText);
    };
};

WatchUnsavedDataProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default WatchUnsavedDataProvider;
