import TextField from '../TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { memo } from 'react';
import SelectField from '../SelectField';

/**
 *
 * @param {FormKeyValuePair[]} currentListOfKeyValuePairs
 * @param {string} updateType
 * @param {string|FormKeyValueDefinition} value
 * @param {number} index
 * @returns {FormKeyValuePair[]}
 */
export const handleKeyValuePairUpdate = (currentListOfKeyValuePairs, updateType, value, index) => {
    const activeKeyValueList = [...currentListOfKeyValuePairs];
    const currentKeyValuePair = activeKeyValueList?.[index] || { key: '', value: { rawData: '' } };

    switch (updateType) {
        case 'key':
            currentKeyValuePair[updateType] = value;
            break;
        case 'value':
            if (typeof value === 'string') {
                currentKeyValuePair[updateType] = { rawData: value };
            } else {
                currentKeyValuePair[updateType] = value;
            }
            break;
    }

    activeKeyValueList[index] = currentKeyValuePair;

    return activeKeyValueList;
};

/**
 *
 * @param {KeyValuePairFieldSet[]} currentListOfKeyValuePairs
 * @param {number} removeIndex
 */
export const handleKeyValuePairRemove = (currentListOfKeyValuePairs, removeIndex) => {
    const outputArray = [];
    for (let currentIndex = 0; currentIndex < currentListOfKeyValuePairs.length; currentIndex++) {
        if (currentIndex !== removeIndex) {
            outputArray.push(currentListOfKeyValuePairs[currentIndex]);
        }
    }

    return outputArray;
};

/**
 * @param {FormKeyValueDefinition} value
 * @returns {boolean}
 */
const isValidValueForPair = (value) => {
    if (typeof value === 'object') {
        return (
            (value?.rawData && value.rawData !== '') ||
            (value?.sourceStepId &&
                value?.sourceDataTypeId &&
                value?.sourceDisplayLabel &&
                Array.isArray(value?.sourceInputPath))
        );
    }

    return false;
};

/**
 * Checks an array of key value pairs for
 * @param {FormKeyValuePair[]} listOfKeyValuePairs
 * @param {boolean} requireAtLeastOneEntry
 * @returns {boolean}
 */
export const isValidKeyValuePairList = (listOfKeyValuePairs, requireAtLeastOneEntry = false) => {
    let hasInvalidPair = false;
    if (listOfKeyValuePairs.length > 0) {
        for (const pair of listOfKeyValuePairs) {
            if (!pair?.key || pair?.key === '' || !isValidValueForPair(pair?.value || {})) {
                hasInvalidPair = true;
            }
        }
    } else if (requireAtLeastOneEntry) {
        return false;
    }

    return !hasInvalidPair;
};

/**
 * @typedef FormKeyValueDefinition
 * @property {string?} rawData
 * @property {number?} sourceStepId
 * @property {string?} sourceDataTypeId
 * @property {string[]?} sourceInputPath
 * @property {string?} sourceDisplayLabel
 * @property {Object[]?} sourceInputFilters
 * @property {number?} sourceInputEntryOffset
 */

/**
 * @typedef FormKeyValuePair
 * @property {string} key
 * @property {FormKeyValueDefinition} value
 */

/**
 * @typedef FormKeyValueValidKey
 * @property {string} label
 * @property {string} value
 */

/**
 * @callback HandleAdditionalFieldProps
 * @param {string} type
 * @param {number} index
 * @param {FormKeyValuePair} currentKeyValue
 * @returns {void}
 */

/**
 * @callback HandleRemoveIndex
 * @param {number} index
 * @returns {void}
 */

/**
 *
 * @param {IntegrationBuilderProps} integrationBuilderProps
 * @param {FormKeyValuePair[]} listOfKeyValuePairs
 * @param {HandleAdditionalFieldProps} handleAdditionalFieldProps
 * @param {HandleRemoveIndex} handleRemoveIndex
 * @param {boolean} isSubmittingForm
 * @param {FormKeyValueValidKey[]} listOfValidKeys
 * @param {boolean} excludeUsedKeys
 */
const KeyValuePairFieldSet = ({
    integrationBuilderProps,
    listOfKeyValuePairs,
    handleAdditionalFieldProps,
    handleRemoveIndex,
    isSubmittingForm,
    listOfValidKeys,
    excludeUsedKeys = true,
}) => {
    const { isUserAdmin } = integrationBuilderProps;
    const isUsingSelect = Array.isArray(listOfValidKeys) && listOfValidKeys.length > 0;
    const selectedKeys = listOfKeyValuePairs.map((item) => item.key);

    const fieldsToRender = [...listOfKeyValuePairs, { key: '', value: {} }].map((keyValue, index, sourceArray) => {
        const valueDisplayText = keyValue.value?.sourceDisplayLabel || keyValue.value?.rawData || '';
        const isLastEntry = index + 1 === sourceArray.length;
        const isDisabled = !isUserAdmin;
        const availableKeys = [];
        let hasError = false;
        if (!isLastEntry && sourceArray.length > 1) {
            if (!keyValue.value?.sourceDataTypeId && !keyValue.value?.rawData) {
                hasError = true;
            }
        }

        if (isUsingSelect) {
            for (const availableKey of listOfValidKeys) {
                let isValid = true;

                for (const usedKey of selectedKeys) {
                    if (availableKey.value === usedKey && keyValue.key !== usedKey) {
                        isValid = false;
                    }
                }

                if (isValid || !excludeUsedKeys) {
                    availableKeys.push(availableKey);
                }
            }
        }

        /**
         * @returns {JSX.Element}
         */
        const getPairKeySelectBox = () => {
            return (
                <SelectField
                    name={`step-parameter-${index}`}
                    label={null}
                    inputDataList={availableKeys}
                    getItemLabel={(item) => item?.label}
                    getItemValue={(item) => item?.value}
                    renderWithGrid={false}
                    value={keyValue?.key || ''}
                    showError={false}
                    error={hasError}
                    sx={{
                        width: '175px',
                        marginTop: isLastEntry ? '4px' : '0',
                    }}
                    inputProps={handleAdditionalFieldProps('key', index, keyValue)}
                />
            );
        };

        /**
         * @returns {JSX.Element}
         */
        const getPairKeyTextField = () => {
            return (
                <TextField
                    placeholder="Key"
                    label={null}
                    required={index + 1 !== sourceArray.length}
                    disabled={isDisabled}
                    value={keyValue.key}
                    onChange={() => {}}
                    isSubmittingForm={isSubmittingForm}
                    renderWithGrid={false}
                    error={hasError}
                    showError={false}
                    sx={{ margin: '0' }}
                    inputProps={handleAdditionalFieldProps('key', index, keyValue)}
                />
            );
        };

        return (
            <div
                key={index}
                style={{ display: 'flex', justifyItems: 'stretch', alignItems: 'center', padding: '5px 0' }}
            >
                <div style={{ margin: 'auto 5px auto auto' }}>
                    {isUsingSelect ? getPairKeySelectBox() : getPairKeyTextField()}
                </div>
                <div style={{ margin: 'auto 5px auto auto' }}>
                    <TextField
                        placeholder="Value"
                        label={null}
                        required={index + 1 !== sourceArray.length}
                        disabled={isDisabled}
                        value={valueDisplayText}
                        onChange={() => {}}
                        isSubmittingForm={isSubmittingForm}
                        renderWithGrid={false}
                        error={hasError}
                        showError={false}
                        sx={{ margin: '0' }}
                        inputProps={handleAdditionalFieldProps('value', index, keyValue)}
                    />
                </div>
                <div>
                    <Button
                        style={{
                            height: '3em',
                            background: '#3a70d4',
                            borderRadius: '10px',
                            width: '50px',
                            border: '1px solid #3a70d4',
                            visibility: isLastEntry ? 'hidden' : 'visible',
                        }}
                        onClick={() => {
                            handleRemoveIndex(index);
                        }}
                    >
                        <Typography style={{ fontSize: '2em', cursor: 'pointer', color: 'white' }}>-</Typography>
                    </Button>
                </div>
            </div>
        );
    });

    return <div>{fieldsToRender}</div>;
};

KeyValuePairFieldSet.propTypes = {
    integrationBuilderProps: PropTypes.object.isRequired,
    listOfKeyValuePairs: PropTypes.array.isRequired,
    handleRemoveIndex: PropTypes.func.isRequired,
    handleAdditionalFieldProps: PropTypes.func.isRequired,
    isSubmittingForm: PropTypes.bool.isRequired,
    listOfValidKeys: PropTypes.array,
    excludeUsedKeys: PropTypes.bool,
};

export default memo(KeyValuePairFieldSet);
