export const uploadfilestyle = {
    uploaddileselecttxt: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.813rem',
        textTransform: 'capitalize',
        color: '#FFFFFF',
    },
    uploadheadtxt: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.813rem',
        // textTransform: 'capitalize',
        color: '#fff',
    },
    uploadlink: {
        textDecoration: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.813rem',
        textTransform: 'capitalize',
        color: '#3A70D4',
        cursor: 'pointer',
    },
    uploadcontentbtnContainer: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        width: '100%',
        '& p': { cursor: 'pointer', width: 48 },
        '& .MuiButtonBase-root': {
            margin: '1rem 0',

            backgroundColor: '#3A70D4',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.875rem',
            minWidth: 116,
            minHeight: 35,
            textTransform: 'capitalize',
        },
    },
    uploadfilesectionBtn: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.875rem',
        textTransform: 'none',
        color: '#fff',
        width: 116,
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#bdbdbd',
            color: '#fff',
        },
    },
    uploadpreviewtxt: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#fff',
    },
    uploadsectionmainContainer: {
        overflow: 'hidden',
        marginTop: '1rem',
        padding: '0 10px',
        '&::-webkit-scrollbar': {
            width: 1,
        },
        '& hr': {
            margin: '1.5rem 0',
            height: '1px',
            background: '#828282',
        },
    },
    uploadpreviewboxcontainer: {
        paddingBottom: '2rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& div': {
            minHeight: 97,
            minWidth: 177,
            background: '#fff',
            border: '1px solid #828282',
            borderRadius: '5px',
            '& .MuiTypography-root:first-child': {
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '0.813rem',
                color: '#4F4F4F',
                padding: '10px 13px 0px 19px;',
                textAlign: 'center',
            },
            '& h2': {
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '2.5rem',
                color: '#000000',
                textAlign: 'center',
            },
        },
    },
    uploadprogresscontainer: {
        '& div:first-child': {
            display: 'flex',
            alignItems: 'center',
        },
        '& div:last-child': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    uploadbrowssectionBtn: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '0.875rem',
        textTransform: 'none',
        // color: '#fff',
        background: '#fff',
        color: '#3A70D4',
        border: '1px solid #3A70D4',
        marginTop: 5,
        '&.MuiButton-root:hover': {
            background: '#fff',
            color: '#3A70D4',
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#bdbdbd',
            color: '#fff',
        },
    },
    uploadprogressdiv: {
        // '& .MuiGrid-container': {
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: '#828282',
            width: '100%',
            borderRadius: '5px',
            marginTop: '15px',
        },
        '& .MuiLinearProgress-barColorPrimary': { backgroundColor: '#85C446' },
        // },
    },
    uploadfileSelect: {
        '& .select__input': { color: '#fff!important' },
        '& .select__control': {
            backgroundColor: '#484747!important',
        },
        '& .select__menu': {
            marginTop: 0,
            backgroundColor: '#484747!important',
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
        },
        '& .select__indicator-separator': {
            display: 'none!important',
        },
        '& svg': {
            color: '#fff!important',
        },
        '& .select__dropdown-indicator:last-child': {
            paddingRight: 14,
        },
        '& .select__dropdown-indicator': {
            padding: '13px 7px!important',
        },
        '& .css-1gtu0rj-indicatorContainer': {
            padding: '13px 14px!important',
        },
        '& .css-1pahdxg-control': {
            backgroundColor: '#484747!important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .select__placeholder': {
            color: '#8B9197!important',
        },
        '& .select__menu-list': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#484747',
            color: '#fff',
            fontSize: '0.815rem',
            fontWeight: 400,
            borderRadius: '0 0 5px 5px',
            '& div:hover': {
                backgroundColor: '#3a70d4',
                cursor: 'pointer',
            },
            '& div': {
                borderBottom: '1px solid #828282',
            },
            '& div:last-child': {
                borderBottom: 0,
            },
            '& .select__option': {
                backgroundColor: 'transparent',
            },
        },
        '& .css-2b097c-container': {
            fontSize: '0.813rem',
            fontWeight: 400,
            backgroundColor: '#4f4f4f',
            '& .select__control': {
                borderColor: '#828282!important',
                background: '#4f4f4f',
                '&:hover': {
                    borderColor: '#3a70d4!important',
                },
            },
            '& .css-1uccc91-singleValue': {
                color: '#fff',
            },
        },
    },
};
