import '../../../App.css';
import { useEffect } from 'react';

import useStyles from '../../../styling/jss';
import LoadingScreenContainer from '../LoadingScreenContainer';
import AppRoutesContainer from '../AppRoutesContainer';

import useUser from '../../../hooks/useUserHook';
import { useSessionRefreshBasedOnUserActivity } from '../../../hooks/useSessionRefreshBasedOnUserActivityHook';
import { validateSessionTimeout, setupSessionTimeoutValidation } from '../../../utils/loginUtils';
import { useDataTypesObject } from '../../../hooks/useSchemasHook';
import { autoRefreshAppDataGlobally } from '../../../hooks/usePrepareAppDataGloballyHook';

const AppContainer = () => {
    const classes = useStyles()(),
        userData = useUser(),
        isUserLoggedIn = Object.keys(userData)?.length > 0;

    useDataTypesObject();
    useSessionRefreshBasedOnUserActivity(isUserLoggedIn);

    useEffect(() => {
        setupSessionTimeoutValidation();
        validateSessionTimeout();
        autoRefreshAppDataGlobally();
    }, []);

    const renderAppContainer = !isUserLoggedIn ? <LoadingScreenContainer /> : <AppRoutesContainer />;

    return <div className={classes.Approot}>{renderAppContainer}</div>;
};

export default AppContainer;
