import PropTypes from 'prop-types';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { memo } from 'react';

const IntegrationBuilderSettingsAccordion = ({ titleContent, children, style }) => {
    const accordionStyle = {
            borderBottomLeftRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
            boxShadow: 'none !important',
            minHeight: '0',
            height: 'auto',
            margin: '0px',
            padding: '0 !important',
            width: '100%',
            position: 'relative',
            '& .MuiAccordionSummary-content': { margin: '10px 0 !important' },
            '& .MuiAccordionSummary-root': { minHeight: '0 !important' },
        },
        accordionSummaryStyle = {
            display: 'flex',
            alignItems: 'center',
            margin: '0',
            color: '#FFFFFF !important',
            fontSize: '0.813rem !important',
            background: '#3E3E3E',
            paddingLeft: '10px',
            cursor: 'pointer',
        },
        accordionDetailStyle = {
            padding: '10px',
            margin: '0',
            fontSize: '14px',
            transitionDuration: '300ms',
            border: '1px solid #c7c7c7',
        };

    return (
        <Accordion sx={{ ...accordionStyle, ...(style || {}) }} defaultExpanded={true}>
            <AccordionSummary sx={accordionSummaryStyle} expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}>
                {titleContent}
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailStyle}>{children}</AccordionDetails>
        </Accordion>
    );
};

IntegrationBuilderSettingsAccordion.propTypes = {
    titleContent: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
};

export default memo(IntegrationBuilderSettingsAccordion);
