import { getSelectedMappingId } from './rosettaUtils';

import {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
    handleErrorResponse,
    processResponse as processRequestResponse,
} from './dataRequestUtils';

/**
 * Process request response
 * @param {Object} args
 * @return {Object} Response success/error callbacks
 */
const processResponse = ({ errorCallback, errorMessage, ...props }) => {
    return processRequestResponse({
        ...props,
        errorCallback: (error) =>
            handleErrorResponse({
                error,
                errorMessage: error.response.status === 409 ? error.response.data.error : errorMessage,
                errorCallback,
            }),
    });
};

/**
 * Get all mappings
 * @param {Object} args
 * @return {Promise<DatabaseQueryResults>} response
 */
export const getAllMappings = ({ successCallback, errorCallback, finallyCallback } = {}) => {
    return sendGetRequest({
        url: 'rosetta/mappings',
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'No mappings were found for this organization',
        }),
    });
};

/**
 * Get all mappings
 * @param {Object} args
 * @return {Promise<DatabaseQueryResults>} response
 */
export const getMappingsForPage = ({
    successCallback,
    errorCallback,
    finallyCallback,
    pageNo = 1,
    sorts = { name: 1 },
    searchText = '',
} = {}) => {
    return sendGetRequest({
        url: 'rosetta/mappings',
        data: { pageNo, sorts: JSON.stringify(sorts), searchText },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'No mappings were found for this organization',
        }),
    });
};

/**
 * Get mapping by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getMappingById = ({ mappingId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `rosetta/mapping/${mappingId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected mapping from server',
        }),
    });

/**
 * Get all data types
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAllDataTypes = ({
    successCallback,
    errorCallback,
    finallyCallback,
    pageNo = 1,
    paginationStatus = true,
} = {}) =>
    sendGetRequest({
        url: 'rosetta/dataTypes',
        data: { pageNo, paginationStatus },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch data types from server',
        }),
    });

/**
 * Create mapping data
 * @param {Object} args
 * @param {Object} args.data New mapping data
 * @return {Promise<any>} Promise resolved
 */
export const createMapping = ({ data, successCallback, errorCallback, finallyCallback }) =>
    sendPostRequest({
        data,
        url: 'rosetta/mapping',
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to create mapping.',
            successMessage: 'Mapping created successfully.',
        }),
    });

/**
 * Update mapping data
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID to update
 * @param {Object} args.data Mapping data to update
 * @return {Promise<any>} Promise resolved
 */
export const updateMappingData = ({ mappingId, data, successCallback, errorCallback, finallyCallback }) =>
    sendPatchRequest({
        data,
        url: `rosetta/mapping/${mappingId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update mapping data.',
            successMessage: 'Mapping data updated successfully.',
        }),
    });

/**
 * Delete mapping
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID whose data should be deleted.
 * @return {Promise<any>} Promise resolved
 */
export const deleteMapping = ({ mappingId, successCallback, errorCallback, finallyCallback }) =>
    sendDeleteRequest({
        url: `rosetta/mapping/${mappingId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete mapping data.',
            successMessage: 'Mapping data deleted successfully.',
        }),
    });

/**
 * Fallback to the selected mapping ID for the given request
 * if none is specified.
 * @param {String} mappingId
 * @return {String} Mapping ID to use for the given request.
 */
const getSelectedMappingIdForRequest = (mappingId = '') => mappingId || getSelectedMappingId();

/**
 * Add mapping input/output type
 * @param {Object} args
 * @param {Object} args.data mapping input/output data
 * @param {String} args.mappingId Mapping ID to add the input/output to
 * @param {String} args.dataType  'input' | 'output'
 * @return {Promise<any>} Promise resolved
 */
export const addDataTypeToMapping = ({
    data,
    successCallback,
    errorCallback,
    finallyCallback,
    mappingId = '',
    dataType = 'input',
}) =>
    sendPostRequest({
        data,
        url: `rosetta/mapping/${getSelectedMappingIdForRequest(mappingId)}/${dataType}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add input to mapping.',
            successMessage: 'Input added successfully.',
        }),
    });

/**
 * Update mapping input/output data
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID the input is connected to
 * @param {String} args.typeId Input/output ID to update
 * @param {Object} args.data Mapping data to update
 * @return {Promise<any>} Promise resolved
 */
export const updateMappingDataType = ({
    typeId,
    data,
    successCallback,
    errorCallback,
    finallyCallback,
    mappingId = '',
    dataType = 'input',
}) =>
    sendPatchRequest({
        data,
        url: `rosetta/mapping/${getSelectedMappingIdForRequest(mappingId)}/${dataType}/${typeId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: `Unable to update mapping ${dataType} type data.`,
            successMessage: `Mapping ${dataType} type data updated successfully.`,
        }),
    });

/**
 * Delete mapping input/output data type
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID the input is connected to
 * @param {String} args.typeId Input/output ID to delete
 * @return {Promise<any>} Promise resolved
 */
export const deleteMappingDataType = ({
    typeId,
    successCallback,
    errorCallback,
    finallyCallback,
    mappingId = '',
    dataType = 'input',
}) =>
    sendDeleteRequest({
        url: `rosetta/mapping/${getSelectedMappingIdForRequest(mappingId)}/${dataType}/${typeId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: `Unable to delete mapping ${dataType} type data.`,
            successMessage: `Mapping ${dataType} type data deleted successfully.`,
        }),
    });

/**
 * Get all function types
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getAllFunctionTypes = ({ successCallback, errorCallback, finallyCallback, pageNo = null } = {}) =>
    sendGetRequest({
        url: `/rosetta/functions`,
        data: { pageNo },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch functions from server.',
        }),
    });

export const addCustomField = ({ successCallback, errorCallback, finallyCallback, data, mappingId = '' } = {}) =>
    sendPostRequest({
        url: `/rosetta/mapping/${getSelectedMappingIdForRequest(mappingId)}/customField`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add custom field to mapping.',
            successMessage: 'Custom Field created successfully.',
        }),
    });

export const updateCustomField = ({
    successCallback,
    errorCallback,
    finallyCallback,
    data,
    mappingId = '',
    customFieldId,
} = {}) =>
    sendPatchRequest({
        url: `/rosetta/mapping/${getSelectedMappingIdForRequest(mappingId)}/customField/${customFieldId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update custom field to mapping.',
            successMessage: 'Custom Field updated successfully.',
        }),
    });

export const deleteCustomField = ({
    successCallback,
    errorCallback,
    finallyCallback,
    mappingId = '',
    customFieldId,
} = {}) =>
    sendDeleteRequest({
        url: `/rosetta/mapping/${getSelectedMappingIdForRequest(mappingId)}/customField/${customFieldId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete custom field from mapping.',
            successMessage: 'Custom Field deleted successfully.',
        }),
    });

/**
 * Update input/output mappings data
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID to update
 * @param {Object} args.data Mapping data to update
 * @return {Promise<any>} Promise resolved
 */
export const updateInputOutputFieldMappings = ({
    mappingId,
    outputId,
    data,
    successCallback,
    errorCallback,
    finallyCallback,
}) =>
    sendPatchRequest({
        data,
        url: `rosetta/mapping/${mappingId}/output/${outputId}/fieldMappings`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update output field mappings.',
            successMessage: 'Updated output field mappings.',
        }),
    });

/**
 * Get specific mapping for export
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const exportMapping = ({
    mappingId,
    dependentDataTypes,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPostRequest({
        url: `/rosetta/mapping/${mappingId}/export`,
        data: { dependentDataTypes },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to export mapping',
        }),
    });

/**
 * Import Mapping to organization
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const importMapping = ({ data, successCallback, errorCallback, finallyCallback } = {}) => {
    sendPostRequest({
        url: `/rosetta/mappings/import`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully imported Mapping',
            errorMessage: 'Unable to import Mapping',
        }),
    });
};

/**
 * Copy Mapping to organization
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const copyMapping = ({ mappingId, mappingName, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: `/rosetta/mapping/${mappingId}/copy`,
        data: { name: mappingName },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully copied Mapping',
            errorMessage: 'Unable to copy Mapping',
        }),
    });

/**
 * Create Mapping Condition
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID to update
 * @param {String} args.name Name of the condition
 * @returns {Promise<any>} response
 */
export const createMappingCondition = ({ mappingId, name, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: `rosetta/mapping/${mappingId}/condition`,
        data: { name },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully created Mapping Condition',
            errorMessage: 'Unable to create Mapping Condition',
        }),
    });

/**
 * Update Mapping Condition Name
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID to update
 * @param {String} args.conditionId Condition ID to update
 * @param {String} args.name Name of the condition
 * @param {Array} args.expressionList List of expressions
 * @returns {Promise<any>} response
 */
export const updateMappingCondition = ({
    mappingId,
    conditionId,
    name,
    expressionList,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) => {
    const data = {};

    if (name) {
        data.name = name;
    }

    if (expressionList) {
        data.expressionList = expressionList;
    }

    return sendPatchRequest({
        url: `rosetta/mapping/${mappingId}/condition/${conditionId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully updated Mapping Condition',
            errorMessage: 'Unable to update Mapping Condition',
        }),
    });
};

/**
 * Delete Mapping Condition
 * @param {Object} args
 * @param {String} args.mappingId Mapping ID to update
 * @param {String} args.conditionId Condition ID to delete
 */
export const deleteMappingCondition = ({
    mappingId,
    conditionId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendDeleteRequest({
        url: `rosetta/mapping/${mappingId}/condition/${conditionId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Successfully deleted Mapping Condition',
            errorMessage: 'Unable to delete Mapping Condition',
        }),
    });
