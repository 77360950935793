import { getAllComparisons } from '../utils/comparisonsDataRequestUtils';
import { updateStatesOptimistically } from './useMyAppsHook';
import { ObjectId } from '../utils/helpers';
import { useStoreValue, getStoreState, setStoreState, setStoreStates } from './useStoreStateHook';
import { comparisonActions } from '../utils/comparisonUtils';

export const storeNamespace = 'comparisons';

/**
 * Default State
 */
export const comparisonsStates = {
    comparisons: [],
    allComparisons: { loading: true, comparisons: [] },
    selectedComparison: {},
    recentlyUpdatedComparisonId: '',
    isReady: false,
    hasSubmitted: false,
    selectedComparisonIdToScroll: '',

    // Data table states
    currentPage: 1,
    currentPageComparisons: [],
    shouldReload: false,

    comparisonModal: {
        isOpen: false,
        data: {},
    },
    infoModal: {
        isOpen: false,
        data: {},
    },
    inputModal: {
        isOpen: false,
        data: {},
    },
    comparisonFieldModal: {
        isOpen: false,
        data: {},
        type: '',
    },
    comparisonExpressionReferenceFieldModal: {
        isOpen: false,
        data: {},
    },
};

/**
 * Reset comparisons states
 * @param {Object} states
 */
export const resetComparisonsStates = (states = {}) => {
    setStoreStates(storeNamespace, {
        comparisons: [],
        selectedComparison: {},
        recentlyUpdatedComparisonId: '',

        currentPage: 1,
        currentPageComparisons: [],
        shouldReload: false,
        comparisonModal: {
            isOpen: false,
            data: {},
        },
        infoModal: {
            isOpen: false,
            data: {},
        },
        hasSubmitted: false,
        ...states,
    });
};

/****** Get Store States ******/

/**
 * Get comparison by id
 * @param {String} comparisonId
 * @returns {Object}
 */
export const getComparisonById = (comparisonId) =>
    getStoreState('comparisons')([]).find((comparison) => comparison._id === comparisonId) || {};

/**
 * Get data type id from comparison 'from'
 * @param {String} comparisonId
 * @returns {String}
 */
export const getDataTypeIdByComparisonId = (comparisonId) => {
    const comparison = getStoreState('selectedComparison')({}).comparisons?.find(
            (comparison) => comparison._id === comparisonId,
        ),
        inputId = comparison?.leftOperandInputId;

    if (!inputId) return '';

    return getStoreState('selectedComparison')({}).inputs.find((input) => input._id === inputId)?.dataTypeId ?? '';
};

/**
 * Get data type id from condition 'to'
 * @param {String} comparisonId
 * @param {String} conditionId
 * @returns {String}
 */
export const getDataTypeIdByConditionId = (comparisonId, conditionId) => {
    const comparison = getStoreState('selectedComparison')({}).comparisons?.find(
            (comparison) => comparison._id === comparisonId,
        ),
        condition = comparison?.conditionList.find((condition) => condition._id === conditionId);

    if (!condition) return '';

    const inputId = condition?.rightOperandInputId;

    if (!inputId) return '';

    return getStoreState('selectedComparison')({}).inputs.find((input) => input._id === inputId)?.dataTypeId ?? '';
};

/****** Set Store States ******/

/**
 * Set comparisons
 * @param {Array<Object>} comparisons
 */
export const setComparisons = (comparisons) => setStoreState('comparisons')(comparisons);

/**
 * Set current page number
 * @param {Number} currentPage
 */
export const setCurrentPage = (currentPage) => setStoreState('currentPage')(currentPage);

/**
 * Set Recently updated comparison id
 * @param {String} comparisonId
 */
export const setRecentlyUpdatedComparisonId = (comparisonId) =>
    setStoreState('recentlyUpdatedComparisonId')(comparisonId);

/**
 * Set current page comparisons
 * @param {Array<Object>} comparisons
 */
export const setCurrentPageComparisons = (comparisons) => setStoreState('currentPageComparisons')(comparisons);

/**
 * Set selected comparison
 * @param {Object} comparison
 */
export const setSelectedComparison = (comparison) => setStoreState('selectedComparison')(comparison);

/**
 * Set should reload
 * @param {Boolean} shouldReload
 */
export const setShouldReload = (shouldReload) => setStoreState('shouldReload')(shouldReload);

/**
 * Set is ready
 * @param {Boolean} isReady
 */
export const setIsReady = (isReady) => setStoreState('isReady')(isReady);

/**
 * Update comparisons optimalistically
 * @param {Object} comparison
 * @param {String} action - 'add' | 'update' | 'delete'
 */
export const updateComparisonsOptimalistically = (comparison, action) => {
    const comparisons = getStoreState('comparisons')([]);
    // Copies array to avoid mutating the original array in the store
    const currentPageComparisons = [...getStoreState('currentPageComparisons')([])];
    const comparisonIndex = comparisons.findIndex((item) => item._id === comparison._id);
    const currentPageComparisonIndex = currentPageComparisons.findIndex((item) => item._id === comparison._id);

    switch (action) {
        case 'add':
            comparisons.push(comparison);
            break;
        case 'update':
            if (currentPageComparisonIndex !== -1) {
                currentPageComparisons[currentPageComparisonIndex] = comparison;
            }

            if (comparisonIndex !== -1) {
                comparisons[comparisonIndex] = comparison;
            }

            setRecentlyUpdatedComparisonId(comparison._id);
            break;
        case 'delete':
            if (currentPageComparisonIndex !== -1) {
                currentPageComparisons.splice(currentPageComparisonIndex, 1);
            }

            if (comparisonIndex !== -1) {
                comparisons.splice(comparisonIndex, 1);
            }

            break;
        case 'copy':
            // Remove last comparison from current page if comparisons exceeed 10
            if (currentPageComparisons.length > 10) {
                currentPageComparisons.pop();
            }
            // Insert comparison
            currentPageComparisons.splice(0, 0, comparison);
            comparisons.push(comparison);
            setRecentlyUpdatedComparisonId(comparison._id);
            break;
        default:
            break;
    }

    updateAllComparisonsOptimistically({ comparison, actionType: action === 'add' ? 'create' : action });

    setStoreStates({
        comparisons,
        currentPageComparisons,
    });

    setTimeout(() => {
        setRecentlyUpdatedComparisonId('');
    }, 4000);
};

/**
 * Invalidate expressions from input data type change recursively
 * @param {Object} expression
 * @param {String} inputId
 */
const invalidateExpressionFieldsFromInputDataTypeChange = (expression, type) => {
    if (expression?.expressionList?.length) {
        expression.expressionList.forEach((item) => invalidateExpressionFieldsFromInputDataTypeChange(item, type));
    } else {
        if (type === 'from') {
            expression.leftOperandInputPath = [];
        } else if (type === 'to') {
            expression.rightOperandInputPath = [];
        }
    }
};

/**
 * Helper function to update selected comparison's (nested) conditions
 * @param {Object[]} selectedComparisonCurrentConditionExpressionList
 * @param {String[]} expressionIdPath
 * @param {String|Object[]} updatedItemValueForAction
 * @param {String} expressionId
 * @param {Number} referenceFieldIndex
 * @param {String} action
 * @param {Object[]} expressions
 */
const updateConditionOptimalistically = ({
    selectedCondition,
    expressionIdPath,
    updatedItemValueForAction,
    expressionId,
    referenceFieldIndex,
    action,
    expressions,
}) => {
    let currentExpressionsList = selectedCondition.expressionList;

    for (const expressionId of expressionIdPath) {
        currentExpressionsList = currentExpressionsList.find(
            (condition) => condition._id === expressionId,
        ).expressionList;
    }

    const expressionIndex = currentExpressionsList?.findIndex((item) => item._id === expressionId) ?? -1;

    switch (action) {
        case comparisonActions.ADD_NEW_GROUPING: {
            const newGrouping = {
                _id: ObjectId(),
                expressionType: 'grouping',
                expressionList: [
                    {
                        _id: ObjectId(),
                        expressionName: '',
                        expressionType: 'expression',
                        leftOperandInputPath: [],
                        comparisonOperator: '',
                        rightOperandInputPath: [],
                    },
                ],
            };
            if (!currentExpressionsList) {
                currentExpressionsList = [newGrouping];
            } else {
                currentExpressionsList.push(newGrouping);
            }
            break;
        }

        case comparisonActions.ADD_NEW_EXPRESSION: {
            const newExpression = {
                _id: ObjectId(),
                expressionName: '',
                expressionType: 'expression',
                leftOperandInputPath: [],
                comparisonOperator: '',
                rightOperandInputPath: [],
            };

            if (!currentExpressionsList) {
                currentExpressionsList = [newExpression];
            } else {
                currentExpressionsList.push(newExpression);
            }
            break;
        }

        case comparisonActions.ADD_NEW_EXPRESSION_REFERENCE_FIELD: {
            const currentExpression = currentExpressionsList[expressionIndex];

            if (currentExpression.leftOperandReferenceFields) {
                currentExpression.leftOperandReferenceFields.push(updatedItemValueForAction);
            } else {
                currentExpression.leftOperandReferenceFields = [updatedItemValueForAction];
            }
            break;
        }

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_NAME:
            currentExpressionsList[expressionIndex].expressionName = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_FROM_VALUE:
            currentExpressionsList[expressionIndex].leftOperandInputPath = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_COMPARISON_OPERATOR:
            currentExpressionsList[expressionIndex].comparisonOperator = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_TO_VALUE:
            currentExpressionsList[expressionIndex].rightOperandInputPath = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_LOGICAL_OPERATOR_PREFIX:
            currentExpressionsList[expressionIndex].logicalOperatorPrefix = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_REFERENCE_FIELD:
            if (referenceFieldIndex !== null) {
                currentExpressionsList[expressionIndex].leftOperandReferenceFields[referenceFieldIndex] =
                    updatedItemValueForAction;
            }
            break;

        case comparisonActions.DELETE_GROUPING:
        case comparisonActions.DELETE_EXPRESSION:
            currentExpressionsList.splice(expressionIndex, 1);
            break;

        case comparisonActions.DELETE_EXPRESSION_REFERENCE_FIELD:
            if (referenceFieldIndex !== null) {
                currentExpressionsList[expressionIndex].leftOperandReferenceFields.splice(referenceFieldIndex, 1);
            }
            break;

        case comparisonActions.ADD_MULTIPLE_EXPRESSIONS_TO_CONDITION:
            if (currentExpressionsList.length === 0) {
                // Remove 'join' of first element if there are no other expressions
                expressions[0].logicalOperatorPrefix = '';
            }

            currentExpressionsList.push(...expressions);
            break;

        default:
            break;
    }
};

/**
 * Update selected comparison optimistically
 * @param {Object} args
 * @param {String} args.action - 'name' | 'from' | 'entryComparisonType' | 'comparisonOperator' | 'to' | 'removeTo' | 'isCaseSensitive'
 * @param {String|Object} args.updatedItemValueForAction
 * @param {String} args.comparisonId
 * @param {String} args.conditionId
 * @param {String} args.expressionId
 * @param {String[]} args.expressionPath
 * @param {Number} args.referenceFieldIndex
 * @param {Object[]} args.expressions
 */
export const updateSelectedComparisonOptimalistically = ({
    action,
    updatedItemValueForAction = {},
    comparisonId = null,
    conditionId = null,
    expressionId = null,
    expressionPath = [],
    referenceFieldIndex = null,
    expressions = [],
}) => {
    const selectedComparison = getStoreState('selectedComparison')({}),
        comparisonIndex = selectedComparison.comparisons.findIndex((item) => item._id === comparisonId),
        inputIndex = selectedComparison.inputs.findIndex((item) => item._id === updatedItemValueForAction._id),
        isNewComparison = comparisonIndex === -1,
        updatedInput = selectedComparison.inputs[inputIndex],
        conditionIndex = conditionId
            ? selectedComparison.comparisons[comparisonIndex].conditionList.findIndex(
                  (item) => item._id === conditionId,
              )
            : -1;

    // Reset hasSubmitted state
    setHasSubmitted(false);

    switch (action) {
        /***  Adds ***/
        case comparisonActions.ADD_NEW_COMPARISON:
            selectedComparison.comparisons
                ? selectedComparison.comparisons.push({
                      _id: ObjectId(),
                      name: '',
                      conditionEvaluationMode: 'all',
                      leftOperandInputId: null,
                      conditionList: [],
                  })
                : (selectedComparison.comparisons = [
                      {
                          _id: ObjectId(),
                          name: '',
                          conditionEvaluationMode: 'all',
                          leftOperandInputId: null,
                          conditionList: [],
                      },
                  ]);
            break;

        case comparisonActions.ADD_NEW_CONDITION:
            selectedComparison.comparisons[comparisonIndex].conditionList.push({
                _id: ObjectId(),
                conditionName: '',
                rightOperandInputId: '',
                expressionList: [],
                conditionLeftOperandReferenceFields: [],
            });
            break;

        case comparisonActions.ADD_NEW_CONDITION_REFERENCE_FIELD:
            if (
                selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex]
                    .conditionLeftOperandReferenceFields
            ) {
                selectedComparison.comparisons[comparisonIndex].conditionList[
                    conditionIndex
                ].conditionLeftOperandReferenceFields.push(updatedItemValueForAction);
            } else {
                selectedComparison.comparisons[comparisonIndex].conditionList[
                    conditionIndex
                ].conditionLeftOperandReferenceFields = [updatedItemValueForAction];
            }
            break;

        case comparisonActions.UPDATE_COMPARISON_REFERENCE_FIELD:
            if (referenceFieldIndex !== null) {
                selectedComparison.comparisons[comparisonIndex].conditionList[
                    conditionIndex
                ].conditionLeftOperandReferenceFields[referenceFieldIndex] = updatedItemValueForAction;
            }
            break;

        case comparisonActions.DELETE_CONDITION_REFERENCE_FIELD:
            if (referenceFieldIndex !== null) {
                selectedComparison.comparisons[comparisonIndex].conditionList[
                    conditionIndex
                ].conditionLeftOperandReferenceFields.splice(referenceFieldIndex, 1);
            }
            break;

        case comparisonActions.ADD_NEW_INPUT:
            selectedComparison.inputs.push({
                _id: ObjectId(),
                name: updatedItemValueForAction.name,
                dataTypeId: updatedItemValueForAction.dataTypeId,
                externalId: updatedItemValueForAction.externalId,
                entryOffset: updatedItemValueForAction.entryOffset,
            });
            break;

        case comparisonActions.ADD_NEW_GROUPING:
        case comparisonActions.ADD_NEW_EXPRESSION:
        case comparisonActions.ADD_NEW_EXPRESSION_REFERENCE_FIELD:
            updateConditionOptimalistically({
                selectedCondition: selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex],
                expressionIdPath: expressionPath,
                updatedItemValueForAction,
                expressionId,
                referenceFieldIndex,
                action,
            });
            break;

        /*** Updates ***/
        case comparisonActions.UPDATE_COMPARISON_NAME:
            if (isNewComparison) {
                selectedComparison.comparisons.push({
                    _id: ObjectId(),
                    name: updatedItemValueForAction,
                    conditionEvaluationMode: 'all',
                    leftOperandInputId: null,
                    conditionList: [],
                });
            } else {
                selectedComparison.comparisons[comparisonIndex].name = updatedItemValueForAction;
            }
            break;
        case comparisonActions.UPDATE_COMPARISON_EXTERNAL_ID:
            selectedComparison.comparisons[comparisonIndex].externalId = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_LEFT_OPERAND_INPUT_ID:
            selectedComparison.comparisons[comparisonIndex].leftOperandInputId = updatedItemValueForAction;

            selectedComparison.comparisons[comparisonIndex].conditionList.forEach((condition) => {
                condition.expressionList.forEach((expression) => {
                    invalidateExpressionFieldsFromInputDataTypeChange(expression, 'from');
                });
            });

            selectedComparison.comparisons[comparisonIndex].conditionList.forEach((condition) => {
                condition.expressionList.forEach((expression) => {
                    expression.leftOperandReferenceFields = [];
                });
            });
            break;

        case comparisonActions.UPDATE_COMPARISON_CONDITION_EVALUATION_MODE:
            selectedComparison.comparisons[comparisonIndex].conditionEvaluationMode = updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_INPUT: {
            if (inputIndex !== -1) {
                const dataTypeChanged =
                    selectedComparison.inputs[inputIndex].dataTypeId !== updatedItemValueForAction.dataTypeId;

                selectedComparison.inputs[inputIndex] = {
                    ...updatedInput,
                    name: updatedItemValueForAction.name,
                    dataTypeId: updatedItemValueForAction.dataTypeId,
                    externalId: updatedItemValueForAction.externalId,
                    entryOffset: updatedItemValueForAction.entryOffset,
                };

                // Reset From and To value(s) if using the updated input and datatype changed
                if (dataTypeChanged) {
                    let resetFrom = false,
                        resetTo = false;
                    selectedComparison.comparisons.forEach((comparison) => {
                        if (comparison.leftOperandInputId === updatedItemValueForAction._id) {
                            comparison.leftOperandInputId = null;
                            resetFrom = true;
                        }

                        comparison.conditionList.forEach((condition) => {
                            if (condition.rightOperandInputId === updatedItemValueForAction._id) {
                                condition.rightOperandInputId = null;
                                resetTo = true;
                            }

                            condition.expressionList.forEach((expression) => {
                                if (resetFrom) {
                                    invalidateExpressionFieldsFromInputDataTypeChange(expression, 'from');
                                }

                                if (resetTo) {
                                    invalidateExpressionFieldsFromInputDataTypeChange(expression, 'to');
                                }
                            });
                        });
                    });
                }
            }
            break;
        }

        case comparisonActions.UPDATE_COMPARISON_CONDITION_LOGICAL_OPERATOR_PREFIX:
            selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex].logicalOperatorPrefix =
                updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_CONDITION_NAME:
            selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex].conditionName =
                updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_CONDITION_RIGHT_OPERAND_INPUT_ID:
            selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex].rightOperandInputId =
                updatedItemValueForAction;

            selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex].expressionList.forEach(
                (expression) => {
                    invalidateExpressionFieldsFromInputDataTypeChange(expression, 'to');
                },
            );
            break;

        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_NAME:
        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_FROM_VALUE:
        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_COMPARISON_OPERATOR:
        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_TO_VALUE:
        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_LOGICAL_OPERATOR_PREFIX:
        case comparisonActions.UPDATE_COMPARISON_EXPRESSION_REFERENCE_FIELD:
            updateConditionOptimalistically({
                selectedCondition: selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex],
                expressionIdPath: expressionPath,
                updatedItemValueForAction,
                expressionId,
                referenceFieldIndex,
                action,
            });
            break;

        case comparisonActions.UPDATE_COMPARISON_DISPLAY_UNMATCHED_RESULT_VALUES_FOR_SUBSEQUENT_CONDITIONS:
            selectedComparison.comparisons[comparisonIndex].displayUnmatchedResultValuesForSubsequentConditions =
                updatedItemValueForAction;
            break;

        case comparisonActions.UPDATE_COMPARISON_CONDITION_DISPLAY_UNMATCHED_RESULT_VALUES_BASED_ON_MASTER:
            selectedComparison.comparisons[comparisonIndex].conditionList[
                conditionIndex
            ].displayUnmatchedResultValuesBasedOnMaster = updatedItemValueForAction;
            break;

        /*** Deletes ***/
        case comparisonActions.DELETE_COMPARISON:
            selectedComparison.comparisons.splice(comparisonIndex, 1);
            break;

        case comparisonActions.DELETE_INPUT:
            if (inputIndex !== -1) {
                selectedComparison.inputs.splice(inputIndex, 1);

                // Reset From and To value(s) if using the updated input
                let resetFrom = false,
                    resetTo = false;

                selectedComparison.comparisons.forEach((comparison) => {
                    if (comparison.leftOperandInputId === updatedItemValueForAction._id) {
                        comparison.leftOperandInputId = null;
                        resetFrom = true;
                    }

                    comparison.conditionList.forEach((condition) => {
                        if (condition.rightOperandInputId === updatedItemValueForAction._id) {
                            condition.rightOperandInputId = null;
                            resetTo = true;
                        }

                        condition.expressionList.forEach((expression) => {
                            if (resetFrom) {
                                invalidateExpressionFieldsFromInputDataTypeChange(expression, 'from');
                            }

                            if (resetTo) {
                                invalidateExpressionFieldsFromInputDataTypeChange(expression, 'to');
                            }
                        });
                    });
                });
            }

            break;

        case comparisonActions.DELETE_CONDITION:
            selectedComparison.comparisons[comparisonIndex].conditionList.splice(conditionIndex, 1);
            break;

        case comparisonActions.DELETE_GROUPING:
        case comparisonActions.DELETE_EXPRESSION:
            updateConditionOptimalistically({
                selectedCondition: selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex],
                expressionIdPath: expressionPath,
                expressionId: expressionId,
                action: action,
            });
            break;

        case comparisonActions.DELETE_EXPRESSION_REFERENCE_FIELD:
            updateConditionOptimalistically({
                selectedCondition: selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex],
                expressionIdPath: expressionPath,
                expressionId: expressionId,
                referenceFieldIndex: referenceFieldIndex,
                action: action,
            });
            break;

        case comparisonActions.ADD_MULTIPLE_EXPRESSIONS_TO_CONDITION:
            updateConditionOptimalistically({
                selectedCondition: selectedComparison.comparisons[comparisonIndex].conditionList[conditionIndex],
                expressionIdPath: expressionPath,
                expressionId: expressionId,
                referenceFieldIndex: referenceFieldIndex,
                action: action,
                expressions: expressions,
            });
            break;

        default:
            break;
    }

    setSelectedComparison(JSON.parse(JSON.stringify(selectedComparison)));
    setHasSubmitted(false);
};

/**
 * Set hasSubmitted state
 * @param {Boolean} hasSubmitted
 */
export const setHasSubmitted = (hasSubmitted) => setStoreState('hasSubmitted')(hasSubmitted);

/**
 * Open comparison modal state
 * @param {Object} { isOpen, data }
 */
export const openComparisonModal = ({ data, actionType }) =>
    setStoreState('comparisonModal')({ isOpen: true, data: data || {}, actionType });

/**
 * Open info modal state
 * @param {Object} { isOpen, data }
 */
export const openInfoModal = (data) => setStoreState('infoModal')({ isOpen: true, data: data || {} });

/**
 * Open input modal state
 * @returns {Object} { isOpen, data }
 */
export const openInputModal = (data) => setStoreState('inputModal')({ isOpen: true, data: data || {} });

/**
 * Open comparison field modal state
 */
export const openComparisonFieldModal = ({ data, type }) =>
    setStoreState('comparisonFieldModal')({ isOpen: true, data, type });

/**
 * Open comparison expression reference field modal state
 */
export const openComparisonExpressionReferenceFieldModal = (data) =>
    setStoreState('comparisonExpressionReferenceFieldModal')({ isOpen: true, data });

/**
 * Close comparisons modal
 */
export const closeComparisonModal = () => setStoreState('comparisonModal')({ isOpen: false, data: {} });

/**
 * Close info modal
 */
export const closeInfoModal = () => setStoreState('infoModal')({ isOpen: false, data: {} });

/**
 * Set all comparisons store state value
 * @param {{ loading: boolean, comparisons: Array<object> }} comparisons
 */
export const setAllComparisons = ({ loading, comparisons }) =>
    setStoreState('allComparisons')({ loading, comparisons });

/**
 * Close input modal
 */
export const closeInputModal = () => setStoreState('inputModal')({ isOpen: false, data: {} });

/**
 * Close comparison field modal
 */
export const closeComparisonFieldModal = () => setStoreState('comparisonFieldModal')({ isOpen: false, data: {} });

/**
 * Close comparison expression reference field modal
 */
export const closeComparisonExpressionReferenceFieldModal = () =>
    setStoreState('comparisonExpressionReferenceFieldModal')({ isOpen: false, data: {} });

/**
 * Set comparison id to scroll to
 * @param {String} comparisonId
 */
export const setSelectedComparisonIdToScroll = (comparisonId) =>
    setStoreState('selectedComparisonIdToScroll')(comparisonId);

/****** Use Store States ******/

/**
 * Get current page number and subscribe to changes
 * @returns {Integer}
 */
export const useCurrentPage = () => useStoreValue('currentPage', storeNamespace)(1);

/**
 * Get recently updated comparison id and subscribe to changes
 * @returns {String}
 */
export const useRecentlyUpdatedComparisonId = () => useStoreValue('recentlyUpdatedComparisonId', storeNamespace)('');

/**
 * Get current page comparisons and subscribe to changes
 * @returns {Array<Object>}
 */
export const useCurrentPageComparisons = () => useStoreValue('currentPageComparisons', storeNamespace)([]);

/**
 * Get should reload and subscribe to changes
 * @returns {Boolean}
 */
export const useShouldReload = () => useStoreValue('shouldReload', storeNamespace)(false);

/**
 * Get is ready and subscribe to changes
 * @returns {Boolean}
 */
export const useIsReady = () => useStoreValue('isReady', storeNamespace)(false);

/**
 * Get comparison modal state and subscribe to changes
 * @returns {Object}
 */
export const useComparisonModalState = () => useStoreValue('comparisonModal', storeNamespace)({});

/**
 * Get info modal state and subscribe to changes
 * @returns {Object}
 */
export const useInfoModalState = () => useStoreValue('infoModal', storeNamespace)({});

/**
 * Get all comparisons state and subscribe to changes
 * @return {{ loading: boolean, comparisons: object[] }}
 */
export const useAllComparisonsState = () =>
    useStoreValue('allComparisons', storeNamespace)(comparisonsStates.allComparisons);

/**
 * Get and set all comparisons state value by data request
 */
export const getAndSetAllComparisonsStateWithDataRequest = () => {
    getAllComparisons({
        successCallback: (res) => {
            const data = res?.data?.results?.length ? res.data.results : [];
            setAllComparisons({ loading: false, comparisons: data });
        },
        errorCallback: () => {
            setAllComparisons({ loading: false, comparisons: [] });
        },
    });
};

/**
 * Update all comparison optimistically
 * @param {{ actionType: string, comparison: object }}
 */
export const updateAllComparisonsOptimistically = ({ actionType, comparison }) => {
    updateStatesOptimistically({
        actionType,
        storeNamespace,
        data: comparison,
        state: 'allComparisons',
        dataListProp: 'comparisons',
    });
};

/**
 * Get selected comparison and subscribe to changes
 * @returns {Object}
 */
export const useSelectedComparison = () => useStoreValue('selectedComparison', storeNamespace)({});

/**
 * Get comparisons input modal state and subscribe to changes
 * @returns {Object}
 */
export const useInputModalState = () => useStoreValue('inputModal', storeNamespace)({});

/**
 * Get comparison field modal state and subscribe to changes
 * @returns {Object}
 */
export const useComparisonFieldModalState = () => useStoreValue('comparisonFieldModal', storeNamespace)({});

/**
 * Get comparison expression reference field modal state and subscribe to changes
 */
export const useComparisonExpressionReferenceFieldModalState = () =>
    useStoreValue('comparisonExpressionReferenceFieldModal', storeNamespace)({});

/**
 * Get hasSubmitted and subscribe to changes
 * @returns {Boolean}
 */
export const useHasSubmitted = () => useStoreValue('hasSubmitted', storeNamespace)(false);

/**
 * Get selected comparison id to scroll to and subscribe to changes
 * @returns {String}
 */
export const useSelectedComparisonIdToScroll = () => useStoreValue('selectedComparisonIdToScroll', storeNamespace)('');

/**
 * Get comparisons and subscribe to changes
 * @returns {Array<Object>}
 */
export const useComparisons = () => useStoreValue('comparisons', storeNamespace)([]);
