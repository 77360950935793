import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

const StepStopWorkflow = (props) => {
    const fill = props.fill || '#000';
    return (
        <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <rect x="0.900024" width="24" height="24" rx="5" fill="white" />
            <path
                d="M15.9 11.75C15.9 11.5625 15.7125 11.375 15.525 11.375H10.275C10.0563 11.375 9.90002 11.5625 9.90002 11.75V12.625C9.90002 12.8438 10.0563 13 10.275 13H15.525C15.7125 13 15.9 12.8438 15.9 12.625V11.75ZM15.525 14H10.275C10.0563 14 9.90002 14.1875 9.90002 14.375V15.25C9.90002 15.4688 10.0563 15.625 10.275 15.625H15.525C15.7125 15.625 15.9 15.4688 15.9 15.25V14.375C15.9 14.1875 15.7125 14 15.525 14ZM18.9 8.125C18.9 7.75 18.7125 7.34375 18.4313 7.0625L15.8375 4.46875C15.5563 4.1875 15.15 4 14.775 4H8.40002C7.55627 4 6.90002 4.6875 6.90002 5.5V18.5C6.90002 19.3438 7.55627 20 8.40002 20H17.4C18.2125 20 18.9 19.3438 18.9 18.5V8.125ZM14.9 5.625L17.275 8H14.9V5.625ZM17.4 18.5H8.40002V5.5H13.4V8.75C13.4 9.1875 13.7125 9.5 14.15 9.5H17.4V18.5Z"
                fill={fill}
            />
            <path
                d="M5.80005 4.58582L20.2143 19"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.80005 4.58582L20.2143 19"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

StepStopWorkflow.propTypes = {
    fill: PropTypes.string,
};

export default StepStopWorkflow;
