import { useEffect, useRef } from 'react';
import { useStoreValue, setStoreState, getStoreState, setStoreStates } from './useStoreStateHook';

export const storeNamespace = 'globalDragDrop';

export const defaultGlobalDragDropStates = {
    dragDropActionRef: {},
    dragDropEffect: 'copy',
    dragDropDragType: 'dragType',
    dragDropContextType: '',
    dragDropDraggedItem: null,
    dragDropDroppedItem: null,
    dragDropEffectAllowed: 'text/plain',
    dragDropIsDroppedItem: false,
    dragDropIsDraggingItem: false,
};

/**
 * Get drag drop action ref
 * @return {React.MutableRefObject}
 */
const getDragDropActionRefObject = () => getStoreState('dragDropActionRef', storeNamespace)({});

/**
 * Get the drag drop validation check callback
 * @return {undefined|Function}
 */
const getIsDropValidCallback = () => getDragDropActionRefObject()?.current?.isDropValidCallback;

/**
 * Get the drag drop onDragStart callback
 * @return {undefined|Function}
 */
const getOnDragStartCallback = () => getDragDropActionRefObject()?.current?.onDragStart;

/**
 * Get last dragged over item
 * @return {null|undefined|Object}
 */
export const getLastDraggedOverItem = () => getDragDropActionRefObject()?.current?.lastDraggedOverItem;

/**
 * Get dragged item
 * @return {object|null}
 */
export const getDraggedItem = () => getStoreState('dragDropDraggedItem', storeNamespace)(null);

/**
 * Get dragged type
 * @return {object|null}
 */
export const getDraggedType = () => getStoreState('dragDropDragType', storeNamespace)('');

/**
 * Get drag drop effect allowed
 * @return {string}
 */
export const getDragDropEffectAllowed = () => getStoreState('dragDropEffectAllowed', storeNamespace)('');

/**
 * Set dragged item state
 * @param {Object} data
 */
export const setDraggedItem = (data) => setStoreState('dragDropDraggedItem', storeNamespace)(data);

/**
 * Set dropped item state
 * @param {Object} data
 */
export const setDroppedItem = (data) => setStoreState('dragDropDroppedItem', storeNamespace)(data);

/**
 * Set dropped item state
 * @param {Boolean} isDragging
 */
export const setIsDraggingItem = (isDragging) => setStoreState('dragDropIsDraggingItem', storeNamespace)(isDragging);

/**
 * Set dropped item state
 * @param {String} context
 */
export const setDragDropContextType = (context) => setStoreState('dragDropContextType', storeNamespace)(context);

/**
 * Handle drag start
 * @param {{item: Object, onDragStart: Function}} item
 * @returns {Function}
 */
export const handleDragItemStart =
    ({ item, onDragStart } = {}) =>
    (event) => {
        const draggableId = item?.draggableId || item?.id || item?._id;
        if (draggableId !== undefined) {
            // const _data = typeof draggableId === 'object' ? JSON.stringify(draggableId.toString()) : draggableId;

            event.dataTransfer.effectAllowed = getDragDropEffectAllowed();
            // event.dataTransfer.setData(getDraggedType(), _data);
        }

        // document?.querySelectorAll('.isDraggingOver')?.forEach((elem) => elem?.classList?.remove('.isDraggingOver'));

        const dragDropStates = {
            dragDropDraggedItem: item,
            dragDropIsDraggingItem: true,
        };

        setStoreStates(dragDropStates);

        const onDragStartCallback = getOnDragStartCallback();
        onDragStartCallback && onDragStartCallback({ item, event });

        onDragStart &&
            onDragStart({
                dragDropStates,
                event,
            });
    };

/**
 * Handle drag end
 */
const handleDragItemEnd = () => {
    setTimeout(() => {
        setStoreStates({
            dragDropDraggedItem: null,
            dragDropDroppedItem: null,
            dragDropIsDroppedItem: false,
            dragDropIsDraggingItem: false,
        });
    }, 0);
};

/**
 * get draggable props
 * @param {{item: Object, onDragStart: Function }}
 * @returns {{draggable: true, onDragStart: Function }}
 */
export const getDragDropDraggableProps = ({ item, onDragStart }) => ({
    draggable: true,
    onDragStart: handleDragItemStart({ item, onDragStart }),
    onDragEnd: handleDragItemEnd,
});

/**
 * Handle drag drop item
 * @param {Object} event
 */
export const handleDropItem =
    ({ item, onDrop }) =>
    (event) => {
        event.preventDefault();

        const isDropValidCallback = getIsDropValidCallback();

        if (isDropValidCallback && !isDropValidCallback({ draggedItem: getDraggedItem() })) {
            // handleDragItemEnd();
            // return;
        }

        const dropProps = {
            dragDropDroppedItem: item,
            dragDropIsDroppedItem: true,
            dragDropIsDraggingItem: false,
        };

        setStoreStates(dropProps);
        onDrop && onDrop({ ...dropProps, item, event });

        // setTimeout(() => {
        //     setStoreStates({ ...dropProps, dragDropIsDroppedItem: false });
        // }, 0);
        // handleDragItemEnd();
    };

/**
 * Handle drag over
 * @param {{ item: Object }}
 */
const handleDragOver =
    ({ item }) =>
    (event) => {
        event.preventDefault();

        const dragDropActionRef = getDragDropActionRefObject();
        dragDropActionRef.current.lastDraggedOverItem = { item, event };
        setStoreState('dragDropActionRef', dragDropActionRef);
    };

/**
 * Handle drag leave
 */
const handleDragLeave = (event) => {
    event.preventDefault();

    const dragDropActionRef = getDragDropActionRefObject();
    dragDropActionRef.current.lastDraggedOverItem = null;
    setStoreState('dragDropActionRef', dragDropActionRef);
};

/**
 * get droppable props
 * @param {{item: Object, onDrop: Function }}
 * @returns {{draggable: true, onDrop: Function }}
 */
export const getDragDropDroppableProps = ({ item, onDrop }) => ({
    onDrop: handleDropItem({ item, onDrop }),
    onDragOver: handleDragOver({ item }),
    onDragLeave: handleDragLeave,
    // onDragEnter: handleDragEnter,
});

/**
 * Subscribe to global drag-drop state changes
 */
export const useIsDraggingItem = () => useStoreValue('dragDropIsDraggingItem', storeNamespace)();
export const useDraggedItemValue = () => useStoreValue('dragDropDraggedItem', storeNamespace)(null);
export const useDragDropDroppedItem = () => useStoreValue('dragDropDroppedItem', storeNamespace)(null);
export const useIsDraggedItemDropped = () => useStoreValue('dragDropIsDroppedItem', storeNamespace)();

/**
 * Global Drag Drop Component
 * @param {{  contextType: string, dragType: string, dropEffect: string, effectAllowed: string, isDropValidCallback: ({draggedItem: object}) => {}, onDragStart: ({ item, event}) => {} }} args
 *
 * Note: isDropValidCallback is passed the dragged item: `isDropValidCallback({ draggedItem: null|object })`
 *
 * @returns {{current: {}}} dragDropRef
 */
export const useGlobalDragDropComponent = ({
    dragType,
    dropEffect,
    effectAllowed,
    isDropValidCallback,
    onDragStart,
    contextType = '',
}) => {
    const dragDropRef = useRef({});

    useEffect(() => {
        const states = {
            dragDropContextType: contextType,
        };

        if (effectAllowed) {
            states.dragDropEffectAllowed = effectAllowed;
        }
        if (dropEffect) {
            states.dragDropEffect = dropEffect;
        }
        if (dragType) {
            states.dragDropDragType = dragType;
        }

        dragDropRef.current.onDragStart = onDragStart;
        dragDropRef.current.isDropValidCallback = isDropValidCallback;
        states.dragDropActionRef = dragDropRef;

        setStoreStates(states);
    }, []);

    return dragDropRef;
};
