import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { useStoreValue, setStoreState, getStoreState, setStoreStates, useStoreState } from './useStoreStateHook';
import { getAppInterfaceWebhooks, getApps } from '../utils/myAppDataRequestUtils';
import { isIntelyOrganizationCurrentlySelected } from '../utils/loginUtils';

export const storeNamespace = 'myApps';

/**
 * Default states
 */
export const myAppStates = {
    apps: [],
    deletedAppId: '',
    selectedAppData: {},
    isMyAppsReady: false,
    myAppsTotalCount: 0,
    deleteMyAppModal: {},
    selectedAppInterface: {},
    selectedAppInterfaces: [],
    recentlyUpdatedAppId: '',
    appInterfaceWebhooks: [],
    allAppInterfaceWebhooks: [],
    recentlyUpdatedAppInterfaceId: '',
    selectedAppInterfaceWebhook: {},
    selectedAppInterfaceResource: {},
    selectedAppInterfaceResources: [],
    selectedAppInterfaceInstanceField: {},
    selectedAppInterfaceInstanceFields: [],
    selectedAppInterfaceResourceAction: {},
    selectedAppInterfaceResourceActions: [],
    recentlyUpdatedAppInterfaceWebhookId: '',
    recentlyUpdatedAppInterfaceProgramFieldId: '',
    recentlyUpdatedAppInterfaceInstanceFieldId: '',
    recentlyUpdatedAppInterfaceResourceActionId: '',

    // Data Table
    currentMyAppsPage: 1,
};

/**
 * Reset selected app states
 * @param {Object} stats State to set
 */
export const resetSelectedAppStates = (states = {}) => {
    setStoreStates({
        deletedAppId: '',
        selectedAppData: {},
        deleteMyAppModal: {},
        selectedAppInterface: {},
        selectedAppInterfaces: [],
        selectedAppInterfaceResource: {},
        selectedAppInterfaceResources: [],
        selectedAppInterfaceInstanceField: {},
        selectedAppInterfaceInstanceFields: [],
        selectedAppInterfaceResourceAction: {},
        selectedAppInterfaceResourceActions: [],
        ...states,
    });
};

/**
 * Reset selected app interface states
 * @param {Object} states
 */
export const resetSelectedAppInterfaceStates = (states = {}) => {
    setStoreStates({
        selectedAppData: {},
        deleteMyAppModal: {},
        selectedAppInterface: {},
        selectedAppInterfaceResource: {},
        selectedAppInterfaceResources: [],
        selectedAppInterfaceInstanceField: {},
        selectedAppInterfaceInstanceFields: [],
        selectedAppInterfaceResourceAction: {},
        selectedAppInterfaceResourceActions: [],
        ...states,
    });
};

/**
 * Reset selected app interface resource and instance field states
 * @param {Object} states
 */
export const resetSelectedAppInterfaceResourceAndInstanceFieldStates = (states = {}) => {
    setStoreStates({
        deleteMyAppModal: {},
        selectedAppInterfaceResource: {},
        selectedAppInterfaceInstanceField: {},
        selectedAppInterfaceResourceActions: [],
        ...states,
    });
};
/**
 * Reset selected app interface resource action states
 * @param {Object} states
 */
export const resetSelectedAppInterfaceResourceActionStates = (states = {}) => {
    setStoreStates({
        selectedAppInterfaceResourceAction: {},
        ...states,
    });
};

/**
 * Set app states
 * @see {myAppStates} var
 */
export const setAppStates = (states) => {
    setStoreStates(states);
};

/**
 * Set app states
 * @param {Object} args
 * @param {Array<object>} args.apps Apps data list
 * @param {Boolean} args.isReady Whether the apps data is ready
 * @param {Number} args.totalApps Apps total count
 */
export const setAppsData = ({ apps, totalApps, isReady = false }) => {
    const states = { isMyAppsReady: isReady };

    if (apps) {
        states.apps = apps;
    }
    if (totalApps) {
        states.myAppsTotalCount = totalApps;
    }

    setStoreStates(states);
};

/**
 * Get the recently deleted app ID and subscribe to changes.
 * @returns {String}
 */
export const useDeletedAppId = () => useStoreValue('deletedAppId', storeNamespace)('');

/**
 * Get apps ready state and subscribe to changes.
 * @returns {String}
 */
export const useIsMyAppsReady = () => useStoreValue('isMyAppsReady', storeNamespace)(false);

/**
 * Get apps ready state and subscribe to changes.
 * @returns {String}
 */
export const useMyAppsTotalCount = () => useStoreValue('myAppsTotalCount', storeNamespace)(false);

/**
 * Get the recently deleted app ID and subscribe to changes.
 * @returns {String}
 */
export const clearDeletedAppId = () => {
    setStoreStates({
        deletedAppId: '',
    });
};

/**
 * Get the recently updated app ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedAppId = () => useStoreValue('recentlyUpdatedAppId', storeNamespace)('');

/**
 * Get the recently updated app interface ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedAppInterfaceId = () =>
    useStoreValue('recentlyUpdatedAppInterfaceId', storeNamespace)('');

/**
 * Get the recently updated app interface program field ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedAppInterfaceProgramFieldId = () =>
    useStoreValue('recentlyUpdatedAppInterfaceProgramFieldId', storeNamespace)('');

/**
 * Get the recently updated app interface instance field ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedAppInterfaceInstanceFieldId = () =>
    useStoreValue('recentlyUpdatedAppInterfaceInstanceFieldId', storeNamespace)('');

/**
 * Get the recently updated app interface webhook ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedAppInterfaceWebhookId = () =>
    useStoreValue('recentlyUpdatedAppInterfaceWebhookId', storeNamespace)('');

/**
 * Get the recently updated app interface resource action ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedAppInterfaceResourceActionId = () =>
    useStoreValue('recentlyUpdatedAppInterfaceResourceActionId', storeNamespace)('');

/**
 * Get the selected app data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useSelectedAppData = () => useStoreValue('selectedAppData', storeNamespace)({});

/**
 * Get the selected app interfaces data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useSelectedAppInterfaces = () => useStoreValue('selectedAppInterfaces', storeNamespace)([]);

/**
 * Get selected app interfaces
 * @returns {Array<object>}
 */
export const getSelectedAppInterfaces = () => getStoreState('selectedAppInterfaces', storeNamespace)([]);

/**
 * Get the selected app interface data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedAppInterface = () => useStoreValue('selectedAppInterface', storeNamespace)({});

/**
 * Get the selected app interface program fields and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useSelectedAppInterfaceProgramFields = () =>
    useSelector((state) => state?.[storeNamespace]?.selectedAppInterface?.programFields || []);

/**
 * Get the selected app interface program fields.
 * @returns {Array<Object>}
 */
export const getSelectedAppInterface = () => getStoreState('selectedAppInterface', storeNamespace)({});

/**
 * Get the selected app interface resources data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useSelectedAppInterfaceResources = () =>
    useStoreValue('selectedAppInterfaceResources', storeNamespace)({});

/**
 * Get the selected app interface resource data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedAppInterfaceResource = () => useStoreValue('selectedAppInterfaceResource', storeNamespace)({});

/**
 * Get the selected app interface webhook data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedAppInterfaceWebhook = () => useStoreValue('selectedAppInterfaceWebhook', storeNamespace)({});

/**
 * Get the selected app interface webhooks data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useAppInterfaceWebhooks = () => useStoreValue('appInterfaceWebhooks', storeNamespace)({});

/**
 * Get the selected app interface resource action data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedAppInterfaceResourceAction = () =>
    useStoreValue('selectedAppInterfaceResourceAction', storeNamespace)({});

/**
 * Get the selected app interface resource action data.
 * @returns {Object}
 */
export const getSelectedAppInterfaceResourceAction = () =>
    getStoreState('selectedAppInterfaceResourceAction', storeNamespace)({});

/**
 * Get the selected app interface resource actions data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedAppInterfaceResourceActions = () =>
    useStoreValue('selectedAppInterfaceResourceActions', storeNamespace)({});

/**
 * Get the selected app interface instance field data and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedAppInterfaceInstanceField = () =>
    useStoreValue('selectedAppInterfaceInstanceField', storeNamespace)({});

/**
 * Get the selected app interface instance field data.
 * @returns {Object}
 */
export const getSelectedAppInterfaceInstanceField = () =>
    getStoreState('selectedAppInterfaceInstanceField', storeNamespace)({});

/**
 * Get the selected app interface instance fields data and subscribe to changes.
 * @returns {Array<object>}
 */
export const useSelectedAppInterfaceInstanceFields = () =>
    useStoreValue('selectedAppInterfaceInstanceFields', storeNamespace)({});

/**
 * Set my apps data table current page
 * @param {Number} pageNo
 */
export const setMyAppsCurrentPage = (pageNo) => setStoreState('currentMyAppsPage', storeNamespace)(pageNo);

/**
 * Get current my apps data table page and subscribe to changes.
 * @returns {Number}
 */
export const useMyAppsCurrentPage = () => useStoreValue('currentMyAppsPage', storeNamespace)(1);

/**
 * Get the current screen data
 * @return {Object} Screen data
 */
export const useMyAppCurrentScreenData = () => {
    const { appId, actionId, interfaceId, resourceId, programFieldId, instanceFieldId, interfaceWebhookId } =
            useParams(),
        screenData = { requiredFields: {} };

    if (actionId) {
        screenData.requiredFields = {
            name: '',
            responses: [],
            isEnabled: '',
            requireAuthorization: '',
        };
    } else if (resourceId) {
        screenData.requiredFields = {
            name: '',
            isEnabled: '',
        };
    } else if (interfaceWebhookId) {
        screenData.requiredFields = {
            name: '',
            isEnabled: '',
            // schema: '', // responseBody.schema
            // contentType: '', // responseBody.contentType
            // userCanOverride: '', // responseBody.useCanOverride
            responseBody: {
                // schema: '',
                // contentType: '',
                userCanOverride: '',
            },
            requestBody: {
                // schema: '',
                // contentType: '',
                userCanOverride: '',
            },
        };
    } else if (instanceFieldId) {
        screenData.requiredFields = {
            name: '',
            field: '',
            display: '',
            required: '',
            actionId: '',
            fieldType: '',
            inputType: '',
            resourceId: '',
            mappingType: '',
            displayName: '',
            inputTypeSelectOptions: '',
        };
    } else if (programFieldId) {
        screenData.requiredFields = {
            name: '',
            value: '',
        };
    } else if (interfaceId) {
        screenData.requiredFields = {
            name: '',
            isEnabled: '',
            method: 'None',
        };

        if (isIntelyOrganizationCurrentlySelected()) {
            screenData.requiredFields.pricePerCall = '';
        }
    } else if (appId) {
        screenData.requiredFields = {
            name: '',
            category: '',
            isEnabled: '',
            isPublic: '',
            isStandard: true,
        };
    }

    return screenData;
};

/**
 * Update apps list optimistically
 * @param {Object} args
 * @param {String} args.actionType. Accepted values: 'create' | 'update' | 'delete'
 * @param {Object} args.data
 */
export const updateAppsListOptimally = ({ actionType, data }) => {
    let newDataList = [];
    const apps = getStoreState('apps', storeNamespace)([]),
        selectedAppId = data?._id,
        isDeleteAction = actionType === 'delete';

    if (actionType === 'create') {
        newDataList = [data].concat(apps);
    } else if (isDeleteAction) {
        newDataList = apps?.filter((app) => selectedAppId !== app?._id);
    } else if (actionType === 'update') {
        newDataList = apps?.map((app) => {
            if (selectedAppId !== app?._id) {
                return app;
            }
            return data;
        });
    } else {
        return;
    }

    const appState = {
        apps: newDataList,
        recentlyUpdatedAppId: isDeleteAction ? '' : selectedAppId,
    };

    if (isDeleteAction) {
        appState.deletedAppId = selectedAppId;
    }

    // Update the list and mark the modified row
    setStoreStates(appState);

    // Clear the highlighted row after 4 seconds
    if (!isDeleteAction) {
        setTimeout(() => {
            setStoreState('recentlyUpdatedAppId')('');
        }, 4000);
    }
};

/**
 * Update app interfaces list optimistically
 * @param {Object} args
 * @param {String} args.actionType. Accepted values: 'create' | 'update' | 'delete'
 * @param {Object} args.data
 */
export const updateAppInterfacesListOptimally = ({ actionType, data }) => {
    let newDataList = [];
    const appInterfaces = getStoreState('selectedAppInterfaces', storeNamespace)([]),
        selectedDataId = data?._id,
        isDeleteAction = actionType === 'delete';

    if (actionType === 'create') {
        newDataList = [data].concat(appInterfaces);
    } else if (isDeleteAction) {
        newDataList = appInterfaces?.filter((row) => selectedDataId !== row?._id);
    } else if (actionType === 'update') {
        newDataList = appInterfaces?.map((row) => {
            if (selectedDataId !== row?._id) {
                return row;
            }
            return data;
        });
    } else {
        return;
    }

    // Update the list and mark the modified row
    setStoreStates({
        selectedAppInterfaces: newDataList,
        recentlyUpdatedAppInterfaceId: isDeleteAction ? '' : selectedDataId,
    });

    // Clear the highlighted row after 4 seconds
    if (!isDeleteAction) {
        setTimeout(() => {
            setStoreState('recentlyUpdatedAppInterfaceId')('');
        }, 4000);
    }
};

/**
 * Update app interface resources optimistically
 * @param {Object} args
 * @param {String} args.actionType. Accepted values: 'create' | 'update' | 'delete'
 * @param {Object} args.data
 */
export const updateStatesOptimistically = ({
    data,
    state,
    actionType,
    dataProp = '_id',
    dataListProp = '',
    dataListIndex = '',
    recentStateId = '',
    storeNamespace: _storeNamespace = null,
}) => {
    let newDataList = [];
    const dataObj = getStoreState(state, _storeNamespace || storeNamespace)([]),
        dataList = dataListProp ? dataObj?.[dataListProp] || [] : dataObj,
        selectedDataId = data?.[dataProp],
        isDeleteAction = actionType === 'delete';

    if (actionType === 'create') {
        newDataList = [data].concat(dataList);
    } else if (isDeleteAction) {
        newDataList = dataList?.filter((row, index) => {
            if (dataListIndex) {
                return dataListIndex !== index;
            } else {
                return selectedDataId !== row?.[dataProp];
            }
        });
    } else if (actionType === 'update') {
        newDataList = dataList?.map((row, index) => {
            if (dataListIndex) {
                if (dataListIndex !== index) {
                    return row;
                }
            } else {
                if (selectedDataId !== row?.[dataProp]) {
                    return row;
                }
            }
            return data;
        });
    } else {
        return;
    }

    if (dataListProp) {
        dataObj[dataListProp] = [...newDataList];
        newDataList = { ...dataObj };
    }

    const newStates = {
        [state]: newDataList,
    };

    if (recentStateId) {
        newStates[recentStateId] = isDeleteAction ? '' : selectedDataId;
    }

    // Update the list and mark the modified row
    setStoreStates(newStates);

    // Clear the highlighted row after 4 seconds
    if (!isDeleteAction && recentStateId) {
        setTimeout(() => {
            setStoreState(recentStateId)('');
        }, 4000);
    }
};

/**
 * Update app interface program fields optimistically
 * @param {Object} args
 * @param {String} args.actionType. Accepted values: 'create' | 'update' | 'delete'
 * @param {Object} args.data
 */
export const updateAppInterfaceProgramFieldsOptimally = ({ actionType, data }) => {
    let newDataList = [];
    const selectedAppInterface = { ...getSelectedAppInterface() },
        programFields = selectedAppInterface?.programFields || [],
        selectedDataId = data?.name,
        isDeleteAction = actionType === 'delete';

    if (actionType === 'create') {
        newDataList = [data].concat(programFields);
    } else if (isDeleteAction) {
        newDataList = programFields?.filter((row) => selectedDataId !== row?.name);
    } else if (actionType === 'update') {
        newDataList = programFields?.map((row) => {
            if (selectedDataId !== row?.name) {
                return row;
            }
            return {
                ...row,
                ...data,
            };
        });
    } else {
        return;
    }

    selectedAppInterface.programFields = newDataList;

    // Update the list and mark the modified row
    setStoreStates({
        selectedAppInterface,
        recentlyUpdatedAppInterfaceProgramFieldId: isDeleteAction ? '' : selectedDataId,
    });

    // Clear the highlighted row after 4 seconds
    if (!isDeleteAction) {
        setTimeout(() => {
            setStoreState('recentlyUpdatedAppInterfaceProgramFieldId')('');
        }, 4000);
    }
};

/**
 * Set all apps interface webhooks
 * @param {Array<object>} webhooks
 */
export const setAllAppInterfaceWebhooks = (webhooks) =>
    setStoreState('allAppInterfaceWebhooks', storeNamespace)(webhooks);

/**
 * Get all app interface webhooks
 * @param {object} args
 * @return {Array<object>}
 */
export const useAllAppInterfaceWebhooks = ({ appId, interfaceId }) => {
    const [webhooks, setWebhooks] = useStoreState('allAppInterfaceWebhooks', storeNamespace)([]),
        webhooksValue = useAllAppInterfaceWebhooksValue(),
        [webhooksLoaded, setWebhooksLoaded] = useState(false);

    useEffect(() => {
        if (!appId || !interfaceId || webhooksValue?.length || webhooksLoaded) return;
        getAppInterfaceWebhooks({
            appId,
            interfaceId,
            pageNo: -1,
            successCallback: (res) => {
                setWebhooks(res?.data?.results || res?.data || []);
            },
            errorCallback: () => {
                setWebhooks([]);
            },
            finallyCallback: () => {
                setWebhooksLoaded(true);
            },
        });
    }, [appId, interfaceId, webhooksValue]);

    return webhooks;
};

/**
 * Get webhook from all app interface webhooks by ID
 * @param {string} webhookId
 * @return {object} Webhook data
 */
export const filterAllAppInterfaceWebhooksById = (webhookId) =>
    getStoreState('allAppInterfaceWebhooks', storeNamespace)([])?.find((webhook) => webhook?._id === webhookId) || {};

/**
 * Get the apps list data and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useAllAppInterfaceWebhooksValue = () => useStoreValue('allAppInterfaceWebhooks', storeNamespace)([]);

/**
 * Get apps
 */
export const getMyAppsList = () => {
    getApps({
        pageNo: 1,
        includePublicApps: false,
        successCallback: (res) => {
            setAppsData({
                apps: res.data?.results || [],
                isReady: true,
                totalApps: res.data?.totalNumberOfResults || 0,
            });
        },
        errorCallback: () => {
            setAppsData({
                apps: [],
                isReady: true,
                totalApps: 0,
            });
        },
    });
};

/**
 * Get the apps list data and subscribe to changes.
 * @returns {Array<Object>}
 */
const useMyApps = () => useStoreValue('apps', storeNamespace)([]);
export default useMyApps;
