const CryptoJS = require('crypto-js');

/**
 * @param {String} string
 * @param {String} encryptionKey
 * @returns {String|false}
 */
export const encryptString = (string, encryptionKey) => {
    if (string && encryptionKey) {
        return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), encryptionKey).toString();
    }

    return false;
};

/**
 * @param {String} encryptedString
 * @param {String} encryptionKey
 * @returns {String|false}
 */
export const decryptString = (encryptedString, encryptionKey) => {
    if (encryptedString && encryptionKey) {
        const bytes = CryptoJS.AES.decrypt(encryptedString, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    return false;
};

/**
 * Determine if a string is all alphanumeric characters with a list of exception characters.
 *
 * @param {String} string
 * @param {[String]} allowedSpecialCharacters
 * @returns {Boolean}
 */
export const isAlphaNumeric = (string, allowedSpecialCharacters = []) => {
    if (typeof string === 'string') {
        const listOfCharacters = string.split('');
        let numberOfMatchingCharacters = 0;

        for (const character of listOfCharacters) {
            const code = character.charCodeAt(0);

            if (
                (code > 47 && code < 58) || // numeric (0-9)
                (code > 64 && code < 91) || // upper alpha (A-Z)
                (code > 96 && code < 123) || // lower alpha (a-z)
                allowedSpecialCharacters.includes(character)
            ) {
                numberOfMatchingCharacters++;
            }
        }

        return numberOfMatchingCharacters === listOfCharacters.length;
    }

    return false;
};

/**
 * @param {String} string
 * @param {'MD5'|'SHA1'|'SHA224'|'SHA256'|'SHA384'|'SHA512'|'SHA3'} [algorithm="SHA256"]
 * @returns {String}
 */
export const hashString = (string, algorithm) => {
    let alg = 'SHA256';

    if (algorithm) {
        alg = algorithm.toUpperCase();
    }

    switch (alg) {
        case 'MD5':
        case 'SHA1':
        case 'SHA224':
        case 'SHA256':
        case 'SHA384':
        case 'SHA512':
        case 'SHA3':
            return CryptoJS[alg](string).toString();
        default:
            return 'Unsuported algorithm - please use one of the following: MD5, SHA1, SHA224, SHA256, SHA384, SHA512, SHA3';
    }
};
