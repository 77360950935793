import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../IntegrationBuilderStepDrawer/IntegrationBuilderStepDrawerFields/IntegrationStepSelectAppInstanceRequestBodyField/styles';
import IntegrationBuilderStepDrawerFieldGrid from '../IntegrationBuilderStepDrawer/IntegrationBuilderStepDrawerFieldGrid';
import SelectField from '../../InputFields/SelectField';
import { useIntegrationBuilderValue } from '../../../hooks/useIntegrationBuilderHook';
import InputLabel from '../../InputFields/InputLabel';
import TextField from '../../InputFields/TextField';
import { isValidAuthorizationConfig } from '../../../utils/integrationUtils';
import Switch from '@mui/material/Switch';
import IntegrationBuilderSettingsAccordion from '../IntegrationBuilderSettingsContainer/IntegrationBuilderSettingsAccordion';
import KeyValuePairFieldSet, { handleKeyValuePairRemove } from '../../InputFields/KeyValuePairFieldSet';

/**
 * @typedef AuthorizationFieldUpdate
 * @property {'field'|'stringModal'|'keyValuePair'} updateType
 * @property {'field'|'keyValuePair'?} stringModalUpdateType
 * @property {string} fieldName
 * @property {string|Array|Object} currentValue
 * @property {string?} subfieldSet
 * @property {'key'|'value'|'fullUpdate'?} keyValuePairUpdateType
 * @property {number?} index
 */

/**
 *
 * @param {IntegrationBuilderProps} integrationBuilderProps
 * @param {AuthorizationConfig} authorizationConfig
 * @param {(updatedData: AuthorizationFieldUpdate) => void} handleInputChange
 * @param {Object} dataBeingUpdated
 * @returns {JSX.Element}
 */
const IntegrationBuilderAuthorizationFields = ({
    integrationBuilderProps,
    authorizationConfig,
    handleInputChange,
    dataBeingUpdated,
}) => {
    const { isUserAdmin, selectedIntegrationData } = integrationBuilderProps,
        isSubmittingIntegrationBuilderForm = useIntegrationBuilderValue('isSubmittingIntegrationBuilderForm'),
        authorizationTypes = [
            {
                id: '',
                label: 'None',
            },
            {
                id: 'basic',
                label: 'Basic',
            },
            {
                id: 'apiKey',
                label: 'API Key',
            },
            {
                id: 'bearer',
                label: 'Bearer Token',
            },
        ],
        bearerTokenData = authorizationConfig?.bearerTokenData || {},
        bearerAuthorizationTypes = [
            {
                id: '',
                label: 'None',
            },
            {
                id: 'oauth',
                label: 'OAuth',
            },
            {
                id: 'basic',
                label: 'Basic',
            },
            {
                id: 'apiKey',
                label: 'API Key',
            },
        ],
        [currentErrors, setCurrentErrors] = useState({}),
        [rerender, setRerender] = useState(0);

    /**
     * Returns a function that can handle the given inputs for a keyValue pair input set.
     * @param {string} fieldSet
     * @param {string} fieldName
     * @returns {function(keyType: string, index: number, keyValue: string|KeyValuePairFieldSet): {onClick: function(): void, onChange: function(*): void}}
     */
    const getAdditionalPropsForFieldSet = (fieldSet, fieldName) => {
        return (keyType, index, keyValue) => {
            return {
                onClick: () => {
                    switch (keyType) {
                        case 'key':
                            // Handled by change event
                            break;
                        case 'value':
                            handleInputChange({
                                updateType: 'stringModal',
                                stringModalUpdateType: 'keyValuePair',
                                fieldName: fieldName,
                                subfieldSet: fieldSet,
                                keyValuePairUpdateType: 'value',
                                index: index,
                                currentValue: keyValue?.rawData || '',
                            });
                            break;
                    }
                },
                onChange: (e) => {
                    switch (keyType) {
                        case 'key':
                            handleInputChange({
                                updateType: 'keyValuePair',
                                fieldName: fieldName,
                                currentValue: e.target.value,
                                subfieldSet: fieldSet,
                                index: index,
                                keyValuePairUpdateType: keyType,
                            });
                            break;
                        case 'value':
                            // Handled by click event
                            break;
                    }
                },
            };
        };
    };

    useEffect(() => {
        setCurrentErrors(isValidAuthorizationConfig(authorizationConfig, false));
    }, [isSubmittingIntegrationBuilderForm, dataBeingUpdated, authorizationConfig, rerender]);

    return (
        <div>
            <InputLabel
                sx={styles.mappingLabel}
                label={
                    <>
                        Authorization Type
                        <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                    </>
                }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IntegrationBuilderStepDrawerFieldGrid
                    label=""
                    isRequired={true}
                    style={{ ...styles.fieldGrid, width: '150px' }}
                    integrationBuilderProps={integrationBuilderProps}
                >
                    <SelectField
                        items={authorizationTypes}
                        name={'authorizationType'}
                        label={null}
                        required={true}
                        renderWithGrid={false}
                        isSubmittingForm={isSubmittingIntegrationBuilderForm}
                        placeholder="Select Authorization Type"
                        searchPlaceholder="Search Authorization Type"
                        validate={{ errorLabel: 'Authorization Type' }}
                        getItemId={(option) => option.id}
                        getItemLabel={(option) => option.label}
                        getItemValue={(option) => option.id}
                        value={authorizationConfig?.authorizationType || ''}
                        onChange={(e) => {
                            handleInputChange({
                                updateType: 'field',
                                fieldName: e.target.name,
                                currentValue: e.target.value,
                            });
                        }}
                        disabled={!isUserAdmin}
                    />
                </IntegrationBuilderStepDrawerFieldGrid>
            </div>

            {authorizationConfig?.authorizationType === 'basic' && (
                <>
                    <InputLabel
                        sx={styles.mappingLabel}
                        label={
                            <>
                                Username<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            </>
                        }
                    />
                    <TextField
                        name={'username'}
                        value={authorizationConfig?.username || ''}
                        type={'text'}
                        onChange={() => {
                            setRerender(rerender + 1);
                        }}
                        label={null}
                        required={false}
                        renderWithGrid={false}
                        error={currentErrors?.['username'] === true}
                        validate={{
                            errorLabel: 'Username',
                        }}
                        inputProps={{
                            onClick: () => {
                                handleInputChange({
                                    updateType: 'stringModal',
                                    stringModalUpdateType: 'field',
                                    fieldName: 'username',
                                    subfieldSet: '',
                                    currentValue: authorizationConfig?.username || '',
                                });

                                setRerender(rerender + 1);
                            },
                        }}
                    />
                    <InputLabel
                        sx={styles.mappingLabel}
                        label={
                            <>
                                Password<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            </>
                        }
                    />
                    <TextField
                        name={'password'}
                        value={authorizationConfig?.password || ''}
                        type={'password'}
                        onChange={() => {
                            setRerender(rerender + 1);
                        }}
                        label={null}
                        required={false}
                        renderWithGrid={false}
                        error={currentErrors?.['password'] === true}
                        validate={{
                            errorLabel: 'Password',
                        }}
                        inputProps={{
                            onClick: () => {
                                handleInputChange({
                                    updateType: 'stringModal',
                                    stringModalUpdateType: 'field',
                                    fieldName: 'password',
                                    subfieldSet: '',
                                    currentValue: authorizationConfig?.password || '',
                                });

                                setRerender(rerender + 1);
                            },
                        }}
                    />
                </>
            )}
            {authorizationConfig?.authorizationType === 'apiKey' && (
                <>
                    <InputLabel
                        sx={styles.mappingLabel}
                        label={
                            <>
                                Location<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            </>
                        }
                    />
                    <SelectField
                        items={[
                            { label: 'None', value: '' },
                            { label: 'Query Parameters', value: 'queryParams' },
                            { label: 'Headers', value: 'headers' },
                        ]}
                        name={'keyLocation'}
                        label={null}
                        required={false}
                        renderWithGrid={false}
                        getItemId={(option) => option.value}
                        getItemLabel={(option) => option.label}
                        getItemValue={(option) => option.value}
                        value={authorizationConfig?.['keyLocation'] || ''}
                        error={currentErrors?.['keyLocation'] === true}
                        onChange={(e) => {
                            handleInputChange({
                                updateType: 'field',
                                fieldName: e.target.name,
                                currentValue: e.target.value,
                                subfieldSet: '',
                            });

                            setRerender(rerender + 1);
                        }}
                        disabled={!isUserAdmin}
                    />
                    <InputLabel
                        sx={styles.mappingLabel}
                        label={
                            <>
                                Field Name<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            </>
                        }
                    />
                    <TextField
                        name={'headerField'}
                        value={authorizationConfig?.headerField || ''}
                        type={'text'}
                        onChange={() => {
                            setRerender(rerender + 1);
                        }}
                        label={null}
                        required={false}
                        renderWithGrid={false}
                        error={currentErrors?.['headerField'] === true}
                        validate={{
                            errorLabel: 'Field Name',
                        }}
                        inputProps={{
                            onClick: () => {
                                handleInputChange({
                                    updateType: 'stringModal',
                                    stringModalUpdateType: 'field',
                                    fieldName: 'headerField',
                                    subfieldSet: '',
                                    currentValue: authorizationConfig?.headerField || '',
                                });

                                setRerender(rerender + 1);
                            },
                        }}
                    />
                    <InputLabel
                        sx={styles.mappingLabel}
                        label={
                            <>
                                API Key<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            </>
                        }
                    />
                    <TextField
                        name={'apiKey'}
                        value={authorizationConfig?.apiKey || ''}
                        type={'password'}
                        onChange={() => {
                            setRerender(rerender + 1);
                        }}
                        label={null}
                        required={false}
                        renderWithGrid={false}
                        error={currentErrors?.['apiKey'] === true}
                        validate={{
                            errorLabel: 'API Key',
                        }}
                        inputProps={{
                            onClick: () => {
                                handleInputChange({
                                    updateType: 'stringModal',
                                    stringModalUpdateType: 'field',
                                    fieldName: 'apiKey',
                                    subfieldSet: '',
                                    currentValue: authorizationConfig?.apiKey || '',
                                });

                                setRerender(rerender + 1);
                            },
                        }}
                    />
                </>
            )}
            {authorizationConfig?.authorizationType === 'bearer' && (
                <>
                    <InputLabel
                        sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                        label={
                            <>
                                Token Variable
                                <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            </>
                        }
                    />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <IntegrationBuilderStepDrawerFieldGrid
                            label=""
                            isRequired={true}
                            style={{ ...styles.fieldGrid, width: '150px' }}
                            integrationBuilderProps={integrationBuilderProps}
                        >
                            <SelectField
                                items={[{ name: '' }, ...(selectedIntegrationData?.variables || [])]}
                                name={'bearerMainTokenVariable'}
                                label={null}
                                required={true}
                                renderWithGrid={false}
                                isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                placeholder="Select Variable"
                                searchPlaceholder="Search Variable"
                                error={currentErrors?.bearerMainTokenVariable === true}
                                validate={{ errorLabel: 'Variable' }}
                                getItemId={(option) => option.name}
                                getItemLabel={(option) => {
                                    if (option.name.length === 0) {
                                        return 'None';
                                    } else {
                                        return option.name;
                                    }
                                }}
                                getItemValue={(option) => {
                                    if (option.name.length > 0) {
                                        return `$${option.name}`;
                                    }

                                    return option.name;
                                }}
                                value={bearerTokenData?.bearerMainTokenVariable || ''}
                                onChange={(e) => {
                                    handleInputChange({
                                        updateType: 'field',
                                        subfieldSet: 'bearer',
                                        fieldName: e.target.name,
                                        currentValue: e.target.value,
                                    });
                                }}
                                disabled={!isUserAdmin}
                            />
                        </IntegrationBuilderStepDrawerFieldGrid>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            color: '#000',
                            fontSize: '14px',
                        }}
                    >
                        <div>Requires Token URL</div>
                        <Switch
                            name="bearerRequireTokenUrl"
                            checked={!!bearerTokenData?.bearerRequireTokenUrl}
                            onChange={(e) => {
                                handleInputChange({
                                    updateType: 'field',
                                    subfieldSet: 'bearer',
                                    fieldName: e.target.name,
                                    currentValue: e.target.checked,
                                });
                            }}
                        />
                    </div>
                    {!!bearerTokenData?.bearerRequireTokenUrl && (
                        <>
                            <InputLabel
                                sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                label={
                                    <>
                                        Method
                                        <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                    </>
                                }
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IntegrationBuilderStepDrawerFieldGrid
                                    label=""
                                    isRequired={true}
                                    style={{ ...styles.fieldGrid, width: '150px' }}
                                    integrationBuilderProps={integrationBuilderProps}
                                >
                                    <SelectField
                                        items={['', 'GET', 'POST']}
                                        name={'bearerTokenUrlMethod'}
                                        label={null}
                                        required={true}
                                        renderWithGrid={false}
                                        isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                        placeholder="Select Method"
                                        searchPlaceholder="Search Method"
                                        validate={{ errorLabel: 'Method' }}
                                        getItemId={(option) => option}
                                        getItemLabel={(option) => {
                                            if (option.length === 0) {
                                                return 'None';
                                            } else {
                                                return option;
                                            }
                                        }}
                                        getItemValue={(option) => option}
                                        value={bearerTokenData?.bearerTokenUrlMethod || ''}
                                        onChange={(e) => {
                                            handleInputChange({
                                                updateType: 'field',
                                                subfieldSet: 'bearer',
                                                fieldName: e.target.name,
                                                currentValue: e.target.value,
                                            });
                                        }}
                                        disabled={!isUserAdmin}
                                    />
                                </IntegrationBuilderStepDrawerFieldGrid>
                            </div>
                            <InputLabel
                                sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                label={
                                    <>
                                        Token URL
                                        <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                    </>
                                }
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IntegrationBuilderStepDrawerFieldGrid
                                    label=""
                                    isRequired={true}
                                    style={{ ...styles.fieldGrid, width: '150px' }}
                                    integrationBuilderProps={integrationBuilderProps}
                                >
                                    <TextField
                                        name={'bearerMainAuthorizationEndpoint'}
                                        value={bearerTokenData?.bearerMainAuthorizationEndpoint || ''}
                                        type={'text'}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        placeholder={'https://www.example.com/'}
                                        validate={{
                                            errorLabel: 'Token URL',
                                        }}
                                        error={currentErrors?.bearerMainAuthorizationEndpoint}
                                        inputProps={{
                                            onClick: () =>
                                                handleInputChange({
                                                    updateType: 'stringModal',
                                                    stringModalUpdateType: 'field',
                                                    fieldName: 'bearerMainAuthorizationEndpoint',
                                                    subfieldSet: 'bearer',
                                                    currentValue:
                                                        bearerTokenData?.bearerMainAuthorizationEndpoint || '',
                                                }),
                                        }}
                                    />
                                </IntegrationBuilderStepDrawerFieldGrid>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                                <IntegrationBuilderSettingsAccordion titleContent={'Headers'}>
                                    <KeyValuePairFieldSet
                                        integrationBuilderProps={integrationBuilderProps}
                                        listOfKeyValuePairs={bearerTokenData?.bearerHeaders || []}
                                        isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                        handleAdditionalFieldProps={getAdditionalPropsForFieldSet(
                                            'bearer',
                                            'bearerHeaders',
                                        )}
                                        handleRemoveIndex={(index) => {
                                            handleInputChange({
                                                updateType: 'keyValuePair',
                                                fieldName: 'bearerHeaders',
                                                subfieldSet: 'bearer',
                                                keyValuePairUpdateType: 'fullUpdate',
                                                currentValue: handleKeyValuePairRemove(
                                                    bearerTokenData?.bearerHeaders || [],
                                                    index,
                                                ),
                                                index: index,
                                            });
                                        }}
                                        options={{ showLabels: true }}
                                    />
                                </IntegrationBuilderSettingsAccordion>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                                <IntegrationBuilderSettingsAccordion titleContent={'Query Parameters'}>
                                    <KeyValuePairFieldSet
                                        integrationBuilderProps={integrationBuilderProps}
                                        listOfKeyValuePairs={bearerTokenData?.bearerQueryParams || []}
                                        isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                        handleAdditionalFieldProps={getAdditionalPropsForFieldSet(
                                            'bearer',
                                            'bearerQueryParams',
                                        )}
                                        handleRemoveIndex={(index) => {
                                            handleInputChange({
                                                updateType: 'keyValuePair',
                                                fieldName: 'bearerQueryParams',
                                                subfieldSet: 'bearer',
                                                keyValuePairUpdateType: 'fullUpdate',
                                                currentValue: handleKeyValuePairRemove(
                                                    bearerTokenData?.bearerQueryParams || [],
                                                    index,
                                                ),
                                                index: index,
                                            });
                                        }}
                                        options={{ showLabels: true }}
                                    />
                                </IntegrationBuilderSettingsAccordion>
                            </div>
                            <InputLabel
                                sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                label={'Token Property Name'}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IntegrationBuilderStepDrawerFieldGrid
                                    label=""
                                    isRequired={true}
                                    style={{ ...styles.fieldGrid, width: '150px' }}
                                    integrationBuilderProps={integrationBuilderProps}
                                >
                                    <TextField
                                        name={'bearerMainTokenPropertyName'}
                                        value={bearerTokenData?.bearerMainTokenPropertyName || ''}
                                        type={'text'}
                                        label={null}
                                        isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                        required={false}
                                        renderWithGrid={false}
                                        placeholder={'access_token'}
                                        validate={{
                                            errorLabel: 'Token Property Name',
                                        }}
                                        inputProps={{
                                            onClick: () =>
                                                handleInputChange({
                                                    updateType: 'stringModal',
                                                    stringModalUpdateType: 'field',
                                                    fieldName: 'bearerMainTokenPropertyName',
                                                    subfieldSet: 'bearer',
                                                    currentValue: bearerTokenData?.bearerMainTokenPropertyName || '',
                                                }),
                                        }}
                                    />
                                </IntegrationBuilderStepDrawerFieldGrid>
                            </div>
                            <InputLabel
                                sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                label={'Bearer Authentication Type'}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IntegrationBuilderStepDrawerFieldGrid
                                    label=""
                                    isRequired={true}
                                    style={{ ...styles.fieldGrid, width: '150px' }}
                                    integrationBuilderProps={integrationBuilderProps}
                                >
                                    <SelectField
                                        items={bearerAuthorizationTypes}
                                        name={'bearerAuthorizationType'}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                        placeholder="Select Authorization Type"
                                        searchPlaceholder="Search Authorization Type"
                                        getItemId={(option) => option.id}
                                        getItemLabel={(option) => option.label}
                                        getItemValue={(option) => option.id}
                                        value={bearerTokenData?.bearerAuthorizationType || ''}
                                        onChange={(e) => {
                                            handleInputChange({
                                                updateType: 'field',
                                                subfieldSet: 'bearer',
                                                fieldName: e.target.name,
                                                currentValue: e.target.value,
                                            });
                                        }}
                                        disabled={!isUserAdmin}
                                    />
                                </IntegrationBuilderStepDrawerFieldGrid>
                            </div>
                            {bearerTokenData?.bearerAuthorizationType === 'oauth' && (
                                <>
                                    <InputLabel
                                        sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                        label={
                                            <>
                                                Client ID
                                                <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IntegrationBuilderStepDrawerFieldGrid
                                            label=""
                                            isRequired={true}
                                            style={{ ...styles.fieldGrid, width: '150px' }}
                                            integrationBuilderProps={integrationBuilderProps}
                                        >
                                            <TextField
                                                name={'bearerClientId'}
                                                value={bearerTokenData?.bearerClientId || ''}
                                                type={'text'}
                                                label={null}
                                                required={false}
                                                renderWithGrid={false}
                                                validate={{
                                                    errorLabel: 'Client ID',
                                                }}
                                                error={currentErrors?.['bearerClientId']}
                                                inputProps={{
                                                    onClick: () =>
                                                        handleInputChange({
                                                            updateType: 'stringModal',
                                                            stringModalUpdateType: 'field',
                                                            fieldName: 'bearerClientId',
                                                            subfieldSet: 'bearer',
                                                            currentValue: bearerTokenData?.bearerClientId || '',
                                                        }),
                                                }}
                                            />
                                        </IntegrationBuilderStepDrawerFieldGrid>
                                    </div>
                                    <InputLabel
                                        sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                        label={
                                            <>
                                                Client Secret
                                                <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IntegrationBuilderStepDrawerFieldGrid
                                            label=""
                                            isRequired={true}
                                            style={{ ...styles.fieldGrid, width: '150px' }}
                                            integrationBuilderProps={integrationBuilderProps}
                                        >
                                            <TextField
                                                name={'bearerClientSecret'}
                                                value={bearerTokenData?.bearerClientSecret || ''}
                                                type={'password'}
                                                label={null}
                                                required={false}
                                                renderWithGrid={false}
                                                validate={{
                                                    errorLabel: 'Client Secret',
                                                }}
                                                error={currentErrors?.['bearerClientSecret']}
                                                inputProps={{
                                                    onClick: () =>
                                                        handleInputChange({
                                                            updateType: 'stringModal',
                                                            stringModalUpdateType: 'field',
                                                            fieldName: 'bearerClientSecret',
                                                            subfieldSet: 'bearer',
                                                            currentValue: bearerTokenData?.bearerClientSecret || '',
                                                        }),
                                                }}
                                            />
                                        </IntegrationBuilderStepDrawerFieldGrid>
                                    </div>
                                    <InputLabel
                                        sx={{ ...styles.mappingLabel, marginTop: '5px' }}
                                        label={<>Audience</>}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IntegrationBuilderStepDrawerFieldGrid
                                            label=""
                                            isRequired={true}
                                            style={{ ...styles.fieldGrid, width: '150px' }}
                                            integrationBuilderProps={integrationBuilderProps}
                                        >
                                            <TextField
                                                name={'bearerAudience'}
                                                value={bearerTokenData?.bearerAudience || ''}
                                                type={'text'}
                                                label={null}
                                                required={false}
                                                renderWithGrid={false}
                                                validate={{
                                                    errorLabel: 'Audience',
                                                }}
                                                inputProps={{
                                                    onClick: () =>
                                                        handleInputChange({
                                                            updateType: 'stringModal',
                                                            stringModalUpdateType: 'field',
                                                            fieldName: 'bearerAudience',
                                                            subfieldSet: 'bearer',
                                                            currentValue: bearerTokenData?.bearerAudience || '',
                                                        }),
                                                }}
                                            />
                                        </IntegrationBuilderStepDrawerFieldGrid>
                                    </div>
                                </>
                            )}
                            {bearerTokenData?.bearerAuthorizationType === 'basic' && (
                                <>
                                    <InputLabel
                                        sx={styles.mappingLabel}
                                        label={
                                            <>
                                                Username<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <TextField
                                        name={'bearerUsername'}
                                        value={bearerTokenData?.bearerUsername || ''}
                                        type={'text'}
                                        onChange={() => {
                                            setRerender(rerender + 1);
                                        }}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        error={currentErrors?.['bearerUsername'] === true}
                                        validate={{
                                            errorLabel: 'Username',
                                        }}
                                        inputProps={{
                                            onClick: () => {
                                                handleInputChange({
                                                    updateType: 'stringModal',
                                                    stringModalUpdateType: 'field',
                                                    fieldName: 'bearerUsername',
                                                    subfieldSet: 'bearer',
                                                    currentValue: bearerTokenData?.bearerUsername || '',
                                                });

                                                setRerender(rerender + 1);
                                            },
                                        }}
                                    />
                                    <InputLabel
                                        sx={styles.mappingLabel}
                                        label={
                                            <>
                                                Password<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <TextField
                                        name={'bearerPassword'}
                                        value={bearerTokenData?.bearerPassword || ''}
                                        type={'password'}
                                        onChange={() => {
                                            setRerender(rerender + 1);
                                        }}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        error={currentErrors?.['bearerPassword'] === true}
                                        validate={{
                                            errorLabel: 'Password',
                                        }}
                                        inputProps={{
                                            onClick: () => {
                                                handleInputChange({
                                                    updateType: 'stringModal',
                                                    stringModalUpdateType: 'field',
                                                    fieldName: 'bearerPassword',
                                                    subfieldSet: 'bearer',
                                                    currentValue: bearerTokenData?.bearerPassword || '',
                                                });

                                                setRerender(rerender + 1);
                                            },
                                        }}
                                    />
                                </>
                            )}
                            {bearerTokenData?.bearerAuthorizationType === 'apiKey' && (
                                <>
                                    <InputLabel
                                        sx={styles.mappingLabel}
                                        label={
                                            <>
                                                Location<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <SelectField
                                        items={[
                                            { label: 'None', value: '' },
                                            { label: 'Query Parameters', value: 'queryParams' },
                                            { label: 'Headers', value: 'headers' },
                                        ]}
                                        name={'bearerKeyLocation'}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        getItemId={(option) => option.value}
                                        getItemLabel={(option) => option.label}
                                        getItemValue={(option) => option.value}
                                        value={bearerTokenData?.['bearerKeyLocation'] || ''}
                                        error={currentErrors?.['bearerKeyLocation'] === true}
                                        onChange={(e) => {
                                            handleInputChange({
                                                updateType: 'field',
                                                fieldName: e.target.name,
                                                currentValue: e.target.value,
                                                subfieldSet: 'bearer',
                                            });

                                            setRerender(rerender + 1);
                                        }}
                                        disabled={!isUserAdmin}
                                    />
                                    <InputLabel
                                        sx={styles.mappingLabel}
                                        label={
                                            <>
                                                Field Name<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <TextField
                                        name={'bearerHeaderField'}
                                        value={bearerTokenData?.bearerHeaderField || ''}
                                        type={'text'}
                                        onChange={() => {
                                            setRerender(rerender + 1);
                                        }}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        error={currentErrors?.['bearerHeaderField'] === true}
                                        validate={{
                                            errorLabel: 'Field Name',
                                        }}
                                        inputProps={{
                                            onClick: () => {
                                                handleInputChange({
                                                    updateType: 'stringModal',
                                                    stringModalUpdateType: 'field',
                                                    fieldName: 'bearerHeaderField',
                                                    subfieldSet: 'bearer',
                                                    currentValue: bearerTokenData?.bearerHeaderField || '',
                                                });

                                                setRerender(rerender + 1);
                                            },
                                        }}
                                    />
                                    <InputLabel
                                        sx={styles.mappingLabel}
                                        label={
                                            <>
                                                API Key<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <TextField
                                        name={'bearerApiKey'}
                                        value={bearerTokenData?.bearerApiKey || ''}
                                        type={'password'}
                                        onChange={() => {
                                            setRerender(rerender + 1);
                                        }}
                                        label={null}
                                        required={false}
                                        renderWithGrid={false}
                                        error={currentErrors?.['bearerApiKey'] === true}
                                        validate={{
                                            errorLabel: 'API Key',
                                        }}
                                        inputProps={{
                                            onClick: () => {
                                                handleInputChange({
                                                    updateType: 'stringModal',
                                                    stringModalUpdateType: 'field',
                                                    fieldName: 'bearerApiKey',
                                                    subfieldSet: 'bearer',
                                                    currentValue: bearerTokenData?.bearerApiKey || '',
                                                });

                                                setRerender(rerender + 1);
                                            },
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

IntegrationBuilderAuthorizationFields.propTypes = {
    integrationBuilderProps: PropTypes.object.isRequired,
    authorizationConfig: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func,
    dataBeingUpdated: PropTypes.object,
};

export default memo(IntegrationBuilderAuthorizationFields);
