export const utilsstyling = {
    //createintegrationpopup.js
    dialogbox1: {
        '& .MuiDialogActions-root': {
            padding: '20px 0',
        },
    },
    createintegrationpopupdialogbox: {
        '& .css-yk16xz-control:hover': { borderColor: '#3a70d4' },
        '& .css-1okebmr-indicatorSeparator': {
            display: 'none',
        },
        '& .css-tlfecz-indicatorContainer': {
            color: '#3a70d4',
        },

        '& .MuiDialogActions-root': {
            justifyContent: 'space-between',
            padding: '20px 0',
            '& .MuiButtonBase-root': {
                fontSize: '0.875rem',
                fontWeight: 600,
                textTransform: 'capitalize',
            },
            '& .MuiButtonBase-root:last-child': {
                background: '#3a70d4',
                color: '#fff',
                border: '1px solid #3a70d4',
                '&:hover': {
                    background: '#fff',
                    color: '#3a70d4',
                    border: '1px solid #3a70d4',
                },
                '&:disabled': {
                    background: '#cfcfcf',
                    color: '#fff',
                    border: '1px solid #cfcfcf',
                },
            },
        },
        '& .MuiDialogContent-root': { padding: 30, paddingBottom: 0 },
        '& .MuiInputBase-root': {
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#3a70d4' },
            },
        },
        '& .MuiTypography-root': {
            color: '#545454',
            fontWeight: 600,
            fontSize: '0.813rem',
            paddingTop: '1rem',
            paddingBottom: '0.5rem',
            '& span': { color: 'red' },
        },
    },
    createIntegrationTextInput: {
        padding: '10px 14px',
        color: '#000',
        '&::placeholder': {
            color: '#C4C8D2',
            opacity: 1,
        },
        fontSize: '0.813rem',
        fontWeight: 400,
    },
    createIntegrationMultilineTextInput: {
        // padding: '10px 14px',
        color: '#000',
        '&::placeholder': {
            color: '#C4C8D2',
            opacity: 1,
        },
        fontSize: '0.813rem',
        fontWeight: 400,
    },
    logoutbuttons: {
        justifyContent: 'space-around',
    },
    nobtn: {
        color: 'red',
        border: '1px solid red',
        padding: '5px',
        '&:hover': {
            color: '#fff',
            border: '1px solid #fff',
            background: 'red',
        },
    },
    //PopupModal.js
    popupmodaldilaogbox: {
        '& .MuiPaper-root': {
            minWidth: 600,
            minHeight: 355,
            borderRadius: 20,
            textAlign: 'center',
        },
    },
    typo1: {
        color: '#000',
        fontSize: '1.5625rem',
        fontWeight: '700',
        margin: '10px 0',
    },
    maintypo: {
        color: '#000',
        fontSize: '1rem',
        fontWeight: 'bold',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconnecttxt: {
        color: '#3A70D4',
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingLeft: 3,
        marginBottom: 1,
    },
    popupmodaltypo2: {
        color: '#000',
        fontSize: '1rem',
        fontWeight: '400',
    },
    typo3: {
        color: '#000',
        fontSize: '1rem',
        fontWeight: '400',
    },
    popupmodalsubmit: {
        backgroundColor: '#3a70d4',
        color: '#fff',
        padding: '6px 30px',
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#3a70d4',
            opacity: '0.8',
        },
    },
};
