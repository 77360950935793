const labelStyle = {
    fontSize: '14px',
    marginRight: '15px',
    color: '#000',
};

export default {
    root: {
        display: 'flex',
        width: '100%',
        border: '1px solid #414141',
        flexDirection: 'column',
        margin: '25px 0px 0px 8px',
        paddingBottom: '10px',
        borderRadius: '4px',
    },
    requestBodyTitle: {
        background: '#414141',
        color: '#fff',
        fontSize: '13px',
        padding: '9px 10px',
        textTransform: 'uppercase',
        marginBottom: '20px',
        marginTop: 0,
    },
    valueTypeLabel: {
        ...labelStyle,
        flex: 0.3,
    },
    mappingLabel: {
        ...labelStyle,
        textTransform: 'capitalize',
    },
    stepOutputLabel: {
        ...labelStyle,
        flex: 0.3,
    },
    delimiterLabel: {
        ...labelStyle,
        width: '125px',
    },
    outputDelimiterLabel: {
        ...labelStyle,
        width: '200px',
        textTransform: 'uppercase',
    },
    requestBodyField: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0px 10px',
    },
    inputMappingWrapper: {
        width: '100%',
        padding: '0px 10px',
        margin: '30px 0px',
    },
    intputOptionFields: {
        marginTop: '50px',
    },
    fieldGrid: {
        flex: 0.8,
        flexGrow: 1,
    },
    inputMappingField: {
        flexGrow: 1,
        marginTop: '10px',
    },
    marginBottom: {
        marginBottom: '5px',
    },
    mappingInputTitle: {
        color: '#000',
        textTransform: 'uppercase',
        marginBottom: '0px',
        fontSize: '13px',
    },
    mappingWriteToFileTitle: {
        color: '#000',
        textTransform: 'uppercase',
        marginBottom: '0px',
        fontSize: '13px',
        display: 'inline',
        marginRight: '40px',
    },
    mappingIncludeHeadersTitle: {
        color: '#000',
        textTransform: 'uppercase',
        marginBottom: '0px',
        fontSize: '13px',
        display: 'inline',
        marginRight: '15px',
    },
    requestBodyTextWrapper: {
        outline: 'none',
        padding: '0px 10px',
        marginBottom: '10px',
    },
    inputFieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '8px 0px',
    },
    inputLabel: {
        flex: 'initial',
        width: '142px',
        display: 'flex',
        marginRight: '5px',
    },
    inputGrid: {
        marginTop: '10px',
        flexGrow: 1,
    },
    inputField: {
        background: '#fff',
        borderRadius: '8px',
    },
    normalizeLabel: {
        display: 'flex',
    },
};
