export default {
    toggleObjectWrapper: {
        width: '100%',
    },
    arrow: {
        '& svg': {
            marginLeft: '10px',
            fontSize: '18px',
            color: '#fff',
        },
    },
    pre: {
        margin: 0,
        background: '#4f4f4f',
        color: '#fff',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    colon: { paddingRight: '5px' },
    time: { color: '#d4d4d4' },
    wrapText: { wordWrap: 'break-word' },
};
