import SvgIcon from '@mui/material/SvgIcon';

const StepLog = (props) => {
    return (
        <SvgIcon width="13" height="16" viewBox="0 0 13 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.90002 8.67857L3.90002 9.83571C3.90002 9.93214 3.97034 10.0286 4.08752 10.0286L9.71252 10.0286C9.80627 10.0286 9.90002 9.93214 9.90002 9.83572L9.90002 8.67857C9.90002 8.55804 9.80627 8.48571 9.71252 8.48571L4.08752 8.48571C3.97034 8.48571 3.90002 8.55804 3.90002 8.67857ZM3.90002 10.9929L3.90002 12.15C3.90002 12.2464 3.97034 12.3429 4.08752 12.3429L9.71252 12.3429C9.80627 12.3429 9.90002 12.2464 9.90002 12.15L9.90002 10.9929C9.90002 10.8723 9.80627 10.8 9.71252 10.8L4.08752 10.8C3.97034 10.8 3.90002 10.8723 3.90002 10.9929ZM3.90002 7.13571L3.90002 7.52143C3.90002 7.61786 3.97034 7.71429 4.08752 7.71429L9.71252 7.71429C9.80627 7.71429 9.90002 7.61786 9.90002 7.52143L9.90002 7.13571C9.90002 7.01518 9.80627 6.94286 9.71252 6.94286L4.08752 6.94286C3.97034 6.94286 3.90002 7.01518 3.90002 7.13571ZM12.9 14.2714L12.9 1.15714C12.9 0.50625 12.3844 -2.25387e-08 11.775 -4.91753e-08L2.02502 -4.75361e-07C1.39221 -5.03022e-07 0.900024 0.506249 0.900024 1.15714L0.900024 14.2714C0.900024 14.8982 1.39221 15.4286 2.02502 15.4286L11.775 15.4286C12.3844 15.4286 12.9 14.8982 12.9 14.2714ZM2.02502 14.2714L2.02502 1.15714L11.775 1.15714L11.775 14.2714L2.02502 14.2714ZM3.90002 3.27857L3.90002 4.43571C3.90002 4.53214 3.97034 4.62857 4.08752 4.62857L9.71252 4.62857C9.80627 4.62857 9.90002 4.53214 9.90002 4.43571L9.90002 3.27857C9.90002 3.15804 9.80627 3.08571 9.71252 3.08571L4.08752 3.08571C3.97034 3.08571 3.90002 3.15804 3.90002 3.27857ZM3.90002 5.59286L3.90002 5.97857C3.90002 6.075 3.97034 6.17143 4.08752 6.17143L9.71252 6.17143C9.80627 6.17143 9.90002 6.075 9.90002 5.97857L9.90002 5.59286C9.90002 5.47232 9.80627 5.4 9.71252 5.4L4.08752 5.4C3.97034 5.4 3.90002 5.47232 3.90002 5.59286Z"
                fill="#000"
            />
        </SvgIcon>
    );
};
export default StepLog;
