import { useStoreValue, setStoreState, setStoreStates, getStoreState } from './useStoreStateHook';

export const storeNamespace = 'appInstances';

/**
 * App Instance Registration State
 */
export const appInstancesStates = {
    listOfApps: [],
    listOfInstances: [],
    selectedApp: {},
    isAppInstancesReady: false,
    selectedInstance: {},
    totalAppInstances: 0,
    appInstanceWebhooks: [],
    recentlyUpdatedAppInstanceId: '',
    recentlyUpdatedAppInstanceWebhookId: '',

    /**
     * Data Table states
     */
    currentAppInstancePage: 1,
    shouldReloadAppInstances: false,
};

export const resetAppInstanceStates = (states = {}) => {
    setStoreStates({
        // listOfApps: [],
        // listOfInstances: [],
        // totalAppInstances: 0,
        // isAppInstancesReady: false,
        selectedApp: {},
        selectedInstance: {},
        appInstanceWebhooks: [],
        recentlyUpdatedAppInstanceId: '',
        recentlyUpdatedAppInstanceWebhookId: '',
        ...states,
    });
};

/**
 * Set app instances state values
 * @param {Array<Object>} instances
 */
export const setAppInstancesList = (instances) => setStoreState('listOfInstances')(instances);

/**
 * Set total app instances state values
 * @param {Number} count
 */
export const setTotalAppInstances = (count) => setStoreState('totalAppInstances')(count);

/**
 * Set app instance states
 * @param {Object} args
 * @param {Array<object>} args.instances
 * @param {Number} args.totalInstances
 * @param {Bool} args.isAppInstancesReady
 */
export const setAppInstanceStates = ({ instances, totalInstances, isAppInstancesReady = false }) => {
    const states = { isAppInstancesReady };

    if (totalInstances !== undefined) {
        states.totalAppInstances = totalInstances;
    }
    if (instances !== undefined) {
        states.listOfInstances = instances;
    }

    setStoreStates(states);
};

/**
 * Get apps instances state values
 * @returns {Array<Object>} Apps instances state values
 */
export const getAppInstancesList = () => getStoreState('listOfInstances', storeNamespace)([]);

/**
 * Get app instances state values and subscribe to changes
 * @returns {Array<Object>} App Instances state values
 */
export const useAppInstancesList = () => useStoreValue('listOfInstances', storeNamespace)([]);

/**
 * Get total app instances state values and subscribe to changes
 * @returns {Number} Total app Instances state values
 */
export const useTotalAppInstances = () => useStoreValue('totalAppInstances', storeNamespace)([]);

/**
 * Get app instances ready state value and subscribe to changes
 * @returns {Number} Total app Instances state values
 */
export const useIsAppInstancesReady = () => useStoreValue('isAppInstancesReady', storeNamespace)([]);

/**
 * Set apps state values
 * @param {Array<Object>} apps
 */
export const setAppsList = (apps) => setStoreState('listOfApps')(apps);

/**
 * Get apps state values
 * @returns {Array<Object>} Apps state values
 */
export const getAppsList = () => getStoreState('listOfApps', storeNamespace)([]);

/**
 * Get apps state values and subscribe to changes
 * @returns {Array<Object>} Apps state values
 */
export const useAppsList = () => useStoreValue('listOfApps', storeNamespace)([]);

/**
 * Set currently selected app type
 * @param {Object} app
 */
export const setSelectedApp = (app) => setStoreState('selectedApp')(app);

/**
 * Find and set selected app using appId
 * @param {String} appId
 */
export const setSelectedAppWithId = (appId) => {
    const app = getAppsList().find((app) => app._id === appId);
    setSelectedApp(app);
};

/**
 * Get current selected app and subscribe to changes
 * @returns {Object} Selected App state value
 */
export const useSelectedApp = () => useStoreValue('selectedApp', storeNamespace)({});

/**
 * Get recent updated app instance webhook ID and subscribe to changes
 * @returns {string} Recently updated app instance webhook ID
 */
export const useRecentlyUpdatedAppInstanceWebhookId = () =>
    useStoreValue('recentlyUpdatedAppInstanceWebhookId', storeNamespace)([]);

/**
 * Get recent updated app instance ID and subscribe to changes
 * @returns {string} Recently updated app instance ID
 */
export const useRecentlyUpdatedAppInstanceId = () => useStoreValue('recentlyUpdatedAppInstanceId', storeNamespace)([]);

/**
 * Get current selected app instance webhooks and subscribe to changes
 * @returns {Array<object>} Selected App instance webhook state value
 */
export const useSelectedAppInstanceWebhooks = () => useStoreValue('appInstanceWebhooks', storeNamespace)([]);

/**
 * Set app instance webhooks state value
 * @param {Array<object>} webhooks
 */
export const setSelectedAppInstanceWebhooks = (webhooks) => setStoreState('appInstanceWebhooks')(webhooks);

/**
 * Set instance state value
 * @param {Object} instance
 */
export const setSelectedInstance = (instance) => setStoreState('selectedInstance')(instance);

/**
 * Get current selected instance and subscribe to changes
 * @returns {Object} Selected instance state value
 */
export const useSelectedInstance = () => useStoreValue('selectedInstance', storeNamespace)({});

/**
 * Set current page of app instances data table
 * @param {Integer} instance
 */
export const setAppInstancesCurrentPage = (currPage) => setStoreState('currentAppInstancePage')(currPage);

/**
 * Get current page of app instance and subscribe to changes
 * @returns {Integer} Selected instance state value
 */
export const useAppInstancesCurrentPage = () => useStoreValue('currentAppInstancePage', storeNamespace)({});

/**
 * Set current page of app instances data table
 * @param {Boolean} instance
 */
export const setAppInstancesShouldReload = (shouldReload) => setStoreState('shouldReloadAppInstances')(shouldReload);

/**
 * Get current page of app instance and subscribe to changes
 * @returns {Boolean} Selected instance state value
 */
export const useAppInstancesShouldReload = () => useStoreValue('shouldReloadAppInstances', storeNamespace)(false);

// export default useAppInstances;
