import { useEffect } from 'react';

import { getAuthUserData } from './useUserHook';

import {
    SESSION_TOKEN_REFRESH_MINIMUM_WAIT_THRESHOLD,
    SESSION_TOKEN_REFRESH_ENABLE_ON_USER_ACTIVITY,
} from '../utils/constants';

import {
    refreshUserToken,
    isSessionIdlePopupShown,
    getUserLastActivityTime,
    updateUserLastActivityTime,
} from '../utils/loginUtils';

/**
 * Bind a listener which will monitor for user activity and refresh
 * the user's session to prevent expiration.
 *
 * @param {Bool} isUserLoggedIn Specify whether the user is logged in or not.
 * Used to control whether to monitor user activity or not.
 */
export const useSessionRefreshBasedOnUserActivity = (isUserLoggedIn) => {
    const runSessionTokenAutoUpdateHandler = async () => {
        // If the session token expiration popup is shown,
        // disabled user activity check
        if (!isSessionIdlePopupShown()) {
            const lastActivityTime = getUserLastActivityTime(),
                sessionExpiredAt = getAuthUserData().sessionExpiredAt * 1000;

            if (lastActivityTime && lastActivityTime > 0) {
                const sessionExpireOffsetByWaitThreshold =
                    sessionExpiredAt - SESSION_TOKEN_REFRESH_MINIMUM_WAIT_THRESHOLD;

                // Purposefully left these debug statements for future diagnosis
                // console.log('mouse: lastActivityTime', lastActivityTime);
                // console.log('mouse: sessionExpiredAt', sessionExpiredAt);
                // console.log('mouse: sessionExpireOffsetByWaitThreshold', sessionExpireOffsetByWaitThreshold);
                // console.log(
                //     'mouse: sessionExpireOffsetByWaitThreshold - lastActivityTime',
                //     sessionExpireOffsetByWaitThreshold - lastActivityTime,
                // );

                if (sessionExpireOffsetByWaitThreshold <= lastActivityTime) {
                    await refreshUserToken({
                        error: true,
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (isUserLoggedIn && SESSION_TOKEN_REFRESH_ENABLE_ON_USER_ACTIVITY) {
            monitorUserMouseInput(runSessionTokenAutoUpdateHandler);
        }
    }, [isUserLoggedIn]);
};

/**
 * Monitor the user mouse input for activity.
 *
 * @param {Function} callback A callback to fire once activity is detected
 */
export const monitorUserMouseInput = (callback) => {
    const appBodyNode = document.querySelector('body');
    let timeoutHandle = null;

    appBodyNode.onmousemove = async (event) => {
        // Verify if event is from the browser's screen
        if (event?.isTrusted && event?.screenX && event.screenY && callback) {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }

            timeoutHandle = setTimeout(async () => {
                updateUserLastActivityTime();
                await callback(event);
            }, 500);
        }
    };
};
