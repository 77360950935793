import { combineReducers } from 'redux';
import modals from './modals.reducer';
import myApps from './myApps.reducer';
import myDataTypes from './myDataTypes.reducer';
import crosswalks from './crosswalks.reducer';
import comparisons from './comparisons.reducer';
import integrationExecutions from './integrationsExecutions.reducer';
import loading from './loading.reducer';
import feedback from './snackbar.reducer';
import userData from './userData.reducer';
import appSchemas from './schemas.reducer';
import storeStates from './storeStates.reducer';
import rosettaMappings from './rosettaMappings.reducer';
import appInstances from './appInstances.reducer';
import globalDragDrop from './globalDragDrop.reducer';
import appSubscriptions from './appSubscriptions.reducer';
import integrationsData from './integrationsData.reducer';
import vendorRegistration from './vendorRegistration.reducer';
import dashboardActiveTab from './dashboardActiveTab.reducer';
import authUserSessionToken from './authUserSessionToken.reducer';
import currentOrganization from './currentOrganization.reducer';
import integrationBuilder, { transformComponentReducer } from './integrationBuilder.reducer';
import myConnections from './myConnections.reducer';
import loadingOverlay from './displayOverlay.reducer';

const reducers = combineReducers({
    modals,
    myApps,
    loading,
    feedback,
    crosswalks,
    comparisons,
    integrationExecutions,
    myConnections,
    loadingOverlay,
    appSchemas,
    storeStates,
    myDataTypes,
    appInstances,
    globalDragDrop,
    rosettaMappings,
    integrationsData,
    appSubscriptions,
    dashboardActiveTab,
    integrationBuilder,
    vendorRegistration,
    authUserSessionToken,
    userdata: userData,
    currentOrganization,
    transformComponentPan: transformComponentReducer,
});

export default reducers;
