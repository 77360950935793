// __webpack_nonce__ is a magic var set so webpack is in the know about the nonce value and can use it
__webpack_nonce__ = window.nonce; // eslint-disable-line
window.__webpack_nonce__ = window.nonce;

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

import ThemeProvider from './theme';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
