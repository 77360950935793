export const appstlying = {
    Approot: {
        background: '#fff',
        // height: '100vh',
        // width: '100%',
    },
    appcontainer: {
        // height: '100%',
        // overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 1,
        },
    },
};
