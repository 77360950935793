/**
 * Get a property value from an object.
 *
 * @param {Object} object The object to retrieve property values from.
 * @param {String} prop The property to retrieve from the object.
 * Note: property names can be chained like so: 'user.data.email' which will
 * loop through all the properties 'user' -> 'data' -> 'email' to get the value.
 * There's no limit to the number of properties you can chain.
 *
 * @return {*} The property value.
 */
export const getPropFromObject = (obj, prop) => {
    if (!obj) return undefined;

    let foundData = null;
    const args = prop.split('.');

    for (const arg of args) {
        if (!foundData) {
            foundData = obj?.[arg];
        } else {
            foundData = foundData?.[arg];
        }
    }

    return foundData;
};

/**
 * Create object from props.
 *
 * @param (string) prop The chainable properties.
 * For example: 'user.posts.title'.
 * @param {*} value The value to assign to the last property.
 * Default to empty object.
 *
 * @returns {Object} The created object from given props.
 */
export const createObjectFromChainableProps = (prop, value = {}) => {
    const objRef = {},
        keys = prop.split('.'),
        firstKey = keys[0],
        lastKey = keys[keys.length - 1];

    const createObjectProps = (target = {}) => {
        const key = keys.shift();

        target[key] = key === lastKey ? value : {};

        // Track object prop
        objRef[key] = target;

        if (!keys.length) {
            return objRef[firstKey];
        }

        return createObjectProps(target[key]);
    };

    return createObjectProps();
};

/**
 * Sort Array object by property
 * @param {Array<object>} arrayObj Array object to sort
 * @param {String} prop Property in object to sort by
 * @return {Object} sorted object
 */
export const sortObjectByProp = (arrayObj, prop) => {
    const arrays = [...arrayObj];
    arrays.sort((a, b) => (a?.[prop]?.toLowerCase() < b?.[prop]?.toLowerCase() ? -1 : 0));
    return arrays;
};
