import {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
    processResponse,
} from './dataRequestUtils';

const ignoreErrorCodes = [404];

/**
 * Get all comparisons
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getAllComparisons = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `rosetta/comparisons?pageNo=${null}`,
        data: {
            sorts: JSON.stringify({ name: 1 }),
        },
        ...processResponse({
            ignoreErrorCodes,
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to fetch comparisons.',
        }),
    });

/**
 * Get comparisons for a given page
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getComparisons = ({
    pageNo = 1,
    searchText = '',
    sorts = { name: 1 },
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: 'rosetta/comparisons',
        data: {
            pageNo,
            searchText,
            sorts: JSON.stringify(sorts),
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to fetch comparisons.',
        }),
    });

/**
 * Get comparison by id
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const getComparisonById = ({ comparisonId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `rosetta/comparison/${comparisonId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to fetch comparison.',
        }),
    });

/**
 * Create comparison
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const createComparison = ({ data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: 'rosetta/comparison',
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            sucessMessage: 'Comparison created sucessfully',
            errorMessage: 'Failed to create comparison.',
        }),
    });

/**
 * Update comparison
 * @param {Object} args
 * @returns {Promise<any>} response
 * @note This update method is only used for updating comparison details except for inputs and comparisons.
 *      For updating inputs and comparisons, use updateComparisonInputs and updateComparisonComparisons respectively.
 */
export const updateComparison = ({ id, data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `rosetta/comparison/${id}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Comparison updated sucessfully.',
            errorMessage: 'Failed to update comparison.',
        }),
    });

/**
 * Update comparison inputs
 * @param {Object} args
 * @returns {Promise<any>} response
 * @note This update method is only used for updating comparison inputs.
 */
export const updateComparisonInputs = ({ id, inputs, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `rosetta/comparisons/${id}/inputs`,
        inputs,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Failed to update comparison inputs.',
        }),
    });

/**
 * Update comparison comparisons
 * @param {Object} args
 * @returns {Promise<any>} response
 * @note This update method is only used for updating comparison comparisons.
 */
export const updateComparisonComparisons = ({
    id,
    comparisons,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPatchRequest({
        url: `rosetta/comparisons/${id}/comparisons`,
        comparisons,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Comparisons updated successfully.',
            errorMessage: 'Failed to update comparison comparisons.',
        }),
    });

/**
 * Delete comparison
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const deleteComparison = ({ comparisonId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendDeleteRequest({
        url: `rosetta/comparison/${comparisonId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Comparison deleted successfully.',
            errorMessage: 'Failed to delete comparison.',
        }),
    });

/**
 * Copy comparison
 * @param {Object} args
 * @returns {Promise<any>} response
 */
export const copyComparison = ({
    comparisonId,
    comparisonName,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPostRequest({
        url: `rosetta/comparison/${comparisonId}/copy`,
        data: {
            name: comparisonName,
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            successMessage: 'Comparison copied successfully.',
            errorMessage: 'Failed to copy comparison.',
        }),
    });
