// FunctionParser.js
import * as fns from './CustomFunctions';

const specialCharacters = [
    '.',
    '\\',
    '/',
    '+',
    '*',
    '?',
    '[',
    '^',
    ']',
    '$',
    '(',
    ')',
    '{',
    '}',
    '=',
    '!',
    '<',
    '>',
    '|',
    ':',
    ' ',
];

class Node {
    constructor(value) {
        this.value = value;
        this.parent = null;
        this.isFunction = false;
        this.children = [];
    }

    addChild(node) {
        node.parent = this;
        this.children[this.children.length] = node;
    }
}

/**
 * @typedef FunctionParser
 */
export default class FunctionParser {
    #str;

    constructor(str) {
        this.#str = str;
        this.fieldValues = [];
        this.fieldInputs = [];
        this.fieldCount = 0;
        this.errors = [];
        this.root = this.makeTree(str);
        this.dict = {
            field: fns.field,
            sum: fns.sum,
            concat: fns.concat,
            trim: fns.trim,
            substring: fns.substring,
            split: fns.split,
            currentDateTime: fns.currentDateTime,
            formatDateTime: fns.formatDateTime,
            base64ImgToBuffer: fns.base64ImgToBuffer,
            concatArray: fns.concatArray,
            hashString: fns.hashString,
            addIntervalToDateTime: fns.addIntervalToDateTime,
            subtractIntervalFromDateTime: fns.subtractIntervalFromDateTime,
            randomInteger: fns.randomInteger,
        };
    }

    getStr() {
        return this.#str;
    }

    /**
     * Create tree from function string, return root of tree
     * @param {String} str
     * @returns {Node} root
     */
    makeTree(str) {
        let n = 0; // Tree level
        let j = 0; // Start of expression
        let i = 0; // String index
        let root = new Object();
        let parent = new Object();
        while (i < str.length + 1 && this.errors.length === 0) {
            if (i === 0 && str[i] === '$') {
                let x = i + 1;
                while (x < str.length) {
                    if (specialCharacters.includes(str[x])) {
                        this.errors.push({ message: 'Variables can not contain special characters', type: 'syntax' });
                        return;
                    }
                    x++;
                }
                root = new Node(str.substring(i, x));
                parent = root;
            }
            if (str[i] === '(' && i > 0) {
                if (j === 0) {
                    // Set root node
                    root = new Node(str.substring(j, i));
                    root.isFunction = true;
                    parent = root;
                } else {
                    // Add to parent node
                    const child = new Node(str.substring(j, i));
                    child.isFunction = true;
                    parent.addChild(child);
                    parent = child;
                }
                j = i + 1;
                n++;
            }
            if (str[i] === ')') {
                // handle value child
                n--;
                if (str[i - 1] !== ')' && i !== j && str.substring(j, i).trim() !== '') {
                    const child = new Node(str.substring(j, i));
                    parent.addChild(child);
                }
                j = i + 1;
                // Check for extra parenthesis
                if (parent !== null) {
                    parent = parent.parent;
                } else {
                    this.errors.push({ message: "Syntax error: Too many closing parenthesis ')'", type: 'syntax' });
                    return null;
                }
            }
            if (str[i] === ',') {
                // handle value child
                if (str[i - 1] !== ')' && str.substring(j, i).trim() !== '') {
                    const child = new Node(str.substring(j, i));
                    parent.addChild(child);
                }
                j = i + 1;
            }
            if (str[i] === '"') {
                let x = i + 1;
                let y = 0;
                while (x < str.length) {
                    if (str[x] === '"') {
                        y = x;
                        x = str.length + 2;
                    }
                    x++;
                }
                if (x === str.length) {
                    this.errors.push({ message: 'Expected "', type: 'syntax' });
                } else {
                    const child = new Node(str.substring(i + 1, y));
                    parent.addChild(child);
                    i = y;
                    j = i + 1;
                    // m++;
                }
            }
            i++;
        }
        if (n > 0) {
            this.errors.push({ message: "Syntax error: Missing closing parenthesis ')' ", type: 'syntax' });
        }

        if (this.errors.length > 0) {
            return null;
        }
        return root;
    }

    // evaluate single node of tree, return value, assumes children are evaluated already
    evalNode(node) {
        let val;
        const children = node.children.map((elm) => elm.value);

        if (node.isFunction) {
            const functionValue = node.value.trim();
            if (functionValue in this.dict) {
                if (functionValue === 'field') {
                    val = fns.field(this.fieldValues.shift());
                } else if (functionValue === 'currentDateTime') {
                    if (node.children.length > 0) {
                        return { message: 'currentDateTime() does not take any parameters.', type: 'error' };
                    }
                    val = fns.currentDateTime();
                } else {
                    val = this.dict[functionValue](children);
                }
            } else {
                return { message: functionValue + ' is not a function.', type: 'error' };
            }
        } else {
            val = node.value.trim();
        }

        return val;
    }

    // Traverses the tree and returns final result
    evalFunction(fieldValues) {
        const fieldArr = fieldValues.map((x) => x.inputValue);
        if (fieldArr.length !== this.fieldCount) {
            return { value: null, errors: [{ message: 'Incorrect number of field inputs', type: 'error' }] };
        } else {
            this.fieldValues = fieldArr;
            return this.treeTravlersalHelperEvaluate(this.root);
        }
    }

    treeTravlersalHelperEvaluate(node) {
        let res = '';
        if (node !== null) {
            if (node.children) {
                for (const child of node.children) {
                    this.treeTravlersalHelperEvaluate(child);
                }
            }
            res = this.evalNode(node);
            if (res.message) {
                this.errors.push(res);
                node.value = null;
            } else {
                node.value = res;
            }
        }
        if (node.parent === null) {
            if (this.errors.length > 0) {
                return { value: node.value, errors: this.errors };
            } else {
                return { value: node.value };
            }
        }
    }

    getFieldInputs() {
        if (this.errors.length > 0) {
            throw new Error(this.errors[0].message);
        }
        // If fieldInputs have not been generated
        if (this.fieldInputs.length > 0) {
            return this.fieldInputs;
        } else {
            return this.treeTravlersalHelperFieldInputs(this.root);
        }
    }

    treeTravlersalHelperFieldInputs(node) {
        if (node !== null) {
            if (node.children) {
                for (const child of node.children) {
                    this.treeTravlersalHelperFieldInputs(child);
                }
            }
            if (node.value.trim() === 'field') {
                this.fieldCount++;
                this.fieldInputs.push(this.parseFieldId(node.children[0].value.trim()));
            }
            if (node.value.trim().includes('$')) {
                this.fieldCount++;
                this.fieldInputs.push({
                    variableName: node.value.trim(),
                });
            }
        }
        if (node !== null && node.parent === null) {
            return this.fieldInputs;
        }
    }

    // parses fieldId string into object
    parseFieldId(idStr) {
        const arr = idStr.split('.');
        const idObj = { inputId: arr[0], inputPath: [] };
        for (let i = 1; i < arr.length; i++) {
            let id = arr[i];
            // check occurrence
            let occ;
            if (id[id.length - 1] === ']') {
                occ = Number(id.substring(id.indexOf('[') + 1, id.indexOf(']')));
                id = id.substring(0, id.indexOf('['));
            } else {
                occ = 0;
            }
            // check position index
            let pos;
            if (id.indexOf('{') !== -1) {
                pos = Number(id.substring(id.indexOf('{') + 1, id.indexOf('}')));
                id = id.substring(0, id.indexOf('{'));
            } else {
                pos = 0;
            }
            idObj.inputPath.push({ dataTypeFieldId: id, occurrenceIndex: occ, positionIndex: pos });
        }
        return idObj;
    }
}
