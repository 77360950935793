import * as constants from '../utils/constants';
import { setSchemas } from './useSchemasHook';
import { getMyAppsList } from './useMyAppsHook';
import { getAllDataTypes } from '../utils/rosettaDataRequestUtils';
import { setMappingDataTypes } from './useMappingsHook';
import { getIntegrationsList } from './useIntegrationsHook';
import { setAppInstanceStates } from './useAppInstancesHook';
import { getOrganizationAppInstances } from '../utils/appInstancesDataRequestUtils';
import { getAndSetAllComparisonsStateWithDataRequest } from './useComparisonsHook';

/**
 * Dispatch request concurrently immediately after user logged in.
 * Uses Promise.all() to dispatch requests.
 */
export const prepareAppDataGlobally = () => {
    Promise.all([
        /**
         * All Datatypes
         */
        getAllDataTypes({
            pageNo: null,
            successCallback: (res) => {
                const { results = [] } = res?.data || {};
                setSchemas(results);
                setMappingDataTypes(results);
            },
        }),

        /**
         * App Instances
         */
        getOrganizationAppInstances({
            pageNo: 1,
            successCallback: (res) => {
                setAppInstanceStates({
                    instances: res.data?.results || [],
                    totalInstances: res.data?.totalNumberOfResults || 0,
                    isAppInstancesReady: true,
                });
            },
            errorCallback: () => {
                setAppInstanceStates({
                    instances: [],
                    totalInstances: 0,
                    isAppInstancesReady: true,
                });
            },
        }),

        /**
         * Integrations
         */
        getIntegrationsList(),

        /**
         * My Apps
         */
        getMyAppsList(),

        /**
         * All Comparisons
         */
        getAndSetAllComparisonsStateWithDataRequest(),
    ]);
};

export const autoRefreshAppDataGlobally = () => {
    setInterval(prepareAppDataGlobally, constants.PREPARE_APP_DATA_GLOBALLY_AUTO_REFRESH_INTERVAL);
};
