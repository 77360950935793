export const loginstyle = {
    //signup.js
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff',
    },
    createtxt: {
        fontFamily: 'Avenir LT 55 Roman',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.563rem',
        paddingBottom: 5,
        paddingTop: '2rem',
    },
    signupbtnContainer: {
        marginTop: '2rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    signupBtn: {
        width: '55%',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1rem',
        textTransform: 'none',
        color: '#fff',
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#bdbdbd',
            color: '#fff',
        },
    },
    signupdivider: {
        backgroundColor: '#828282',
        marginTop: 10,
        width: '55%',
    },
    signupdividerContainer: {
        display: 'flex',
        justifyContent: 'center',
        align: 'center',
        width: '100%',
        paddingBottom: 10,
    },
    footerlink: {
        align: 'center',
    },
    subPass: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.75rem',
    },

    bottomtext: {
        fontSize: '0.75rem',
        '& .MuiLink-root': {
            fontSize: '0.75rem',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    signupmainContainer: {
        height: '-webkit-fill-available',
        display: 'flex',
        overflow: 'auto',
        marginTop: '4rem',
        padding: '0 10px',
        '&::-webkit-scrollbar': {
            width: 1,
        },
    },
    signuppassworddiv: {
        display: 'flex',
        position: 'relative',
        '& .MuiIconButton-root': {
            position: 'absolute',
            right: 10,
            top: 5,
            color: '#8B9197',
        },
    },
    //login.js
    loginsubmit: {
        marginTop: '1rem',
        backgroundColor: '#3A70D4',
        fontWeight: 700,
        fontSize: '1rem',
        textTransform: 'capitalize',
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#bdbdbd',
            color: '#fff',
        },
    },
    headertext: {
        fontSize: '1.563rem',
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '1rem',
        fontWeight: '600',
    },
    logintypotext: {
        fontSize: '1rem',
        fontWeight: '400',
    },
    logintextfield: {
        marginTop: 5,
        marginBottom: 15,

        '& :hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3A70D4',
            },
        },
        '& .MuiInputBase-root': {
            color: '#fff',
            boderRadius: 10,
        },
        '& .MuiInputBase-input': {
            color: '#fff!important',
            '&:-webkit-autofill': {
                transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
                transitionDelay: 'background-color 50000s, color 50000s',
            },
        },
        '& .MuiInputBase-input:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3A70D4!important',
            },
        },
        '& .MuiInputBase-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3A70D4!important',
            },
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#828282',
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: '#fff',
        },
        '& .MuiOutlinedInput-input': {
            padding: '15px 14px',
        },
        '& .MuiIconButton-root': {
            color: '#fff',
            position: 'absolute',
            right: 10,
            '& .MuiSvgIcon-root': {
                fontSize: '1.063rem',
            },
        },

        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        paddingRight: 0,
        position: 'relative',
    },
    loginremembertext: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        '& .MuiTypography-body1': { fontSize: '0.75rem', fontWeight: '400' },
        '& .MuiSvgIcon-root': {
            color: '#3A70D4',
            fontSize: '1.25rem',
        },
    },
    logindivider: {
        backgroundColor: '#828282',
        marginBottom: '1rem',
        height: 1.5,
    },
    bottomdiv: {
        textAlign: 'center',
        '& img:first-child': {
            height: '30vh',
        },
        '& img:last-child': {
            marginTop: '2rem',
            marginBottom: '3.5rem',
            height: 'auto',
        },
        '& .MuiTypography-root': {
            fontSize: '1.125rem',
            fontWeight: '400',
            color: '#fff',
            textAlign: 'center',
            textTransform: 'capitalize',
        },
    },
    //forgot.js
    cancel: {
        marginTop: '1rem',
        backgroundColor: 'transparent',
        border: '1px solid #828282',
        color: '#828282',
        fontWeight: 700,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid #828282',
            color: '#828282',
        },
    },
    send: {
        marginTop: '1rem',
        marginLeft: '1rem',
        backgroundColor: '#3A70D4',
        padding: '6px 20px',
        border: '1px solid #828282',
        fontWeight: 700,
        fontSize: '1rem',
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#bdbdbd',
            color: '#fff',
        },
    },
    forgotheadertext: {
        fontSize: '1.5625rem',
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '0.5rem',
        fontWeight: '700',
    },
    typotext1: {
        fontSize: '0.875rem',
        paddingBottom: '2rem',
    },
    buttondiv: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
};
