import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

const StepStopLoop = (props) => {
    const fill = props.fill || '#000';
    return (
        <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <rect xmlns="http://www.w3.org/2000/svg" x="0.900024" width="24" height="24" rx="5" fill="white" />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M19.9938 4.90625L18.3688 6.53125C16.9625 5.125 15.025 4.25 12.9 4.25C8.74378 4.25 5.33753 7.53125 5.15003 11.625C5.11878 11.8438 5.30628 12 5.52503 12H6.40003C6.58753 12 6.74378 11.875 6.77503 11.6562C6.96253 8.4375 9.61878 5.875 12.9 5.875C14.5875 5.875 16.1188 6.5625 17.2125 7.6875L15.525 9.375C15.275 9.625 15.4625 10 15.775 10H20.275C20.4625 10 20.65 9.84375 20.65 9.625V5.15625C20.65 4.84375 20.2438 4.65625 19.9938 4.90625ZM20.2438 12H19.3688C19.1813 12 19.025 12.1562 18.9938 12.375C18.8063 15.5938 16.15 18.125 12.9 18.125C11.1813 18.125 9.65003 17.4688 8.55628 16.3438L10.2438 14.6562C10.4938 14.4062 10.3063 14 9.99378 14H5.52503C5.30628 14 5.15003 14.1875 5.15003 14.375V18.875C5.15003 19.1875 5.52503 19.375 5.77503 19.125L7.40003 17.5C8.80628 18.9062 10.7438 19.75 12.9 19.75C17.025 19.75 20.4313 16.5 20.6188 12.4062C20.65 12.1875 20.4625 12 20.2438 12Z"
                fill={fill}
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M5.90002 4.58582L20.3142 19"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M5.90002 4.58582L20.3142 19"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

StepStopLoop.propTypes = {
    fill: PropTypes.string,
};

export default StepStopLoop;
