import SvgIcon from '@mui/material/SvgIcon';

const TriggerManualIcon = (props) => {
    return (
        <SvgIcon {...props} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20.5996" cy="20" r="20" fill="#56CCF2" />
            <path
                d="M23.1621 19.1564C23.0996 18.9177 22.9121 18.7189 22.6413 18.6592C22.3913 18.5797 22.1204 18.6592 21.9538 18.8183L20.7246 19.9916L20.0371 19.8922L19.9329 19.2359L21.1621 18.0825C21.3496 17.9035 21.4121 17.645 21.3496 17.4064C21.2663 17.1677 21.0788 16.9689 20.8079 16.9092C19.7038 16.6507 18.5579 16.949 17.7663 17.7246C16.9746 18.4604 16.6621 19.5342 16.9121 20.5683L13.0996 24.2274C12.7663 24.5257 12.5996 24.9433 12.5996 25.3808C12.5996 25.7984 12.7663 26.216 13.0996 26.5143C13.4121 26.8325 13.8496 26.9916 14.3079 26.9916C14.7454 26.9916 15.1829 26.8325 15.4954 26.5143L19.3288 22.8751C20.4121 23.1138 21.5163 22.8155 22.3079 22.0598C23.0996 21.3041 23.4329 20.1905 23.1621 19.1564ZM21.5996 21.3837C21.0163 21.9604 20.1413 22.1393 19.3288 21.9007L19.0371 21.8013L14.8079 25.8581C14.5371 26.0967 14.0579 26.0967 13.7871 25.8581C13.6621 25.7189 13.5996 25.5598 13.5996 25.3808C13.5996 25.182 13.6621 25.0229 13.7871 24.8837L18.0371 20.8467L17.9538 20.5683C17.6829 19.7927 17.8704 18.9575 18.4746 18.4007C18.8913 17.9831 19.4538 17.7842 20.0371 17.7842H20.0579L18.8704 18.9177L19.1829 20.7075L21.0579 21.0257L22.2663 19.8723C22.2663 20.4291 22.0371 20.9859 21.5996 21.3837ZM14.4329 24.9433C14.2454 24.9433 14.0996 25.0825 14.0996 25.2615C14.0996 25.4206 14.2454 25.5797 14.4329 25.5797C14.5996 25.5797 14.7663 25.4206 14.7663 25.2615C14.7663 25.0825 14.5996 24.9433 14.4329 24.9433Z"
                fill="white"
            />
            <path
                d="M15.5044 18.0312C15.5044 18.0937 15.5044 18.1563 15.4389 18.2188L14.3913 19.2188C14.2276 19.375 13.9329 19.25 13.9329 19.0313V14.5C13.9329 13.6562 14.6532 13 15.5044 13H27.0282C27.9121 13 28.5996 13.6562 28.5996 14.5V25.5C28.5996 26.3125 27.9121 27 27.0282 27H18.0907C17.8615 27 17.7306 26.7188 17.8943 26.5625L18.9419 25.5625C19.0073 25.5 19.0728 25.5 19.1383 25.5H27.0282V14.5H15.5044V18.0312Z"
                fill="white"
            />
        </SvgIcon>
    );
};
export default TriggerManualIcon;
