import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { hashString as hashStringUtil } from '../../utils/stringUtils';

export const field = (val) => {
    return val;
};

export const sum = (arr) => {
    // Convert to int
    return arr.map((x) => +x).reduce((a, b) => a + b, 0);
};

export const concat = (arr) => {
    return arr.join('');
};

export const trim = (arr) => {
    if (arr.length > 1) {
        return { message: 'Error: trim() only takes one parameter: (string)', type: 'error' };
    } else if (typeof arr[0] !== 'string') {
        return { message: 'Error: Incorrect parameter in trim(string)', type: 'error' };
    } else {
        return arr[0].trim();
    }
};

export const substring = (arr) => {
    if (arr.length !== 3) {
        return { message: 'Error: substring() must take three parameters: (string, number, number)', type: 'error' };
    } else if (typeof arr[0] !== 'string' || isNaN(parseInt(arr[1])) || isNaN(parseInt(arr[2]))) {
        return { message: 'Error: Incorrect parameter in substring(string, number, number)', type: 'error' };
    } else {
        const res = arr[0].substring(arr[1], arr[2]);
        if (res === undefined) {
            return { message: 'Error: Incorrect parameter in substring(string, number, number)', type: 'error' };
        } else {
            return res;
        }
    }
};

export const split = (arr) => {
    if (arr.length !== 3) {
        return { message: 'Error: split() must take three param', type: 'error' };
    } else if (typeof arr[0] !== 'string' || typeof arr[1] !== 'string' || isNaN(parseInt(arr[2]))) {
        return { message: 'Error: Incorrect parameter in split(string, string, number)', type: 'error' };
    } else {
        const res = arr[0].split(arr[1])[arr[2]];
        if (res === undefined) {
            return { message: 'Error: Incorrect parameter in split(string, string, number)', type: 'error' };
        } else {
            return res;
        }
    }
};

export const currentDateTime = () => {
    return new Date().toUTCString();
};

export const formatDateTime = (arr) => {
    dayjs.extend(utc);
    dayjs.extend(advancedFormat);
    return dayjs.utc(arr[0]).format(arr[1]);
};

export const base64ImgToBuffer = (arr) => {
    if (arr.length !== 1) {
        return { message: 'base64ImgToBuffer() must take one param', type: 'error' };
    } else if (typeof arr[0] !== 'string') {
        return { message: 'incorrect parameter type in base64ImgToBuffer(string)', type: 'error' };
    }
    return Buffer.from(arr[0], 'base64');
};

export const concatArray = (arr) => {
    if (arr.length !== 2) {
        return { message: 'concatArray() must take two params', type: 'error' };
    } else if (typeof arr[0] !== 'object' || typeof arr[1] !== 'string') {
        return { message: 'incorrect parameter in concatArray(array, number)', type: 'error' };
    } else {
        return arr[0].join(arr[1]);
    }
};

export const hashString = (arr) => {
    if (arr.length < 1 || arr.length > 2) {
        return { message: 'hashString() must take one or two params', type: 'error' };
    } else if (typeof arr[0] !== 'string' || (arr[1] && typeof arr[1] !== 'string')) {
        return { message: 'incorrect parameter in hashString(string, string)', type: 'error' };
    } else {
        return hashStringUtil(arr[0], arr[1]);
    }
};

export const addIntervalToDateTime = (arr) => {
    if (arr.length !== 4) {
        return { message: 'addIntervalToDateTime() must take four params', type: 'error' };
    } else if (
        typeof arr[0] !== 'string' ||
        isNaN(parseInt(arr[1])) ||
        typeof arr[2] !== 'string' ||
        typeof arr[3] !== 'string'
    ) {
        return {
            message: 'incorrect parameter in addIntervalToDateTime(string, number, string, string)',
            type: 'error',
        };
    } else if (
        arr[2] !== 'day' &&
        arr[2] !== 'week' &&
        arr[2] !== 'month' &&
        arr[2] !== 'year' &&
        arr[2] !== 'hour' &&
        arr[2] !== 'minute' &&
        arr[2] !== 'second' &&
        arr[2] !== 'millisecond'
    ) {
        return {
            message:
                'incorrect parameter in addIntervalToDateTime(string, number, string, string). Third parameter must be day, week, month, year, hour, minute, second, or millisecond.',
            type: 'error',
        };
    } else {
        dayjs.extend(utc);
        dayjs.extend(advancedFormat);
        return dayjs.utc(arr[0]).add(arr[1], arr[2]).format(arr[3]);
    }
};

export const subtractIntervalFromDateTime = (arr) => {
    if (arr.length !== 4) {
        return { message: 'subtractIntervalFromDateTime() must take four params', type: 'error' };
    } else if (
        typeof arr[0] !== 'string' ||
        isNaN(parseInt(arr[1])) ||
        typeof arr[2] !== 'string' ||
        typeof arr[3] !== 'string'
    ) {
        return {
            message: 'incorrect parameter in subtractIntervalFromDateTime(string, number, string, string)',
            type: 'error',
        };
    } else if (
        arr[2] !== 'day' &&
        arr[2] !== 'week' &&
        arr[2] !== 'month' &&
        arr[2] !== 'year' &&
        arr[2] !== 'hour' &&
        arr[2] !== 'minute' &&
        arr[2] !== 'second' &&
        arr[2] !== 'millisecond'
    ) {
        return {
            message:
                'incorrect parameter in subtractIntervalFromDateTime(string, number, string, string). Third parameter must be day, week, month, year, hour, minute, second, or millisecond.',
            type: 'error',
        };
    } else {
        dayjs.extend(utc);
        dayjs.extend(advancedFormat);
        return dayjs.utc(arr[0]).subtract(arr[1], arr[2]).format(arr[3]);
    }
};

export const randomInteger = (arr) => {
    if (arr.length !== 2) {
        return { message: 'randomInt() must take two params', type: 'error' };
    } else if (isNaN(parseInt(arr[0])) || isNaN(parseInt(arr[1]))) {
        return { message: 'incorrect parameter in randomInt(number, number)', type: 'error' };
    } else {
        return Math.random() * (parseInt(arr[1]) - parseInt(arr[0])) + parseInt(arr[0]);
    }
};
