/**
 * Redux reducer helper for dispatching action(s)
 *
 * @param {object} actions An object of reducer actions
 * @param {*} initialState Default state to use
 * @param {boolean} isStateProtected Whether to ignore state update
 * if given state does not exists in default state object. Default: true.
 *
 * @returns {function} Redux reducer function
 */
const reduxReducerHelper =
    (actions, initialState, isStateProtected = true) =>
    (state = initialState, action = {}) => {
        const type = action?.type;
        const actionExists = actions ? Object.values(actions)?.includes(type) : {};

        if (actionExists || !isStateProtected) {
            return {
                ...state,
                [type]: action.payload,
            };
        }

        return state;
    };

export default reduxReducerHelper;

/**
 * Default reducer actions values mapper
 *
 * @param {Object} defaultValues Reducer default values
 *
 * @returns {Object} Reducer actions
 */
export const mapDefaultValuesToActions = (defaultValues) => {
    const actions = {};
    for (const o in defaultValues) {
        actions[o] = o;
    }
    return actions;
};
