import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
    setIntegrationBuilderState,
    setIntegrationBuilderStates,
    useIntegrationBuilderValue,
    useTransformComponentPanState,
} from '../../../hooks/useIntegrationBuilderHook';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../IntegrationBuilderStepDrawer/IntegrationBuilderStepDrawerFields/IntegrationStepSelectAppInstanceRequestBodyField/styles';
import SelectField from '../../InputFields/SelectField';
import InputLabel from '../../InputFields/InputLabel';
import * as constants from '../../../utils/constants';
import TextField, { textareaInputProps } from '../../InputFields/TextField';
import { Switch } from '@mui/material';
import IntegrationBuilderAuthorizationFields from '../IntegrationBuilderAuthorizationFields';
import IntegrationBuilderStepDrawerFieldGrid from '../IntegrationBuilderStepDrawer/IntegrationBuilderStepDrawerFieldGrid';
import IntegrationBuilderSettingsAccordion from './IntegrationBuilderSettingsAccordion';
import KeyValuePairFieldSet, {
    handleKeyValuePairRemove,
    handleKeyValuePairUpdate,
    isValidKeyValuePairList,
} from '../../InputFields/KeyValuePairFieldSet';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { getEmailAddressArray, isValidAuthorizationConfig } from '../../../utils/integrationUtils';
import * as integrationUtils from '../../../utils/integrationUtils';

/**
 * @param {IntegrationBuilderProps} integrationBuilderProps
 * @constructor
 */
const IntegrationBuilderSettingsContainer = ({ integrationBuilderProps }) => {
    const rootStyle = { position: 'relative', height: '100%' },
        closeDrawerButtonStyle = { minWidth: 'auto', padding: '1px 6px' },
        rowDivStyle = { display: 'flex', flexDirection: 'row', marginBottom: '15px' },
        { classes, isUserAdmin, selectedIntegrationData, handleOpenIntegrationStringInputModal } =
            integrationBuilderProps,
        [transformDimensions] = useTransformComponentPanState(),
        isSubmittingIntegrationBuilderForm = useIntegrationBuilderValue('isSubmittingIntegrationBuilderForm'),
        integrationStatusList = [
            {
                id: 'integrationStatusActive',
                label: 'Active',
                value: constants.INTEGRATION_STATUS_ACTIVE,
            },
            {
                id: 'integrationStatusPending',
                label: 'Pending',
                value: constants.INTEGRATION_STATUS_PENDING,
            },
            {
                id: 'integrationStatusDisabled',
                label: 'Disabled',
                value: constants.INTEGRATION_STATUS_DISABLED,
            },
        ],
        [integrationMetaData, setIntegrationMetaData] = useState(selectedIntegrationData || {}),
        responseBodyIncludes = integrationMetaData?.responseBodyIncludes || {},
        hasWebhookEnabled = !!integrationMetaData?.webhookAfterExecution,
        webhookConfig = integrationMetaData?.webhookConfig || {},
        isAdditionalDataEnabled =
            !!responseBodyIncludes?.additionalData || responseBodyIncludes?.responseOutputMode === 'custom',
        [emailsToNotify, setEmailsToNotifyDisplay] = useState(
            integrationMetaData?.notificationEmailList?.join(', ') || '',
        ),
        [currentErrors, setCurrentErrors] = useState({});

    const handleCloseStepDrawer = () => {
        setIntegrationBuilderStates({
            isSettingsDrawerOpen: false,
        });
    };

    const updateMainIntegrationBuilderInformation = async (updatedIntegration) => {
        setIntegrationBuilderState('currentIntegrationMetaData', {
            ...selectedIntegrationData,
            ...updatedIntegration,
        });
    };

    useEffect(() => {
        // Populate initial state of meta data before changes
        setIntegrationBuilderState('currentIntegrationMetaData', {
            ...selectedIntegrationData,
        });
    }, []);

    /**
     * Handles setting up and opening the string input modal.
     * @param {string} initialStringValue
     * @param {string} stringType
     * @param {string?} fieldset
     * @param {number?} index
     */
    const openStringInputModal = (initialStringValue, stringType, fieldset, index) => {
        if (!isUserAdmin) return;

        handleOpenIntegrationStringInputModal({
            stringValue: initialStringValue,
            showOptionsForStringType: false,
            stringTypeOptions: [],
            stringType,
            onSubmit: (updatedStringValue, stringType) => {
                updateLocalIntegrationMetaDataState(stringType, updatedStringValue, fieldset, index);
            },
        });
    };

    /**
     * @param {AuthorizationFieldUpdate} initialUpdateData
     */
    const openStringInputModalForAuthorization = (initialUpdateData) => {
        if (!isUserAdmin) return;

        handleOpenIntegrationStringInputModal({
            stringValue: initialUpdateData.currentValue,
            showOptionsForStringType: false,
            stringTypeOptions: [],
            stringType: initialUpdateData.fieldName,
            onSubmit: (stringValue) => {
                handleInputForAuthorizationFields({
                    ...initialUpdateData,
                    updateType: initialUpdateData.stringModalUpdateType,
                    currentValue: stringValue,
                });
            },
        });
    };

    /**
     * Updates the local state of the integration from the form based on field and fieldset.
     * @param {string} fieldName
     * @param {string|boolean} valueToSet
     * @param {string?} fieldset
     * @param {number?} index
     */
    const updateLocalIntegrationMetaDataState = async (fieldName, valueToSet, fieldset, index) => {
        if (!isUserAdmin) return;
        const updatedIntegrationData = {};
        updatedIntegrationData['webhookConfig'] = { ...webhookConfig };
        updatedIntegrationData['responseBodyIncludes'] = { ...responseBodyIncludes };

        if (fieldset) {
            switch (fieldset) {
                case 'webhook':
                    updatedIntegrationData['webhookConfig'][fieldName] = valueToSet;
                    break;
                case 'responseBodyIncludes':
                    updatedIntegrationData['responseBodyIncludes'][fieldName] = valueToSet;
                    break;
                case 'queryParams':
                case 'headers':
                case 'additionalDataFields':
                    {
                        let baseObject;
                        switch (fieldset) {
                            case 'queryParams':
                            case 'headers':
                                baseObject = 'webhookConfig';
                                break;
                            case 'additionalDataFields':
                                baseObject = 'responseBodyIncludes';
                                break;
                        }

                        if (baseObject) {
                            if (!updatedIntegrationData[baseObject]?.[fieldset]) {
                                updatedIntegrationData[baseObject][fieldset] = [];
                            }

                            updatedIntegrationData[baseObject][fieldset] = handleKeyValuePairUpdate(
                                updatedIntegrationData[baseObject][fieldset],
                                fieldName,
                                valueToSet,
                                index,
                            );
                        }
                    }
                    break;
            }
        } else {
            switch (fieldName) {
                case 'sendNotifications':
                case 'webhookAfterExecution':
                    updatedIntegrationData[fieldName] = valueToSet;
                    break;
                case 'requiresAuthorization':
                    updatedIntegrationData['webhookConfig'][fieldName] = valueToSet;
                    break;
                case 'webhookMethod':
                    updatedIntegrationData['webhookConfig']['method'] = valueToSet;
                    break;
                case 'notificationEmails':
                    setEmailsToNotifyDisplay(valueToSet);
                    updatedIntegrationData['notificationEmailList'] = getEmailAddressArray(valueToSet);
                    break;
                case 'additionalDataJson':
                    updatedIntegrationData['responseBodyIncludes'][fieldName] = valueToSet;
                    break;
                default:
                    updatedIntegrationData[fieldName] = valueToSet;
                    break;
            }
        }

        const updatedIntegration = {
            ...integrationMetaData,
            ...updatedIntegrationData,
        };

        setIntegrationMetaData(updatedIntegration);
        await updateMainIntegrationBuilderInformation(updatedIntegration);
    };

    const handleUpdatingKeyValueList = async (fieldName, newValues) => {
        const updatedIntegrationData = {};

        switch (fieldName) {
            case 'queryParams':
            case 'headers':
                updatedIntegrationData['webhookConfig'] = { ...webhookConfig };
                updatedIntegrationData['webhookConfig'][fieldName] = newValues;
                break;
            case 'additionalDataFields':
                updatedIntegrationData['responseBodyIncludes'] = { ...responseBodyIncludes };
                updatedIntegrationData['responseBodyIncludes'][fieldName] = newValues;
                break;
        }

        const updatedIntegration = {
            ...integrationMetaData,
            ...updatedIntegrationData,
        };

        setIntegrationMetaData(updatedIntegration);
        await updateMainIntegrationBuilderInformation(updatedIntegration);
    };

    /**
     * Converts switch values to boolean and maps necessary data to the right event property.
     * @param {React.ChangeEvent<HTMLSelectElement|HTMLInputElement>} e
     */
    const handleSelectAndSwitchChange = async (e) => {
        const targetName = e.target.name,
            targetValue = e.target.value;

        switch (targetName) {
            case 'responseOutputMode':
            case 'integrationIncludeLogs':
            case 'integrationIncludeErrors':
            case 'integrationIncludeAdditionalData':
            case 'integrationIncludeOutput':
            case 'additionalDataMode':
                {
                    let mappedInputName;
                    let mappedValue;
                    switch (targetName) {
                        case 'integrationIncludeLogs':
                            mappedInputName = 'logs';
                            mappedValue = e.target.checked;
                            break;
                        case 'integrationIncludeErrors':
                            mappedInputName = 'errorData';
                            mappedValue = e.target.checked;
                            break;
                        case 'integrationIncludeOutput':
                            mappedInputName = 'output';
                            mappedValue = e.target.checked;
                            break;
                        case 'integrationIncludeAdditionalData':
                            mappedInputName = 'additionalData';
                            mappedValue = e.target.checked;
                            break;
                        default:
                            mappedInputName = targetName;
                            mappedValue = e.target.value;
                            break;
                    }

                    await updateLocalIntegrationMetaDataState(mappedInputName, mappedValue, 'responseBodyIncludes');
                }
                break;
            case 'sendNotifications':
            case 'webhookAfterExecution':
                await updateLocalIntegrationMetaDataState(targetName, e.target.checked);
                break;
            case 'requiresAuthorization':
                await updateLocalIntegrationMetaDataState(targetName, e.target.checked, 'webhook');
                break;
            default:
                await updateLocalIntegrationMetaDataState(targetName, targetValue);
                break;
        }
    };

    /**
     * Returns a function that can handle the given inputs for a keyValue pair input set.
     * @param {string} fieldSet
     * @returns {function(keyType: string, index: number, keyValue: string|KeyValuePairFieldSet): {onClick: function(): void, onChange: function(*): void}}
     */
    const getAdditionalPropsForFieldSet = (fieldSet) => {
        return (keyType, index, keyValue) => {
            return {
                onClick: () => {
                    switch (keyType) {
                        case 'key':
                            // Handled by change event
                            break;
                        case 'value':
                            openStringInputModal(keyValue?.value?.rawData || '', keyType, fieldSet, index);
                            break;
                    }
                },
                onChange: async (e) => {
                    switch (keyType) {
                        case 'key':
                            await updateLocalIntegrationMetaDataState(keyType, e.target.value, fieldSet, index);
                            break;
                        case 'value':
                            // Handled by click event
                            break;
                    }
                },
            };
        };
    };

    /**
     * @param {AuthorizationFieldUpdate} updatedData
     */
    const handleInputForAuthorizationFields = (updatedData) => {
        let destinationToUpdate;
        const updatedIntegrationData = {};
        updatedIntegrationData['webhookConfig'] = { ...webhookConfig };

        // Add object if not set or reset if type is changing
        if (
            !updatedIntegrationData['webhookConfig']?.['authorizationConfig'] ||
            // Clear the data on swapping types
            updatedData.fieldName === 'authorizationType'
        ) {
            updatedIntegrationData['webhookConfig']['authorizationConfig'] = {};
        }

        if (updatedData?.subfieldSet === 'bearer') {
            if (!updatedIntegrationData['webhookConfig']['authorizationConfig']['bearerTokenData']) {
                updatedIntegrationData['webhookConfig']['authorizationConfig']['bearerTokenData'] = {};
            }

            destinationToUpdate = updatedIntegrationData['webhookConfig']['authorizationConfig']['bearerTokenData'];

            if (updatedData.fieldName === 'bearerAuthorizationType') {
                delete destinationToUpdate['bearerKeyLocation'];
                delete destinationToUpdate['bearerHeaderField'];
                delete destinationToUpdate['bearerApiKey'];
                delete destinationToUpdate['bearerMethod'];
                delete destinationToUpdate['bearerPersistentTokenData'];
                delete destinationToUpdate['bearerTokenUrl'];
                delete destinationToUpdate['bearerClientId'];
                delete destinationToUpdate['bearerClientSecret'];
                delete destinationToUpdate['bearerAudience'];
                delete destinationToUpdate['bearerTokenPropertyName'];
                delete destinationToUpdate['bearerUsername'];
                delete destinationToUpdate['bearerPassword'];
            }
        } else {
            destinationToUpdate = updatedIntegrationData['webhookConfig']['authorizationConfig'];
        }

        switch (updatedData.updateType) {
            case 'field':
                destinationToUpdate[updatedData.fieldName] = updatedData.currentValue;
                break;
            case 'keyValuePair':
                {
                    switch (updatedData.keyValuePairUpdateType) {
                        case 'fullUpdate':
                            destinationToUpdate[updatedData.fieldName] = updatedData.currentValue;
                            break;
                        case 'key':
                        case 'value':
                            if (!Array.isArray(destinationToUpdate[updatedData.fieldName])) {
                                destinationToUpdate[updatedData.fieldName] = [];
                            }
                            destinationToUpdate[updatedData.fieldName] = handleKeyValuePairUpdate(
                                destinationToUpdate[updatedData.fieldName] || [],
                                updatedData.keyValuePairUpdateType,
                                updatedData.currentValue,
                                updatedData.index,
                            );
                            break;
                    }
                }
                break;
            case 'stringModal':
                openStringInputModalForAuthorization(updatedData);
                break;
        }

        const updatedIntegration = {
            ...integrationMetaData,
            ...updatedIntegrationData,
        };

        setIntegrationMetaData(updatedIntegration);
        updateMainIntegrationBuilderInformation(updatedIntegration);
    };

    useEffect(() => {
        setCurrentErrors(validateIntegrationDataSettings(integrationMetaData, selectedIntegrationData));
    }, [isSubmittingIntegrationBuilderForm, selectedIntegrationData]);

    return (
        <div
            style={rootStyle}
            className={classNames(classes.stepmainContainer, {
                noSelectContainer: transformDimensions.isPanning,
            })}
        >
            <div className={classes.Rightdrawheader}>
                <Typography component="h1" varient="h5">
                    Integration
                </Typography>
                <Button style={closeDrawerButtonStyle} onClick={handleCloseStepDrawer}>
                    <CloseIcon sx={{ fill: 'white', height: '24px' }} />
                </Button>
            </div>
            <div
                style={{
                    padding: '15px',
                    width: '100%',
                    height: '90vh',
                    overflowY: 'scroll',
                }}
            >
                <InputLabel sx={styles.mappingLabel} label={'Status'} />
                <div style={rowDivStyle}>
                    <div style={{ ...styles.fieldGrid, width: '150px' }}>
                        <SelectField
                            items={integrationStatusList}
                            name={'status'}
                            label={null}
                            required={true}
                            renderWithGrid={false}
                            isSubmittingForm={isSubmittingIntegrationBuilderForm}
                            placeholder="Current Integration Status"
                            searchPlaceholder="Current Integration Status"
                            getItemId={(option) => option.id}
                            getItemLabel={(option) => option.label}
                            getItemValue={(option) => option.value}
                            value={integrationMetaData?.status ?? ''}
                            handleChange={handleSelectAndSwitchChange}
                            disabled={!isUserAdmin}
                        />
                    </div>
                </div>
                <InputLabel
                    sx={styles.mappingLabel}
                    label={
                        <>
                            Name<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                        </>
                    }
                />
                <div style={rowDivStyle}>
                    <div style={{ ...styles.fieldGrid, width: '150px' }}>
                        <TextField
                            name={'name'}
                            value={integrationMetaData?.name || ''}
                            type={'text'}
                            onChange={handleSelectAndSwitchChange}
                            label={null}
                            isSubmittingForm={isSubmittingIntegrationBuilderForm}
                            required={true}
                            renderWithGrid={false}
                            error={currentErrors?.name ?? false}
                            validate={{
                                errorLabel: `Please enter an integration name`,
                            }}
                        />
                    </div>
                </div>
                <InputLabel sx={styles.mappingLabel} label={'Description'} />
                <div style={rowDivStyle}>
                    <div style={{ ...styles.fieldGrid, width: '150px' }}>
                        <TextField
                            name={'description'}
                            value={integrationMetaData?.description || ''}
                            minRows={3}
                            label={null}
                            isSubmittingForm={isSubmittingIntegrationBuilderForm}
                            required={false}
                            renderWithGrid={false}
                            inputProps={textareaInputProps}
                            onChange={handleSelectAndSwitchChange}
                        />
                    </div>
                </div>
                <div style={rowDivStyle}>
                    <IntegrationBuilderSettingsAccordion titleContent={'Notifications'}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <Typography component="div" style={{ fontSize: '14px', display: 'inline' }}>
                                    Enable Notifications
                                </Typography>
                                <Switch
                                    name="sendNotifications"
                                    checked={!!integrationMetaData?.sendNotifications}
                                    onChange={handleSelectAndSwitchChange}
                                />
                            </div>
                            <div>
                                <InputLabel sx={styles.mappingLabel} label={'Associated Emails'} />
                                <div style={rowDivStyle}>
                                    <div style={{ ...styles.fieldGrid, width: '150px' }}>
                                        <TextField
                                            value={emailsToNotify}
                                            name={'notificationEmails'}
                                            minRows={3}
                                            label={null}
                                            isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                            required={false}
                                            renderWithGrid={false}
                                            inputProps={textareaInputProps}
                                            error={currentErrors?.notificationEmailList ?? false}
                                            onChange={async (e) => {
                                                await updateLocalIntegrationMetaDataState(
                                                    'notificationEmails',
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IntegrationBuilderSettingsAccordion>
                </div>
                <div style={rowDivStyle}>
                    <IntegrationBuilderSettingsAccordion titleContent={'Data in Response Body'}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ padding: '0 10px 0 0' }}>Mode:</div>
                            <div>
                                <RadioGroup
                                    row
                                    name="responseOutputMode"
                                    value={responseBodyIncludes?.responseOutputMode || 'default'}
                                    aria-label="additional-data-mode"
                                    sx={{ '& MuiFormControlLabel-root': { padding: '5px' } }}
                                    onChange={handleSelectAndSwitchChange}
                                >
                                    <FormControlLabel
                                        label={'Default'}
                                        value={'default'}
                                        disabled={!isUserAdmin}
                                        sx={{
                                            '& .MuiTypography-root': { fontSize: '14px' },
                                            '& .MuiButtonBase-root': { padding: '5px' },
                                        }}
                                        control={
                                            <Radio
                                                icon={<RadioButtonUncheckedIcon fill="transparent" />}
                                                checkedIcon={<RadioButtonCheckedIcon />}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={'Custom'}
                                        value={'custom'}
                                        disabled={!isUserAdmin}
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '14px',
                                                marginLeft: '-3px',
                                            },
                                        }}
                                        control={
                                            <Radio
                                                icon={<RadioButtonUncheckedIcon fill="transparent" />}
                                                checkedIcon={<RadioButtonCheckedIcon />}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                            }}
                        >
                            <div>
                                <Typography component="div" style={{ fontSize: '14px', display: 'inline' }}>
                                    Logs
                                </Typography>
                                <Switch
                                    name="integrationIncludeLogs"
                                    checked={
                                        !!responseBodyIncludes?.logs &&
                                        (!responseBodyIncludes?.responseOutputMode ||
                                            responseBodyIncludes?.responseOutputMode === 'default')
                                    }
                                    disabled={
                                        responseBodyIncludes?.responseOutputMode &&
                                        responseBodyIncludes?.responseOutputMode !== 'default'
                                    }
                                    onChange={handleSelectAndSwitchChange}
                                />
                            </div>
                            <div>
                                <Typography component="div" style={{ fontSize: '14px', display: 'inline' }}>
                                    Outputs
                                </Typography>
                                <Switch
                                    name="integrationIncludeOutput"
                                    checked={
                                        !!responseBodyIncludes?.output &&
                                        (!responseBodyIncludes?.responseOutputMode ||
                                            responseBodyIncludes?.responseOutputMode === 'default')
                                    }
                                    disabled={
                                        responseBodyIncludes?.responseOutputMode &&
                                        responseBodyIncludes?.responseOutputMode !== 'default'
                                    }
                                    onChange={handleSelectAndSwitchChange}
                                />
                            </div>
                            <div>
                                <Typography component="div" style={{ fontSize: '14px', display: 'inline' }}>
                                    Errors
                                </Typography>
                                <Switch
                                    name="integrationIncludeErrors"
                                    checked={
                                        !!responseBodyIncludes?.errorData &&
                                        (!responseBodyIncludes?.responseOutputMode ||
                                            responseBodyIncludes?.responseOutputMode === 'default')
                                    }
                                    disabled={
                                        responseBodyIncludes?.responseOutputMode &&
                                        responseBodyIncludes?.responseOutputMode !== 'default'
                                    }
                                    onChange={handleSelectAndSwitchChange}
                                />
                            </div>
                            <div>
                                <Typography component="div" style={{ fontSize: '14px', display: 'inline' }}>
                                    Additional Data
                                </Typography>
                                <Switch
                                    name="integrationIncludeAdditionalData"
                                    checked={isAdditionalDataEnabled}
                                    disabled={
                                        responseBodyIncludes?.responseOutputMode &&
                                        responseBodyIncludes?.responseOutputMode !== 'default'
                                    }
                                    onChange={handleSelectAndSwitchChange}
                                />
                            </div>
                        </div>
                        {isAdditionalDataEnabled && (
                            <div style={{ ...rowDivStyle, marginTop: '10px' }}>
                                <IntegrationBuilderSettingsAccordion titleContent={'Additional Data'}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ padding: '0 10px 0 0' }}>Mode:</div>
                                        <div>
                                            <RadioGroup
                                                row
                                                name="additionalDataMode"
                                                value={responseBodyIncludes?.additionalDataMode || 'normal'}
                                                aria-label="additional-data-mode"
                                                sx={{ '& MuiFormControlLabel-root': { padding: '5px' } }}
                                                onChange={handleSelectAndSwitchChange}
                                            >
                                                <FormControlLabel
                                                    label={'Normal'}
                                                    value={'normal'}
                                                    disabled={!isUserAdmin}
                                                    sx={{
                                                        '& .MuiTypography-root': { fontSize: '14px' },
                                                        '& .MuiButtonBase-root': { padding: '5px' },
                                                    }}
                                                    control={
                                                        <Radio
                                                            icon={<RadioButtonUncheckedIcon fill="transparent" />}
                                                            checkedIcon={<RadioButtonCheckedIcon />}
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    label={'Advanced'}
                                                    value={'advanced'}
                                                    disabled={!isUserAdmin}
                                                    sx={{
                                                        '& .MuiTypography-root': {
                                                            fontSize: '14px',
                                                            marginLeft: '-3px',
                                                        },
                                                    }}
                                                    control={
                                                        <Radio
                                                            icon={<RadioButtonUncheckedIcon fill="transparent" />}
                                                            checkedIcon={<RadioButtonCheckedIcon />}
                                                        />
                                                    }
                                                />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    {responseBodyIncludes?.additionalDataMode === 'normal' && (
                                        <div style={rowDivStyle}>
                                            <KeyValuePairFieldSet
                                                integrationBuilderProps={integrationBuilderProps}
                                                listOfKeyValuePairs={responseBodyIncludes?.additionalDataFields || []}
                                                isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                                handleAdditionalFieldProps={getAdditionalPropsForFieldSet(
                                                    'additionalDataFields',
                                                )}
                                                handleRemoveIndex={(index) => {
                                                    handleUpdatingKeyValueList(
                                                        'additionalDataFields',
                                                        handleKeyValuePairRemove(
                                                            responseBodyIncludes?.additionalDataFields || [],
                                                            index,
                                                        ),
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                    {responseBodyIncludes?.additionalDataMode === 'advanced' && (
                                        <div style={rowDivStyle}>
                                            <TextField
                                                value={responseBodyIncludes?.additionalDataJson || ''}
                                                name={'additionalDataJson'}
                                                minRows={3}
                                                label={null}
                                                isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                                required={false}
                                                renderWithGrid={false}
                                                error={currentErrors?.additionalDataJson ?? false}
                                                inputProps={{
                                                    ...textareaInputProps,
                                                    onClick: () => {
                                                        openStringInputModal(
                                                            responseBodyIncludes?.additionalDataJson || '',
                                                            'additionalDataJson',
                                                        );
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </IntegrationBuilderSettingsAccordion>
                            </div>
                        )}
                    </IntegrationBuilderSettingsAccordion>
                </div>
                <div style={rowDivStyle}>
                    <IntegrationBuilderSettingsAccordion titleContent={'Webhook'}>
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                <div>
                                    <Typography component="div" style={{ fontSize: '14px', display: 'inline' }}>
                                        Call Webhook After Execution
                                    </Typography>
                                    <Switch
                                        name="webhookAfterExecution"
                                        checked={hasWebhookEnabled}
                                        onChange={handleSelectAndSwitchChange}
                                    />
                                </div>
                            </div>
                            {hasWebhookEnabled && (
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
                                    <InputLabel
                                        sx={styles.mappingLabel}
                                        label={
                                            <>
                                                Method<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                            </>
                                        }
                                    />
                                    <IntegrationBuilderStepDrawerFieldGrid
                                        label=""
                                        isRequired={true}
                                        style={{ ...styles.fieldGrid, width: '100%', marginBottom: '5px' }}
                                        integrationBuilderProps={integrationBuilderProps}
                                    >
                                        <SelectField
                                            items={['', 'GET', 'POST']}
                                            name={'webhookMethod'}
                                            label={null}
                                            required={false}
                                            renderWithGrid={false}
                                            placeholder="Select Request Method"
                                            searchPlaceholder="Search Request Method"
                                            validate={{ errorLabel: 'Request Method' }}
                                            getItemId={(option) => option}
                                            getItemLabel={(option) => {
                                                if (option.length === 0) {
                                                    return 'None';
                                                } else {
                                                    return option;
                                                }
                                            }}
                                            error={currentErrors?.['webhookMethod']}
                                            getItemValue={(option) => option}
                                            value={webhookConfig?.method || ''}
                                            handleChange={handleSelectAndSwitchChange}
                                            disabled={!isUserAdmin}
                                        />
                                    </IntegrationBuilderStepDrawerFieldGrid>
                                    <div>
                                        <InputLabel
                                            sx={styles.mappingLabel}
                                            label={
                                                <>
                                                    URL<span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                                </>
                                            }
                                        />
                                        <div style={rowDivStyle}>
                                            <div style={{ ...styles.fieldGrid, width: '150px' }}>
                                                <TextField
                                                    name={'endpointUrl'}
                                                    value={webhookConfig?.endpointUrl || ''}
                                                    label={null}
                                                    required={false}
                                                    placeholder={'https://www.example.com/'}
                                                    validate={{ errorLabel: 'URL' }}
                                                    renderWithGrid={false}
                                                    error={currentErrors?.endpointUrl === true}
                                                    inputProps={{
                                                        onClick: () => {
                                                            openStringInputModal(
                                                                webhookConfig?.endpointUrl || '',
                                                                'endpointUrl',
                                                                'webhook',
                                                            );
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={rowDivStyle}>
                                        <IntegrationBuilderSettingsAccordion titleContent={'Headers'}>
                                            <KeyValuePairFieldSet
                                                integrationBuilderProps={integrationBuilderProps}
                                                listOfKeyValuePairs={webhookConfig?.headers || []}
                                                isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                                handleAdditionalFieldProps={getAdditionalPropsForFieldSet('headers')}
                                                handleRemoveIndex={async (index) => {
                                                    await handleUpdatingKeyValueList(
                                                        'headers',
                                                        handleKeyValuePairRemove(webhookConfig?.headers || [], index),
                                                    );
                                                }}
                                                options={{ showLabels: true }}
                                            />
                                        </IntegrationBuilderSettingsAccordion>
                                    </div>
                                    <div style={rowDivStyle}>
                                        <IntegrationBuilderSettingsAccordion titleContent={'Query Params'}>
                                            <KeyValuePairFieldSet
                                                integrationBuilderProps={integrationBuilderProps}
                                                listOfKeyValuePairs={webhookConfig?.queryParams || []}
                                                isSubmittingForm={isSubmittingIntegrationBuilderForm}
                                                handleAdditionalFieldProps={getAdditionalPropsForFieldSet(
                                                    'queryParams',
                                                )}
                                                handleRemoveIndex={(index) => {
                                                    handleUpdatingKeyValueList(
                                                        'queryParams',
                                                        handleKeyValuePairRemove(
                                                            webhookConfig?.queryParams || [],
                                                            index,
                                                        ),
                                                    );
                                                }}
                                                options={{ showLabels: true }}
                                            />
                                        </IntegrationBuilderSettingsAccordion>
                                    </div>
                                    <div style={rowDivStyle}>
                                        <IntegrationBuilderSettingsAccordion titleContent={'Authentication'}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'start',
                                                }}
                                            >
                                                <div>
                                                    <Typography
                                                        component="div"
                                                        style={{ fontSize: '14px', display: 'inline' }}
                                                    >
                                                        Requires Authorization
                                                    </Typography>
                                                    <Switch
                                                        name="requiresAuthorization"
                                                        checked={!!webhookConfig?.requiresAuthorization}
                                                        onChange={handleSelectAndSwitchChange}
                                                    />
                                                </div>
                                            </div>
                                            {hasWebhookEnabled && !!webhookConfig?.requiresAuthorization && (
                                                <IntegrationBuilderAuthorizationFields
                                                    integrationBuilderProps={integrationBuilderProps}
                                                    authorizationConfig={webhookConfig?.authorizationConfig || {}}
                                                    handleInputChange={handleInputForAuthorizationFields}
                                                    dataBeingUpdated={integrationMetaData}
                                                />
                                            )}
                                        </IntegrationBuilderSettingsAccordion>
                                    </div>
                                </div>
                            )}
                        </>
                    </IntegrationBuilderSettingsAccordion>
                </div>
            </div>
        </div>
    );
};

IntegrationBuilderSettingsContainer.propTypes = {
    integrationBuilderProps: PropTypes.object.isRequired,
};

/**
 * @param {string} variableDataJsonString
 * @param {IntegrationData} integrationData
 * @returns {boolean}
 */
const isValidJsonStringWithVariables = (variableDataJsonString, integrationData) => {
    if (typeof variableDataJsonString === 'string') {
        const updatedString = integrationUtils.resolveDummyDataForIntegration(
            variableDataJsonString,
            integrationData.variables,
        );

        if (updatedString.hasError === false) {
            try {
                JSON.parse(updatedString.resolvedString);

                return true;
            } catch (error) {
                // console.error(error);
            }
        }
    }

    return false;
};

/**
 *
 * @param {Object} integrationMetaData
 * @param {IntegrationData} selectedIntegrationData
 * @returns {{}}
 */
export const validateIntegrationDataSettings = (integrationMetaData, selectedIntegrationData) => {
    const errorObject = {};
    // Check to see if we've modified the data at all
    if (Object.keys(integrationMetaData).length > 0) {
        if (!integrationMetaData?.name || integrationMetaData.name === '') {
            errorObject.name = true;
        }
        if (Array.isArray(integrationMetaData?.notificationEmailList)) {
            for (const email of integrationMetaData.notificationEmailList) {
                if (!/^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+,?[ ]*)+$/.test(email)) {
                    errorObject.notificationEmailList = true;
                }
            }
        }
        if (integrationMetaData?.responseBodyIncludes?.additionalData) {
            if (
                integrationMetaData.responseBodyIncludes.additionalDataMode === 'normal' &&
                integrationMetaData.responseBodyIncludes?.additionalDataFields?.length
            ) {
                if (!isValidKeyValuePairList(integrationMetaData.responseBodyIncludes.additionalDataFields)) {
                    errorObject.additionalDataFields = true;
                }
            } else if (
                integrationMetaData.responseBodyIncludes.additionalDataMode === 'advanced' &&
                !isValidJsonStringWithVariables(
                    integrationMetaData.responseBodyIncludes?.additionalDataJson,
                    selectedIntegrationData,
                )
            ) {
                errorObject.additionalDataJson = true;
            }
        }

        if (integrationMetaData?.webhookAfterExecution) {
            const webhookData = integrationMetaData?.webhookConfig || {};

            if (!webhookData?.method) {
                errorObject.method = true;
            }

            try {
                new URL(webhookData?.endpointUrl);
            } catch (error) {
                if (webhookData?.endpointUrl?.substring(0, 1) !== '$') {
                    errorObject.endpointUrl = true;
                }
            }

            if (webhookData?.queryParams) {
                if (!isValidKeyValuePairList(webhookData.queryParams)) {
                    errorObject.queryParams = true;
                }
            }
            if (webhookData?.headers) {
                if (!isValidKeyValuePairList(webhookData.headers)) {
                    errorObject.headers = true;
                }
            }
            if (webhookData?.requiresAuthorization) {
                if (!isValidAuthorizationConfig(webhookData?.authorizationConfig || {})) {
                    errorObject.authorizationConfig = true;
                }
            }
        }
    }

    return errorObject;
};

export default memo(IntegrationBuilderSettingsContainer);
