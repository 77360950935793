const dashboardstyle = {
    dashboardroot: {
        paddingTop: '4rem',
        overflow: 'auto',
        height: '100%',
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    topbar: {
        background: '#575757',
        padding: 10,
        paddingLeft: 30,
        paddingRight: '3rem',
        color: '#fff',
        '& .MuiTypography-root': {
            fontSize: '1.125rem',
            fontWeight: 600,
        },
        display: 'flex',
        justifyContent: 'space-between',
    },
    //workflowcontainer.js
    workflowcontainerroot: {
        padding: 0,
        color: '#fff',
        marginBottom: 10,
        '& hr': {
            marginTop: 0,
            border: 0,
            borderBottom: '2px solid #828282',
        },
        '& .MuiTypography-root': {
            color: '#fff',
            fontSize: '0.875rem',
            fontWeight: '700',
        },
        '& div:first-child': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    createbtn: {
        background: '#3a70d4',
        textTransform: 'capitalize',
        color: '#fff',
        padding: '0.7rem 2rem',
        '&:hover': {
            '& .MuiTypography-root': { color: '#3a70d4' },
            background: 'transparent',
            border: '1px solid #3a70d4',
            color: '#3a70d4!important',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            marginRight: 5,
        },
    },

    loaderdiv: {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activetab: {
        borderBottom: '2px solid #3a70d4',
        background: '#575757',
        padding: '0.7rem 2.5rem',
        textTransform: 'uppercase',
        color: '#fff',
        '&:hover': {
            color: 'rgba(255,255,255, 0.4)',
            background: 'rgba(0, 0, 0, 0.04)',
        },
    },
    deactivetab: {
        color: 'rgba(255,255,255, 0.4)',
        background: 'rgba(0, 0, 0, 0.04)',
        padding: '0.7rem 2.5rem',
        textTransform: 'uppercase',
        '&:hover': {
            background: '#575757',
            color: '#fff',
        },
    },
    //nodatacompo.js
    nodatacomporoot: {
        padding: 0,
        marginBottom: '0.875rem',
        color: '#fff',
        width: '100%',
        '& .MuiContainer-root': {
            maxWidth: '84.188rem',
        },
    },
    headtxt1: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.875rem',
        paddingTop: '1.7rem',
        marginBottom: 7,
        textTransform: 'capitalize',
        color: '#8B9197',
    },
    compContainer: {
        height: '20vh',
        width: '100%',
        color: 'black',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    //dashpopup.js
    dilaogbox: {
        '& .MuiPaper-root': {
            minWidth: 500,
            minHeight: 300,
            borderRadius: 20,
            textAlign: 'center',
        },
    },
    typodiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    typo2: {
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 'bold',
        width: '70%',
    },
    yesbtn: {
        backgroundColor: '#3A70D4',
        color: '#fff',
        padding: '6px 60px',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#3a70d4',
            opacity: '0.8',
        },
    },
    laterbtn: {
        backgroundColor: '#BDBDBD',
        color: '#fff',
        padding: '6px 60px',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#BDBDBD',
            opacity: '0.8',
        },
    },
    //breadcrumbcomp.js
    breadcrumbdiv: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        padding: 10,
        paddingLeft: 25,
        '& .MuiSvgIcon-root': {
            fontSize: '1.063rem',
        },
    },
    //integrationcontainer.js
    inttitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '0.875rem',
        color: '#3A70D4',
        '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
        '& .MuiTypography-root:last-child': {
            fontSize: '0.75rem',
            fontWeight: '400',
            color: '#8B9197',
        },
    },
    inttitle1: {
        fontSize: '0.75rem',
        fontWeight: '400',
        color: '#8B9197',
        fontStyle: 'normal',
    },
    desc: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '0.75rem',
        color: '#8b9197',
    },

    integrationcontainerroot: {
        padding: 0,
        color: '#fff',
        '& .MuiContainer-root': {
            maxWidth: '84.188rem',
        },
    },

    dropdowndrawer: {
        '& .MuiTooltip-tooltip': {
            background: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: 0,
            filter: 'drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 2px)',
            fontSize: '0.75rem',
            fontWeight: '400',
        },
        '& .MuiTooltip-arrow': {
            color: '#fff',
            filter: 'drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px)',
        },
        '& .MuiButtonBase-root': {
            '&:hover': { color: '#fff' },
            '&:first-child:hover': {
                background: '#3a70d4',
            },
            '&:nth-child(2):hover': {
                background: '#3a70d4',
            },
            '&:nth-child(3):hover': {
                background: '#3a70d4',
            },
            '&:last-child:hover': {
                background: '#ff0000',
            },
        },
    },
    hoveractive: {
        '&:hover': { background: 'green' },
    },
    hoverinactive: {
        '&:hover': { background: '#ff0000' },
    },
    dialogbox: {
        '& .MuiDialog-paper': {
            padding: '40px 50px',
            borderRadius: 20,

            '& .MuiTypography-root:first-child': {
                fontSize: '1rem',
                fontWeight: 700,
                paddingBottom: '1rem',
                color: '#000',
            },
            '& .MuiTypography-root': {
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#828282',
                paddingBottom: '0.5rem',
            },
            '& .MuiOutlinedInput-input': {
                background: '#4f4f4f',
                borderRadius: 5,
                padding: 14,
                fontSize: '0.75rem',
                fontWeight: 400,
                color: '#fff',
                marginBottom: '1rem',
                '&::placeholder': {
                    opacity: 1,
                },
            },
            '& .MuiOutlinedInput-notchedOutline': { border: 0 },
            '& .MuiDialogActions-root': {
                justifyContent: 'normal',
                '& .MuiButtonBase-root': {
                    width: '100%',
                    textTransform: 'capitalize',
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    color: '#fff',
                    borderRadius: 5,
                },
                '& .MuiButtonBase-root:first-child': {
                    background: '#3a70d4',
                },

                '& .MuiButtonBase-root:last-child': {
                    background: '#bdbdbd',
                    marginLeft: '3rem',
                },
            },
        },
    },
};

export default dashboardstyle;
